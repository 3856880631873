import { Dispatch, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Outlet } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { RootState } from '@/store/reducers/rootReducer';
import { BaseActionType, BaseActions } from '@/store/actions/baseActions';
import { UserActionType, UserActions } from '@/store/actions/userActions';
import Header from '@/parts/Header';
import UserMenu from '@/parts/UserMenu';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';

const USER_PROFILE_QUERY = gql`
  query Query {
    me {
      _id
      email
      userAccountType
      firstName
      lastName
      username
      account_status
      completedInitialProfileSetup
      profilePicture
    }
  }
`;

const Dashboard = () => {
  const navigate = useNavigate();
  const isMounted = useRef(true);

  const { loading, error, data } = useQuery(USER_PROFILE_QUERY);

  const { accessTokenName, refreshTokenName } = useSelector(
    (state: RootState) => state.base.baseToken,
  );
  const { completedInitialProfileSetup } = useSelector((state: RootState) => state.user.userData);
  const baseDispatch = useDispatch<Dispatch<BaseActions>>();
  const userDispatch = useDispatch<Dispatch<UserActions>>();
  const { userData } = useSelector((state: RootState) => state.user);
  const [quickEnquiry, showQuickEnquiry] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const triggerQuickEnquiry = () => {
    !quickEnquiry ? showQuickEnquiry(true) : showQuickEnquiry(false);
  };

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      if (isMounted && data && data.me.completedInitialProfileSetup !== true) {
        window.location.href = '/welcome';
      }
    }
    fetchData();

    if (data && data?.me?.email !== '' && userData.email === '') {
      userDispatch({ type: UserActionType.SET_USERDATA, payload: data.me });
    }

    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [data]);

  const handleLogout = () => {
    localStorage.removeItem(accessTokenName);
    baseDispatch({ type: BaseActionType.SET_ACCESS_TOKEN, payload: '' });
    localStorage.removeItem(refreshTokenName);
    baseDispatch({ type: BaseActionType.SET_REFRESH_TOKEN, payload: '' });
    navigate('/login');
    window.location.reload();
  };

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen w-full'>
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <h1>'Error...'</h1>;
  }

  return (
    <>
      {/* { userData.account_status === AccountStatus.PENDING && <div>Confirm email account.</div> } */}
      {/* <Header /> */}
      <Header
        userData={userData}
        quickEnquiry={quickEnquiry}
        showQuickEnquiry={showQuickEnquiry}
        triggerQuickEnquiry={triggerQuickEnquiry}
      />
      <div className={'contentwrapper container pt-20 ' + (quickEnquiry ? 'opacity-50' : '')}>
        <div className='grid grid-cols-12'>
          <div className='col-span-12 lg:col-span-3 pr-12 hidden lg:block'>
            <UserMenu userData={userData} />
          </div>
          <div className='col-span-12 lg:col-span-9'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
