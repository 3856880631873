import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client';

import { getAccessToken } from '@/utils/token'

const AUTHENTICATE_ACCESS_TOKEN = gql`
  mutation Mutation($accessToken: String!) {
    authenticateAccessToken(accessToken: $accessToken)
  }
`;

const PrivateRoute = ({ children }: any) => {
  const [authenticateAccessTokenMutation] = useMutation(AUTHENTICATE_ACCESS_TOKEN);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAccessToken = async () => {
      try {
        const { data } = await authenticateAccessTokenMutation({
          variables: {
            accessToken: getAccessToken(),
          }
        })
        if(data.authenticateAccessToken) {
          setIsAuthenticated(data.authenticateAccessToken)
          setLoading(false)
        } else {
          setLoading(false)
        }
      } catch(err) {
        setLoading(false)
        setIsAuthenticated(false)
      }
    }
    checkAccessToken();
  }, []);

  if(!loading) {
    return isAuthenticated ? children : <Navigate to="/login" />;
  } else {
    return null
  }
};

export default PrivateRoute;