import { ApolloClient, HttpLink, split, from, concat, fromPromise, InMemoryCache, gql, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { getMainDefinition } from "@apollo/client/utilities"
import { createClient } from "graphql-ws"
import { onError } from '@apollo/client/link/error'

const JWT_ACCESS_KEY = process.env.REACT_APP_ACCESS_TOKEN_NAME
const JWT_REFRESH_KEY = process.env.REACT_APP_REFRESH_TOKEN_NAME

let accessToken: string | null = localStorage.getItem(JWT_ACCESS_KEY as string);
let refreshToken: string | null = localStorage.getItem(JWT_REFRESH_KEY as string);

export const setAccessToken = (token: string) => {
  accessToken = token;
  localStorage.setItem(JWT_ACCESS_KEY as string, token)
};

export const getAccessToken = (): string | null => accessToken;

let client: any;

let isRefreshing = false;
let pendingRequests: any = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback: any) => callback());
  pendingRequests = [];
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions!.code) {
          case 'UNAUTHENTICATED':
            let forward$;

            if (!isRefreshing && refreshToken) {
              isRefreshing = true;
              forward$ = fromPromise(
                client
                  .mutate({
                    variables: { refreshToken },
                    mutation: gql`
                      mutation getNewAccessToken($refreshToken: String!){
                        authenticateRefreshToken(refreshToken: $refreshToken) {
                          accessToken
                          refreshToken
                        }
                      }
                    `,
                  })
                  .then(({ data }: any) => {
                    const { accessToken } = data.authenticateRefreshToken
                    setAccessToken(accessToken)
                    return true;
                  })
                  .then(() => {
                    resolvePendingRequests();
                    return true;
                  })
                  .catch((err: any) => {
                    localStorage.removeItem(JWT_ACCESS_KEY as string)
                    localStorage.removeItem(JWT_REFRESH_KEY as string)
                    pendingRequests = [];
                    // window.location.reload();
                    return false;
                  })
                  .finally(() => {
                    isRefreshing = false;
                  })
              );
            } else {
              forward$ = fromPromise(
                new Promise((resolve: any) => {
                  pendingRequests.push(() => resolve());
                })
              );
            }

            return forward$.flatMap(() => forward(operation));
          default:
            console.log(
              `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
            );
        }
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      // window.location.reload();
    };
  }
);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APOLLO_SERVER as string,
});

const wsLink = new GraphQLWsLink(
	createClient({
		url: process.env.REACT_APP_APOLLO_WS_SERVER as string,
	})
);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(JWT_ACCESS_KEY as string);
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === "OperationDefinition" &&
			definition.operation === "subscription"
		);
	},
  wsLink,
	authLink.concat(httpLink)
);

client = new ApolloClient({
  link: from([errorLink, splitLink]),
  cache: new InMemoryCache(),
});

export default client;