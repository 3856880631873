import { combineReducers } from 'redux'
import baseReducer from './baseReducer'
import userReducer from './userReducer'

const rootReducer = combineReducers({
    base: baseReducer,
    user: userReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer;