import { Link } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import TestImage from '@/assets/images/no_image_found.png';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { PopupModalActionTypes } from '@/utils/types';
import ConfirmPopup from '@/components/ConfirmPopup';

const GET_PRODUCT = gql`
  query Query($getProductsUserId: ID) {
    getProducts(userId: $getProductsUserId) {
      id
      title
      price
      bodyContent
      productImageURL
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation DeleteProductMutation($deleteProductId: ID) {
    deleteProduct(productId: $deleteProductId)
  }
`;

const Products = () => {
  const { userData } = useSelector((state: RootState) => state.user);
  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    refetchQueries: [GET_PRODUCT, 'Query'],
  });

  const { loading, data, refetch } = useQuery(GET_PRODUCT);
  const [popupActive, setPopupActive] = useState(false);

  useEffect(() => {
    console.log('test', userData)
    // TODO - Resolve to a better solution, this is for the navigation back from creating a new article.
    // Query was not working correctly by firing off a new fetch.
    refetch();
  });

  const handleDeleteProduct = (e:any, id:any) => {
    e.preventDefault();
    setPopupActive(true);
  }

  const handlePopupStatusChange = (data:any, id: any) => {
    if(data) {
      deleteProduct({
        variables: {
          deleteProductId: id,
        },
      });
      setPopupActive(false);
    } else {
      setPopupActive(false);
    }
  }

  return (
    <>
    {loading ? <LoadingSpinner /> : 
        <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-span-8 lg:pr-3 relative">
          <div className="flex flex-row text-white font-semibold items-center text-xl justify-between">
            <h1>Products</h1>
            <Link className="col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl" to="/products/add">Add Product</Link>
          </div>
          {data && data.getProducts.map((product: any) => (
            <div key={product.id} className="grid grid-cols-12 lg:h-auto mt-8 relative" >
              <div
                className='h-64 md:h-full bg-red col-span-12 md:col-span-6 w-auto left-0 bg-cover lg:bg-center rounded-b-none rounded-t-lg md:w-full md:rounded-l-lg md:rounded-r-none'
                style={{
                  backgroundImage: `url(${
                    (product?.productImageURL && product?.productImageURL !== '') ? product.productImageURL : TestImage
                  })`,
                }}
              />
              <div className="col-span-12 md:col-span-6 right-0 bg-white rounded-t-none rounded-b-lg p-8 md:p-16 md:w-auto md:rounded-l-none md:rounded-r-lg">
                <h3 className="mb-4 text-black text-xl text-center font-semibold">{product.title}</h3>
                <p className="mb-4 text-black text-center">Price: ${product.price}</p>
                <div className="grid grid-cols-12 mt-12">
                  <Link 
                    className="col-span-12" 
                    to={`/products/${product.id}`}
                    state={{
                      productId: product.id,
                      title: product.title,
                      price: product.price,
                      bodyContent: product.bodyContent,
                      productImageURL: product.productImageURL
                    }}
                  >
                    <button className="w-full text-center my-2 bg-red px-1 py-2 rounded-lg font-semibold text-lg uppercase text-white transition ease-in-out duration-300 bg-red hover:bg-darkgrey">
                        View
                    </button>
                  </Link>
                  <button
                    className='delete-project-btn col-span-12 my-2 bg-red px-1 py-2 rounded-lg font-semibold text-lg uppercase text-white text-center transition ease-in-out duration-300 bg-red hover:bg-darkgrey'
                    onClick={(e) => handleDeleteProduct(e, product.id)}>
                    Delete
                  </button>
                  { popupActive && <ConfirmPopup heading="Delete product?" content="Are you sure you want to delete this product? This action cannot be undone." actionType={PopupModalActionTypes.DELETE} onPopupStatusChange={(data: any) => handlePopupStatusChange(data, product.id)} />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    }
    </>

  );
};

export default Products;
