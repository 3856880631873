import { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, gql, useQuery, useLazyQuery } from '@apollo/client';
import LoadingSpinner from '../../utils/loading/LoadingSpinner';
import TestImage from '@/assets/images/no_image_found.png';
import { useForm } from 'react-hook-form';
import { Utilities } from '@/utils/utilities';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import { PlusIcon } from '@heroicons/react/outline';
import { handleInputChange } from 'react-select/dist/declarations/src/utils';
import { Swiper, SwiperSlide } from 'swiper/react';

enum UserImageType {
  profile,
  cover,
}

const GET_ALL_PRODUCTS = gql`
  query Query($userId: ID, $filters: GetProductFilter) {
    getAllProducts(userId: $userId, filters: $filters) {
      id
      title
      price
      productImageURL
      comparePrice
      bodyContent
      type
      vendor
      categories
      tags
    }
  }
`;

const Shop = ({ profile }: any) => {
  const [searchParams] = useSearchParams();
  const [customProductFilter, setCustomProductFilter] = useState<any>({
    vendor: [],
    tag: [],
    category: [],
  });
  const { userData } = useSelector((state: RootState) => state.user);
  const { data, refetch } = useQuery(GET_ALL_PRODUCTS, {
    variables: { userId: profile._id, filters: customProductFilter },
  });

  let vendors: any[] = [
    {
      title: "Boosty Bois",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1665417028/mhp_media/62db866f0f2da906fdab5954/led6ufqf1tnlafsyp39j.jpg"
    },
    {
      title: "Muffler Bois Inc.",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1666063794/mhp_media/62eb94eb57d1d8e7535ddc87/vvqxxivwf6kgmmwe4hom.jpg"
    },
    {
      title: "Yeetville",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1665417028/mhp_media/62db866f0f2da906fdab5954/led6ufqf1tnlafsyp39j.jpg"
    },
    {
      title: "SendItOuttaHere",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1666063794/mhp_media/62eb94eb57d1d8e7535ddc87/vvqxxivwf6kgmmwe4hom.jpg"
    },
    {
      title: "Boosty Bois",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1665417028/mhp_media/62db866f0f2da906fdab5954/led6ufqf1tnlafsyp39j.jpg"
    },
  ]

  let categories: any[] = [
    {
      title: "Wheels",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1665417028/mhp_media/62db866f0f2da906fdab5954/led6ufqf1tnlafsyp39j.jpg"
    },
    {
      title: "Exhaust",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1666063794/mhp_media/62eb94eb57d1d8e7535ddc87/vvqxxivwf6kgmmwe4hom.jpg"
    },
    {
      title: "Engine",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1665417028/mhp_media/62db866f0f2da906fdab5954/led6ufqf1tnlafsyp39j.jpg"
    },
    {
      title: "Accessories",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1666063794/mhp_media/62eb94eb57d1d8e7535ddc87/vvqxxivwf6kgmmwe4hom.jpg"
    },
    {
      title: "Wheels",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1665417028/mhp_media/62db866f0f2da906fdab5954/led6ufqf1tnlafsyp39j.jpg"
    },
  ]

  let tags: any[] = [
    {
      title: "V8",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1665417028/mhp_media/62db866f0f2da906fdab5954/led6ufqf1tnlafsyp39j.jpg"
    },
    {
      title: "Aftermarket",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1666063794/mhp_media/62eb94eb57d1d8e7535ddc87/vvqxxivwf6kgmmwe4hom.jpg"
    },
    {
      title: "Retro",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1665417028/mhp_media/62db866f0f2da906fdab5954/led6ufqf1tnlafsyp39j.jpg"
    },
    {
      title: "Muffler",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1666063794/mhp_media/62eb94eb57d1d8e7535ddc87/vvqxxivwf6kgmmwe4hom.jpg"
    },
    {
      title: "V8",
      image: "http://res.cloudinary.com/my-hi-performance/image/upload/v1665417028/mhp_media/62db866f0f2da906fdab5954/led6ufqf1tnlafsyp39j.jpg"
    },
  ]

  useEffect(() => {
    refetch();
  }, [customProductFilter]);

  console.log(data);

  const handleInputChange = (type: string, value: string, el: any) => {
    switch (type) {
      case 'vendors':
        if (el.target.checked) {
          setCustomProductFilter({
            ...customProductFilter,
            vendor: [...customProductFilter.vendor, value],
          });
        } else {
          let filteredArray = customProductFilter.vendor.filter((item: any) => item !== value);
          setCustomProductFilter({
            ...customProductFilter,
            vendor: filteredArray,
          });
        }
        break;
      case 'tags':
        if (el.target.checked) {
          setCustomProductFilter({
            ...customProductFilter,
            tag: [...customProductFilter.tag, value],
          });
        } else {
          let filteredArray = customProductFilter.tag.filter((item: any) => item !== value);
          setCustomProductFilter({
            ...customProductFilter,
            tag: filteredArray,
          });
        }
        break;
      case 'categories':
        if (el.target.checked) {
          setCustomProductFilter({
            ...customProductFilter,
            category: [...customProductFilter.category, value],
          });
        } else {
          let filteredArray = customProductFilter.category.filter((item: any) => item !== value);
          setCustomProductFilter({
            ...customProductFilter,
            category: filteredArray,
          });
        }
        break;
    }
  };

  return (
    <>
      <h3 className='font-bold text-white text-lg mb-2 w-full'>Vendors</h3>
      {vendors && vendors.length && (
        <Swiper
          slidesPerView={4}
          spaceBetween={16}
          className='overflow-hidden flex-1 custom-swiper'
          breakpoints={{
            // when window width is >= 768px
            768: {
              spaceBetween: 16,
              slidesPerView: 6,
            },
            1400: {
              slidesPerView: 7,
            },
          }}
        >
          {vendors?.map((vendor, index) => (
            <SwiperSlide className='relative' key={index}>
              <div className='custom__productcheck'>
                <input
                  id={vendor.title}
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-red focus:ring-red'
                  onChange={(el) => handleInputChange('vendors', vendor.title, el)}
                />
                <label htmlFor={vendor.title} className='font-medium text-gray-200'>
                  <div className="img__wrap">
                    <img className='rounded-lg w-[98px] h-[98px]' src={vendor.image} />
                  </div>
                  <div className="text-xs pt-1">{vendor.title}</div>
                </label>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <h3 className='font-bold text-white text-lg mb-2 w-full'>Category</h3>
      {categories && categories.length && (
        <Swiper
          slidesPerView={4}
          spaceBetween={16}
          className='overflow-hidden flex-1 custom-swiper'
          breakpoints={{
            // when window width is >= 768px
            768: {
              spaceBetween: 16,
              slidesPerView: 6,
            },
            1400: {
              slidesPerView: 7,
            },
          }}
        >
          {categories?.map((vendor, index) => (
            <SwiperSlide className='relative' key={index}>
              <div className='custom__productcheck'>
                <input
                  id={vendor.title}
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-red focus:ring-red'
                  onChange={(el) => handleInputChange('categories', vendor.title, el)}
                />
                <label htmlFor={vendor.title} className='font-medium text-gray-200'>
                  <div className="img__wrap">
                    <img className='rounded-lg w-[98px] h-[98px]' src={vendor.image} />
                  </div>
                  <div className="text-xs pt-1">{vendor.title}</div>
                </label>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <h3 className='font-bold text-white text-lg mb-2 w-full'>Tags</h3>
      {tags && tags.length && (
        <Swiper
          slidesPerView={4}
          spaceBetween={16}
          className='overflow-hidden flex-1 custom-swiper'
          breakpoints={{
            // when window width is >= 768px
            768: {
              spaceBetween: 16,
              slidesPerView: 6,
            },
            1400: {
              slidesPerView: 7,
            },
          }}
        >
          {tags?.map((vendor, index) => (
            <SwiperSlide className='relative' key={index}>
              <div className='custom__productcheck'>
                <input
                  id={vendor.title}
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-red focus:ring-red'
                  onChange={(el) => handleInputChange('tags', vendor.title, el)}
                />
                <label htmlFor={vendor.title} className='font-medium text-gray-200'>
                  <div className="img__wrap">
                    <img className='rounded-lg w-[98px] h-[98px]' src={vendor.image} />
                  </div>
                  <div className="text-xs pt-1">{vendor.title}</div>
                </label>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <div className='col-span-12 pt-10'>
        <div className='grid grid-cols-12 gap-8 pb-20'>
          {data &&
            data.getAllProducts.map((product: any, index: number) => (
              <div className='col-span-6 md:col-span-4 md:h-[420px]' key={index}>
                <div className='mb-1'>
                  <img
                    src={product.productImageURL}
                    alt='product'
                    className='w-full h-[192px] object-cover rounded-md'
                  />
                  <div className='flex flex-col mt-3 h-20 justify-center text-center'>
                    <h3 className='text-lg font-bold capitalize'>{product.title}</h3>
                    {product.vendor && (
                      <p className='text-xs text-gray-500'>
                        <strong className='font-bold'>Vendor:</strong> {product.vendor}
                      </p>
                    )}
                  </div>
                </div>
                <div className=''>
                  <div className='flex justify-center'>
                    <Link
                      to={`/products/${product.id}`}
                      state={{
                        productId: product.id,
                        title: product.title,
                        price: product.price,
                        bodyContent: product.bodyContent,
                        productImageURL: product.productImageURL,
                      }}
                      className='cursor-pointer col-span-1 mx-0 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
                    >
                      View product
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Shop;
