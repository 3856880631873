import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ArticleTemplateOne from './templates/ArticleTemplateOne';
import ArticleTemplateTwo from './templates/ArticleTemplateTwo';
import { RootState } from '@/store/reducers/rootReducer';
import { useSelector } from 'react-redux';
import ArticleTemplateAdmin from './templates/ArticleTemplateAdmin';

export enum ArticleTemplate {
  TEMPLATE_ONE = 'Template #1',
  TEMPLATE_TWO = 'Template #2',
  TEMPLATE_ADMIN = 'Template Admin',
  NONE = 'none',
}

const enum userAccountType {
  ADMIN = 'admin',
  ADVERTISER = 'advertiser',
  STANDARD = 'standard',
}

const AddArticle = () => {
  const [template, setTemplate] = useState<ArticleTemplate>(ArticleTemplate.NONE);
  const { userData } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  const handleTemplateSelect = (selectedTemplate: ArticleTemplate) => {
    setTemplate(selectedTemplate);
  };

  return (
    <div className='grid grid-cols-12'>
      <div className='col-span-12 lg:col-span-8 lg:pr-3 relative'>
        <div className='flex flex-row text-white font-semibold items-center text-xl justify-between mb-8'>
          <h1>Add New Article</h1>
          <button
            className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </div>
        {template !== ArticleTemplate.NONE && (
          <div
            className='cursor-pointer w-fit whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl w-auto'
            onClick={() => {
              setTemplate(ArticleTemplate.NONE);
            }}
          >
            Back To Templates
          </div>
        )}
        {template === ArticleTemplate.NONE && (
          <>
            <h4 className='pb-4'>Choose a template: </h4>
            <div className='flex flex-wrap'>
              <ArticleTemplatePreview
                template={ArticleTemplate.TEMPLATE_ONE}
                action={handleTemplateSelect}
              />
              <ArticleTemplatePreview
                template={ArticleTemplate.TEMPLATE_TWO}
                action={handleTemplateSelect}
              />
              <ArticleTemplatePreview
                template={ArticleTemplate.TEMPLATE_ADMIN}
                action={handleTemplateSelect}
              />
            </div>
          </>
        )}
        {template === ArticleTemplate.TEMPLATE_ONE && <ArticleTemplateOne />}
        {template === ArticleTemplate.TEMPLATE_TWO && <ArticleTemplateTwo />}
        {template === ArticleTemplate.TEMPLATE_ADMIN && <ArticleTemplateAdmin />}
      </div>
    </div>
  );
};

export default AddArticle;

// Preview article template with skeleton
const ArticleTemplatePreview = ({
  template,
  action,
}: {
  template: ArticleTemplate;
  action: Function;
}) => {
  return (
    <div
      className='flex flex-col p-2 px-6 bg-white m-4 ml-0 mt-0 rounded cursor-pointer hover:bg-red transition duration-300'
      onClick={() => {
        action(template);
      }}
    >
      <label
        htmlFor='firstName'
        className='block text-sm font-medium text-gray-900 font-semibold cursor-pointer'
      >
        {template}
      </label>
      {getTemplateSkeletonForTemplate(template)}
    </div>
  );
};

const getTemplateSkeletonForTemplate = (template: ArticleTemplate) => {
  switch (template) {
    case ArticleTemplate.TEMPLATE_ONE:
      return <TemplateOneSkeleton />;
    case ArticleTemplate.TEMPLATE_TWO:
      return <TemplateTwoSkeleton />;
    case ArticleTemplate.TEMPLATE_ADMIN:
      return <TemplateAdminSkeleton />;
    default:
      return null;
  }
};

// Article Skeleton Templates to be returned upon enum values from helper method - getTemplateSkeletonForTemplate()
const TemplateOneSkeleton = () => {
  return (
    <div className='flex flex-col py-4 h-full'>
      {/* // Heading */}
      <div className='w-3/4 h-3 bg-gray-200 rounded'></div>
      {/* // Sub Heading */}
      <div className='w-1/2 h-2 bg-gray-200 rounded mt-2'></div>
      {/* // Body Content */}
      <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
      <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
      <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
      {/* // Image */}
      <div className='w-full h-6 bg-gray-200 rounded mt-2'></div>
    </div>
  );
};

const TemplateTwoSkeleton = () => {
  return (
    <div className='flex flex-col py-4 h-full'>
      <div className='flex flex-row'>
        {/* // Image */}
        <div className='w-2/5 h-full mr-1 bg-gray-200 rounded'></div>
        <div className='flex w-full flex-col'>
          {/* // Heading */}
          <div className='w-full h-3 bg-gray-200 rounded'></div>
          {/* // Sub Heading */}
          <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
        </div>
      </div>
      {/* // Body Content */}
      <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
      <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
      {/* // Image */}
      <div className='w-full h-6 bg-gray-200 rounded mt-2'></div>
      {/* // Body Content */}
      <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
      <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
      {/* // Image */}
      <div className='w-full h-6 bg-gray-200 rounded mt-2'></div>
    </div>
  );
};

const TemplateAdminSkeleton = () => {
  return (
    <div className='flex'>
      <div className='flex flex-col py-4 h-full w-full min-w-[75px] mr-1'>
        {/* // Feature Image */}
        <div className='w-full h-6 bg-gray-200 rounded mb-2'></div>
        <div className='flex flex-row'>
          {/* // Image */}
          <div className='w-2/5 h-full mr-1 bg-gray-200 rounded'></div>
          <div className='flex w-full flex-col'>
            {/* // Heading */}
            <div className='w-full h-3 bg-gray-200 rounded'></div>
            {/* // Sub Heading */}
            <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
          </div>
        </div>
        {/* // Body Content */}
        <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
        <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
        {/* // Image */}
        <div className='w-full h-6 bg-gray-200 rounded mt-2'></div>
        {/* // Body Content */}
        <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
        <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
        {/* // Image */}
        <div className='w-full h-6 bg-gray-200 rounded mt-2'></div>
      </div>
      <div className='flex flex-col py-4 h-full w-full min-w-[75px]'>
        {/* // Feature Image */}
        <div className='w-full h-6 bg-gray-200 rounded mb-2'></div>
        <div className='w-full h-6 bg-gray-200 rounded mb-2'></div>
        <div className='w-full h-6 bg-gray-200 rounded mb-2'></div>
        <div className='flex flex-row'>
          {/* // Image */}
          <div className='w-2/5 h-full mr-1 bg-gray-200 rounded'></div>
          <div className='flex w-full flex-col'>
            {/* // Heading */}
            <div className='w-full h-3 bg-gray-200 rounded'></div>
            {/* // Sub Heading */}
            <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
          </div>
        </div>
        {/* // Body Content */}
        <div className='w-full h-2 bg-gray-200 rounded mt-2'></div>
        {/* // Image Gallery */}
        <div className='flex flex-wrap'>
          <div className='w-3/12 h-5 mr-1 bg-gray-200 rounded mt-1'></div>
          <div className='w-3/12 h-5 mr-1 bg-gray-200 rounded mt-1'></div>
          <div className='w-3/12 h-5 mr-1 bg-gray-200 rounded mt-1'></div>
          <div className='w-3/12 h-5 mr-1 bg-gray-200 rounded mt-1'></div>
          <div className='w-3/12 h-5 mr-1 bg-gray-200 rounded mt-1'></div>
          <div className='w-3/12 h-5 mr-1 bg-gray-200 rounded mt-1'></div>
        </div>
      </div>
    </div>
  );
};
