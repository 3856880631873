import { useQuery, gql, useMutation } from "@apollo/client"
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useForm, Controller } from "react-hook-form"
import Select, { components } from 'react-select'

import LoadingSpinner from '@/utils/loading/LoadingSpinner'
import { RootState } from '@/store/reducers/rootReducer'

const { Option } = components

const IconOption = (props: any) => {
  return (
    <Option {...props}>
      <div className="flex cursor-pointer"><img src={props.data.profileImage} className="h-6 w-6 rounded-full mr-2" />{props.data.label}</div>
    </Option>
)};

const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers(escape: true) {
      _id
      username
      firstName
      lastName
      email
      profilePicture
    }
  }
`;

const CREATE_NEW_CHAT = gql`
  mutation createChatRoomMutation($input: ChatCreationInput) {
    createChatRoom(input: $input)
  }
`;

const AddMessage = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { isValid, errors }, control } = useForm({ mode: "onChange" });
  const [formError, setFormError] = useState('');
  const [createNewChat] = useMutation(CREATE_NEW_CHAT);
  const { userData } = useSelector((state: RootState) => state.user);
  const { loading: loading, data: allUsers } = useQuery(GET_ALL_USERS, {
    fetchPolicy: "no-cache",
  });

  if (loading || allUsers === undefined ) {
    return <LoadingSpinner />;
  }

  const { getAllUsers } = allUsers;

  const allUserOption =  getAllUsers.map((user: any) => ({
    value: user._id,
    label: `${user.firstName} ${user.lastName}`,
    profileImage: user.profilePicture,
  }));

  const handleAddChat = async (inputs: any) => {
    setFormError('')
    const { message, chatusers } = inputs;

    let userIds: any = []
    let chatName: string = ''
    Object.keys((chatusers)).map((k: any) => {
      userIds.push(chatusers[k]['value'])
        chatName += ` ${chatusers[k]['label'].trim()},`
    });
    try {
      userIds.push(userData._id)
      chatName += ` ${userData.fullName}`
      const { data } = await createNewChat({
        variables: {
          input: {
            message,
            users: userIds,
            chatName: chatName.trim().replace(/,\s*$/, ""),
          },
        },
      })

      if(data?.createChatRoom) {
        navigate(`/messages/${data.createChatRoom}`)
      }

    } catch (err: any) {
      setFormError(err.message)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleAddChat)} className="space-y-6 mt-5">
      <div className="shadow sm:rounded-md">
        <div className="px-4 py-5 bg-white space-y-6 sm:p-6 text-black">
          <div>
            <label htmlFor="heading" className="block text-sm font-medium text-gray-700">
              Select user
            </label>
            <div className="mt-1">
              <Controller
                  control={control}
                  name="chatusers"
                  render={({ field: { onChange }}: any) => (
                    <Select 
                      options={allUserOption}
                      name="chatusers"
                      components={{ 
                        Option: IconOption
                      }}
                      isClearable
                      isSearchable
                      isMulti
                      onChange={onChange}
                    />
                  )}
              />
            </div>
          </div>

          <div>
            <label htmlFor="content" className="block text-sm font-medium text-gray-700">
              Message
            </label>
            <div className="mt-1">
              <textarea
                id="content"
                {...register("message", {
                  required: "Please type a message.",
                    minLength: {
                      value: 1,
                      message: "Please type a message."
                    }
                })}
                className="text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                placeholder="Enter the make/manufacturer of your vehicle"
                defaultValue={''}
              />
              {errors.message && <span role="alert" className="text-xs text-red">{errors.message.message}</span>}
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button type="submit" className="inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl disabled:bg-gray-300 disabled:text-slate-500 disabled:cursor-not-allowed" disabled={!isValid}>
            Send message
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddMessage;