import { Link } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import TestImage from '@/assets/images/no_image_found.png';
import { useEffect, useState } from 'react';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import ConfirmPopup from '@/components/ConfirmPopup';
import { PopupModalActionTypes } from '@/utils/types';

const GET_ARTICLES = gql`
  query Query($userId: ID) {
    getArticles(userId: $userId) {
      id
      createdBy
      createdAt
      heading
      subHeading
      bodyContentList
      articleTemplate
      tags
      approved
      articleImageList
    }
  }
`;

const DELETE_ARTICLE = gql`
  mutation DeleteArticleMutation($deleteArticleId: ID) {
    deleteArticle(articleId: $deleteArticleId)
  }
`;

const Articles = () => {
  const [deleteArticle] = useMutation(DELETE_ARTICLE, {
    refetchQueries: [GET_ARTICLES, 'Query'],
  });

  const { loading, data, refetch, error } = useQuery(GET_ARTICLES);
  const [popupActive, setPopupActive] = useState(false);

  useEffect(() => {
    // TODO - Resolve to a better solution, this is for the navigation back from creating a new article.
    // Query was not working correctly by firing off a new fetch.
    refetch();
  });

  const handleDeleteArticle = (e: any, id: any) => {
    e.preventDefault();
    setPopupActive(true);
  };

  const handlePopupStatusChange = (data: any, id: any) => {
    if (data) {
      deleteArticle({
        variables: {
          deleteArticleId: id,
        },
      });
      setPopupActive(false);
    } else {
      setPopupActive(false);
    }
  };

  if (error) {
    return (
      <>
        <h1>Failed to get your articles, please log out and try again.</h1>
      </>
    );
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className='grid grid-cols-12 pb-12'>
          <div className='col-span-12 lg:col-span-8 lg:pr-3 relative'>
            <div className='flex flex-row text-white font-semibold items-center text-xl justify-between'>
              <h1>Articles</h1>
              <Link
                className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
                to='/articles/add'
              >
                Add Article
              </Link>
            </div>
            {data &&
              data.getArticles.map((article: any) => (
                <div key={article.id} className='grid grid-cols-12 lg:h-auto mt-8 relative'>
                  <div
                    className='h-64 md:h-full bg-red col-span-12 md:col-span-6 w-auto left-0 bg-cover lg:bg-center rounded-b-none rounded-t-lg md:w-full md:rounded-l-lg md:rounded-r-none'
                    style={{
                      backgroundImage: `url(${
                        article?.articleImageList && article?.articleImageList !== ''
                          ? // If article image list exists, grab the first image from the imageList arr
                            article.articleImageList[0]
                          : TestImage
                      })`,
                    }}
                  />
                  <div className='col-span-12 md:col-span-6 right-0 bg-white rounded-t-none rounded-b-lg p-8 md:p-16 md:w-auto md:rounded-l-none md:rounded-r-lg'>
                    <h3 className='mb-4 text-black text-xl text-center font-semibold'>
                      {article.heading}
                    </h3>
                    <div className='grid grid-cols-12 mt-12'>
                      <Link
                        className='col-span-12'
                        to={`/articles/${article.id}`}
                        state={{
                          articleId: article.id,
                          heading: article.heading,
                          subHeading: article.subHeading,
                          bodyContentList: article.bodyContentList,
                          articleImageList: article.articleImageList,
                          createdAt: article.createdAt,
                          articleTemplate: article.articleTemplate,
                        }}
                      >
                        <button className='w-full text-center my-2 bg-red px-1 py-2 rounded-lg font-semibold text-lg uppercase text-white transition ease-in-out duration-300 bg-red hover:bg-darkgrey'>
                          View
                        </button>
                      </Link>
                      <button
                        className='delete-project-btn col-span-12 my-2 bg-red px-1 py-2 rounded-lg font-semibold text-lg uppercase text-white text-center transition ease-in-out duration-300 bg-red hover:bg-darkgrey'
                        onClick={(e) => handleDeleteArticle(e, article.id)}
                      >
                        Delete
                      </button>
                      {popupActive && (
                        <ConfirmPopup
                          heading='Delete Article'
                          content='Are you sure you want to delete this article? This action cannot be undone.'
                          actionType={PopupModalActionTypes.DELETE}
                          onPopupStatusChange={(data: any) =>
                            handlePopupStatusChange(data, article.id)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Articles;
