import { ErrorString } from '@/utils/types';
import { getHTMLFromDraftState, Utilities } from '@/utils/utilities';
import { useState } from 'react';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import { useNavigate, Link } from 'react-router-dom';
import { ArticleData } from '../../SingleArticle';

export const ArticleTemplateAdminDisplay = ({
  userData,
  articleData,
}: {
  articleData: ArticleData;
  userData: any;
}) => {
  const { heading, subHeading, bodyContentList, articleImageList, createdAt } =
    articleData as ArticleData;
  const [lightBoxOpen, setLightBoxOpen] = useState<boolean>(false);
  const [galleryIndex, setGalleryIndex] = useState<number>(0);

  // TODO - understand why the returned array of bodyContentList/articleImageList comes in as a tuple?
  const bodyContentSections = Array.from(bodyContentList);
  const articleImages = Array.from(articleImageList);
  const navigate = useNavigate();

  const isImageValid = (input: any) => {
    return (
      input !== ErrorString.ARTICLE_UNKNOWN_ERROR &&
      input !== '' &&
      input !== null &&
      input !== undefined &&
      input !== ErrorString.ARTICLE_INVALID_SVG_FORMAT
    );
  };

  return (
    <>
      <div className='relative gap-2 grid grid-cols-12 pb-12'>
        <div className='col-span-12 lg:col-span-8 lg:pr-3 relative'>
          <div className='grid grid-flow-col col-span-12 border-red border-y border-t-0 pb-4 items-center my-2'>
            <div className='left grid grid-flow-row grid-flow-col col-span-1 col-span-2 justify-start items-center'>
              <div className='col-span-3 grid'>
                <Link to='/profile'>
                  <img
                    className='author-profile-image rounded-full'
                    width='48px'
                    height='48px'
                    src={userData.profilePicture ? userData.profilePicture : ''}
                  />
                </Link>
              </div>
              <div className='col-span-1 lg:col-span-12 ml-4'>
                <div className='mt-1 text-md text-white font-bold'>
                  {userData.firstName} {userData.lastName}
                </div>
                <div className='mt-1 text-xs text-white'>
                  {new Date(createdAt)?.toLocaleDateString()}
                </div>
              </div>
            </div>
            <div className='right col-span-1 lg:col-span-2 mt-4 mb-2 lg:my-0 text-right'>
              <button
                className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </div>

          <div className='flex'>
            {isImageValid(articleImages?.[0]) ? (
              <div
                onClick={() => {
                  setLightBoxOpen(true);
                  setGalleryIndex(0);
                }}
                className='cursor-pointer col-span-6 my-2 w-1/2 rounded-lg h-80 w-1/2 mr-4 bg-cover bg-center cursor-pointer'
                style={{ backgroundImage: `url(${articleImages[0]})` }}
              />
            ) : (
              ''
            )}
            <div className='flex-col flex pt-4 w-1/2'>
              <div className='col-span-6 text-white text-3xl mt-2 font-bold pb-2'>
                {Utilities.capitalizeFirstLetter(heading)}
              </div>
              <div className='grid grid-flow-row col-span-6 mt-3 mb-2'>
                <h3 className='col-span-12 mb-2 font-bold text-md'>
                  {Utilities.capitalizeFirstLetter(subHeading)}
                </h3>
                {bodyContentSections?.[0] && (
                  <ContentDisplay dangerousHTML={bodyContentSections?.[0]} />
                )}
              </div>
            </div>
          </div>
          <div className='flex flex-col w-full'>
            {bodyContentSections?.[1] && (
              <ContentDisplay dangerousHTML={bodyContentSections?.[1]} />
            )}
            {isImageValid(articleImages?.[1]) ? (
              <div
                onClick={() => {
                  setLightBoxOpen(true);
                  setGalleryIndex(1);
                }}
                className='col-span-6 my-2 rounded-lg h-80 w-full mr-4 bg-cover bg-center cursor-pointer'
                style={{ backgroundImage: `url(${articleImages[1]})` }}
              />
            ) : (
              ''
            )}
            {bodyContentSections?.[2] && (
              <ContentDisplay dangerousHTML={bodyContentSections?.[2]} />
            )}
            {isImageValid(articleImages?.[2]) ? (
              <div
                onClick={() => {
                  setLightBoxOpen(true);
                  setGalleryIndex(2);
                }}
                className='col-span-6 my-2 rounded-lg h-80 w-full mr-4 bg-cover bg-center cursor-pointer'
                style={{ backgroundImage: `url(${articleImages[2]})` }}
              />
            ) : (
              ''
            )}
          </div>
          <div className='flex flex-col w-full mt-6'>
            {isImageValid(articleImages?.[3]) ? (
              <div className='flex-col'>
                <div
                  onClick={() => {
                    setLightBoxOpen(true);
                    setGalleryIndex(3);
                  }}
                  className='col-span-6 my-2 rounded-lg h-80 w-full mr-4 bg-cover bg-center cursor-pointer'
                  style={{ backgroundImage: `url(${articleImages[3]})` }}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className='flex flex-col'>
            {isImageValid(articleImages?.[4]) && (
              <div
                onClick={() => {
                  setLightBoxOpen(true);
                  setGalleryIndex(4);
                }}
                className='col-span-6 my-2 rounded-lg h-80 mr-4 bg-cover bg-center cursor-pointer'
                style={{ backgroundImage: `url(${articleImages[4]})` }}
              />
            )}
            <div className='flex-col flex pt-4'>
              <div className='grid grid-flow-row col-span-6 mt-3 mb-2'>
                {bodyContentSections?.[3] && (
                  <ContentDisplay dangerousHTML={bodyContentSections?.[3]} />
                )}
              </div>
              <div className='grid grid-flow-row col-span-6 mt-3 mb-2'>
                {bodyContentSections?.[4] && (
                  <ContentDisplay dangerousHTML={bodyContentSections?.[4]} />
                )}
              </div>
            </div>
          </div>
          <div className='flex flex-row w-full mt-6'>
            {isImageValid(articleImages?.[4]) ? (
              <div className='flex flex-wrap w-full'>
                {[...Array(10)].fill('').map((_: any, index: number) => {
                  let offset = 5;
                  console.log('articleImage: ', offset + index);
                  console.log('articleImage: ', articleImages?.[offset + index]);
                  if (!isImageValid(articleImages?.[offset + index])) return;
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setGalleryIndex(index + offset);
                        setLightBoxOpen(true);
                      }}
                      className='cursor-pointer mr-1 w-[calc(33$_-_0.25rem)] md:w-[calc(25%_-_0.25rem)] my-1 rounded-lg h-32 w-full pr-1 bg-cover bg-no-repeat bg-center mt-6'
                      style={{ backgroundImage: `url(${articleImages?.[index + offset]})` }}
                    />
                  );
                })}
              </div>
            ) : (
              ''
            )}
            {lightBoxOpen ? (
              <Lightbox
                startIndex={galleryIndex}
                onClose={() => {
                  setLightBoxOpen(false);
                }}
                images={articleImages}
              ></Lightbox>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const ContentDisplay = ({ dangerousHTML }: { dangerousHTML: string }) => {
  return (
    <>
      <div className='col-span-12 text-white text-sm break-all py-2'>
        <div
          dangerouslySetInnerHTML={{
            __html: getHTMLFromDraftState(dangerousHTML),
          }}
        />
      </div>
    </>
  );
};
