import { useParams } from 'react-router-dom';
import { ArticleTemplate } from './AddArticle';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { ArticleTemplateOneDisplay } from './templates/displays/ArticleTemplateOneDisplay';
import { ArticleTemplateTwoDisplay } from './templates/displays/ArticleTemplateTwoDisplay';
import { ArticleTemplateAdminDisplay } from './templates/displays/ArticleTemplateAdminDisplay';

export interface ArticleData {
  articleId: string;
  heading: string;
  subHeading: string;
  bodyContentList: [string];
  articleImageList: [string];
  createdAt: string;
  articleTemplate: ArticleTemplate;
}

export const GET_ARTICLE_BY_ID = gql`
  query GetArticleById($articleId: ID) {
    getArticleById(articleId: $articleId) {
      article {
        id
        createdAt
        createdBy
        heading
        subHeading
        bodyContentList
        tags
        approved
        articleImageList
        articleTemplate
      }
      firstName
      lastName
      username
      profilePicture
      type
      tags
    }
  }
`;

const SingleArticle = () => {
  const { articleSlug } = useParams();

  const {
    error,
    loading,
    data: articleData2,
  } = useQuery(GET_ARTICLE_BY_ID, {
    variables: {
      articleId: articleSlug,
    },
  });

  const [articleData, setArticleDataLoaded] = useState();

  useEffect(() => {
    if (articleData2) {
      setArticleDataLoaded(articleData2?.getArticleById?.article);
    }
  }, [articleData2]);

  const userData = articleData2?.getArticleById;

  // Render based on article template
  if (articleData) {
    const { articleTemplate } = articleData;
    switch (articleTemplate) {
      case ArticleTemplate.TEMPLATE_ONE:
        return <ArticleTemplateOneDisplay articleData={articleData} userData={userData} />;
      case ArticleTemplate.TEMPLATE_TWO:
        return <ArticleTemplateTwoDisplay userData={userData} articleData={articleData} />;
      case ArticleTemplate.TEMPLATE_ADMIN:
        return <ArticleTemplateAdminDisplay userData={userData} articleData={articleData} />;
      default:
        return <ArticleTemplateOneDisplay userData={userData} articleData={articleData} />;
    }
  } else {
    return null;
  }
};

export default SingleArticle;
