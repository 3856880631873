// Import packages and hooks
import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { UPLOAD_PHOTO } from '@/utils/mutations/mutations';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';

const CREATE_PROJECT = gql`
  mutation Mutation($input: ProjectInput) {
    createProject(input: $input) {
      projectName
      year
      make
      model
      colour
      description
      projectCoverImage
    }
  }
`;

const AddNewProject = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [formError, setFormError] = useState('');
  const [projectCoverImage, setProjectCoverImage] = useState('');
  const [imagePreview, setImagePreview] = useState<any>();
  const [addProjectMutation, { data: addProjectData, loading: addProjectLoading }] =
    useMutation(CREATE_PROJECT);
  const [projectCoverImageError, setProjectCoverImageError] = useState(false);
  const [uploadPhoto, { data: uploadPhotoData, loading: uploadPhotoLoading }] =
    useMutation(UPLOAD_PHOTO);

  const handleImageUpload = async (e: any) => {
    try {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        setImagePreview(reader.result);
        await uploadPhoto({
          variables: { uploadPhotoPhoto: reader.result },
        }).then((data: any) => {
          setProjectCoverImage(data?.data?.uploadPhoto);
          setProjectCoverImageError(false);
        });
      };
    } catch (err: any) {
      console.log('err: ', err);
    }
  };
  const handleAddProject = async (inputs: any) => {
    if (projectCoverImage === '' || projectCoverImage === null) {
      setProjectCoverImageError(true);
      return;
    }
    setFormError('');
    const { projectName, year, make, model, colour, description } = inputs;
    try {
      const { data } = await addProjectMutation({
        variables: {
          input: {
            projectName: projectName,
            year: year,
            make: make,
            model: model,
            colour: colour,
            description: description,
            projectCoverImage: projectCoverImage,
          },
        },
      });
      if (data) {
        navigate('/projects');
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 lg:col-span-8 lg:pr-3 relative">
        <div className='flex flex-row text-white font-semibold items-center text-xl justify-between mb-8'>
          <h1>Add New Project</h1>
          <button
            className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </div>
        <form onSubmit={handleSubmit(handleAddProject)} className='space-y-6'>
          <div className='shadow sm:rounded-md sm:overflow-hidden'>
            <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
              <div>
                <label htmlFor='about' className='block text-sm font-medium text-gray-700'>
                  Name
                </label>
                <div className='mt-1'>
                  <input
                    id='projectName'
                    {...register('projectName', { required: 'Project name required' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter the name of your new project'
                    defaultValue={''}
                  />
                  <span className='text-red'>{errors.projectName && errors.projectName.message}</span>
                </div>
              </div>
              <div>
                <label htmlFor='about' className='block text-sm font-medium text-gray-700'>
                  Year
                </label>
                <div className='mt-1'>
                  <input
                    id='about'
                    {...register('year', { required: 'Vehicle year required.' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter the year of your vehicle'
                    defaultValue={''}
                  />
                  <span className='text-red'>{errors.year && errors.year.message}</span>
                </div>
              </div>
              <div>
                <label htmlFor='about' className='block text-sm font-medium text-gray-700'>
                  Make
                </label>
                <div className='mt-1'>
                  <input
                    id='about'
                    {...register('make', { required: 'Vehicle make required.' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter the make/manufacturer of your vehicle'
                    defaultValue={''}
                  />
                  <span className='text-red'>{errors.make && errors.make.message}</span>
                </div>
              </div>
              <div>
                <label htmlFor='about' className='block text-sm font-medium text-gray-700'>
                  Model
                </label>
                <div className='mt-1'>
                  <input
                    id='about'
                    {...register('model', { required: 'Vehicle model required.' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter the model of your vehicle'
                    defaultValue={''}
                  />
                  <span className='text-red'>{errors.model && errors.model.message}</span>
                </div>
              </div>
              <div>
                <label htmlFor='colour' className='block text-sm font-medium text-gray-700'>
                  Colour
                </label>
                <div className='mt-1'>
                  <input
                    id='about'
                    {...register('colour')}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter the colour of your vehicle'
                    defaultValue={''}
                  />
                </div>
              </div>
              <div>
                <label htmlFor='description' className='block text-sm font-medium text-gray-700'>
                  Project Description
                </label>
                <div className='mt-1'>
                  <textarea
                    id='about'
                    {...register('description', { required: 'Vehicle description required.' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter a basic summary of your vehicle'
                    defaultValue={''}
                  />
                  <span className='text-red'>{errors.description && errors.description.message}</span>
                </div>
              </div>
              <div>
                <label className='block text-sm font-medium text-gray-700'>Photos</label>
                <div
                  className={`mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md ${
                    projectCoverImage === '' ? 'px-6 pt-5 pb-6' : ''
                  }`}
                >
                  <div className='space-y-1 text-center'>
                    {uploadPhotoLoading ? (
                      <LoadingSpinner />
                    ) : projectCoverImage === '' ? (
                      <>
                        <svg
                          className='mx-auto h-12 w-12 text-gray-400'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 48 48'
                          aria-hidden='true'
                        >
                          <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <div className='flex text-sm text-gray-600'>
                          <label
                            htmlFor='file-upload'
                            className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                          >
                            <span>Upload a file</span>
                            <input
                              id='file-upload'
                              name='file-upload'
                              type='file'
                              onChange={(event) => {
                                handleImageUpload(event);
                              }}
                              className='sr-only'
                            />
                          </label>
                          <p className='pl-1'>or drag and drop</p>
                        </div>
                        <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                      </>
                    ) : (
                      <div className='h-full w-full max-h-48 relative'>
                        <img
                          className='object-cover h-full w-full'
                          src={imagePreview}
                          alt='ProfileImage'
                        />
                      </div>
                    )}
                  </div>
                </div>
                {projectCoverImageError ? (
                  <span className='text-red'>Project cover image required</span>
                ) : null}
              </div>
            </div>
            <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
              {addProjectLoading ? (
                <LoadingSpinner />
              ) : (
                <button
                  type='submit'
                  className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
                >
                  Create Project
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewProject;
