import { useEffect, useState } from 'react';
import Sticky from 'react-stickynode';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import TestImage from '@/assets/images/no_image_found.png';
import NewAdvertisers from '@/components/usercomponents/NewAdvertisers';
import RecommendedAdvertisers from '@/components/usercomponents/RecommendedAdvertisers';
import AdvertisersWidgets from '@/components/usercomponents/AdvertisersWidgets';

const UserMenu = ({ userData }: any) => {
  // console.log(userData);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { accessTokenName, refreshTokenName } = useSelector(
    (state: RootState) => state.base.baseToken,
  );
  const [activeMenuItem, setActiveMenuItem] = useState({
    activeLink: null,
    navMenuItems: [
      {
        id: 0,
        title: 'Home',
        icon: '<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="27.369" height="21.283" viewBox="0 0 27.369 21.283"><path d="M13.32,7.775l-8.76,7.215v7.787a.76.76,0,0,0,.76.76l5.324-.014a.76.76,0,0,0,.756-.76V18.215a.76.76,0,0,1,.76-.76H15.2a.76.76,0,0,1,.76.76v4.544a.76.76,0,0,0,.76.763l5.322.015a.76.76,0,0,0,.76-.76V14.984L14.047,7.775A.579.579,0,0,0,13.32,7.775Zm13.837,4.9L23.185,9.4V2.824a.57.57,0,0,0-.57-.57H19.955a.57.57,0,0,0-.57.57v3.45l-4.254-3.5a2.281,2.281,0,0,0-2.9,0L.206,12.679a.57.57,0,0,0-.076.8l1.212,1.473a.57.57,0,0,0,.8.077l11.176-9.2a.579.579,0,0,1,.727,0l11.176,9.2a.57.57,0,0,0,.8-.076l1.212-1.473a.57.57,0,0,0-.081-.8Z" transform="translate(0.001 -2.254)" /></svg>',
        link: '/',
      },
      {
        id: 1,
        title: 'My Projects',
        link: '/projects',
        icon: '<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="21.294" height="21.283" viewBox="0 0 21.294 21.283"><path d="M1067.677,334.854l.609,1.674a8.873,8.873,0,0,1,8.772,2.654,1.315,1.315,0,0,1-.422,2.067l-3.612,1.7a1.2,1.2,0,0,1-1.146-.081,2.662,2.662,0,0,0-3.938,1.433,1.2,1.2,0,0,1-.825.8l-3.859,1.02a1.315,1.315,0,0,1-1.652-1.312,8.873,8.873,0,0,1,5.014-7.672l-.609-1.674a10.644,10.644,0,1,0,1.667-.607Zm2.488,19.139a8.894,8.894,0,0,1-6.6-3.3,1.315,1.315,0,0,1,.693-2.092l3.5-.926a1.308,1.308,0,0,1,1.418.532l1.146,1.7,1.125,1.991A1.411,1.411,0,0,1,1070.164,353.993Zm.914-7.2a1.774,1.774,0,1,1,1.06-2.274A1.774,1.774,0,0,1,1071.078,346.793Zm5.328,4.928a1.411,1.411,0,0,1-2.333-.786l-.417-2.249-.213-2.037a1.307,1.307,0,0,1,.744-1.319l3.279-1.543a1.315,1.315,0,0,1,1.875,1.157A8.9,8.9,0,0,1,1076.406,351.721Z" transform="translate(-1059.824 -334.484)"/></svg>',
      },
      {
        id: 2,
        title: 'Messages',
        link: '/messages',
        icon: '<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="21.283" height="21.283" viewBox="0 0 21.283 21.283"><path d="M22.155,3H5.128A2.125,2.125,0,0,0,3.011,5.128L3,24.283l4.257-4.257h14.9A2.135,2.135,0,0,0,24.283,17.9V5.128A2.135,2.135,0,0,0,22.155,3ZM20.026,15.77H7.257V13.641h12.77Zm0-3.192H7.257V10.449h12.77Zm0-3.192H7.257V7.257h12.77Z" transform="translate(-3 -3)"/></svg>',
      },
      {
        id: 3,
        title: 'My Articles',
        link: '/articles',
        icon: '<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="21.28" height="24.32" viewBox="0 0 21.28 24.32"><path d="M21.28,17.1V1.14A1.137,1.137,0,0,0,20.14,0H4.56A4.561,4.561,0,0,0,0,4.56v15.2a4.561,4.561,0,0,0,4.56,4.56H20.14a1.137,1.137,0,0,0,1.14-1.14v-.76a1.149,1.149,0,0,0-.423-.888,10.593,10.593,0,0,1,0-3.548A1.132,1.132,0,0,0,21.28,17.1ZM6.08,6.365a.286.286,0,0,1,.285-.285h10.07a.286.286,0,0,1,.285.285v.95a.286.286,0,0,1-.285.285H6.365a.286.286,0,0,1-.285-.285Zm0,3.04a.286.286,0,0,1,.285-.285h10.07a.286.286,0,0,1,.285.285v.95a.286.286,0,0,1-.285.285H6.365a.286.286,0,0,1-.285-.285ZM18.116,21.28H4.56a1.52,1.52,0,1,1,0-3.04H18.116A17.1,17.1,0,0,0,18.116,21.28Z"/></svg>',
      },
    ],
  });

  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.removeItem(accessTokenName);
    localStorage.removeItem(refreshTokenName);
    navigate('/login');
    window.location.reload();
  };

  return (
    <Sticky top={84} className='sticky-sidebar w-full'>
      <div className='grid justify-center lg:block col-span-12 lg:col-span-3'>
        <div className='menu-container transition ease-in-out duration-250'>
          {userData && (
            <div className='flex items-center'>
              <Link to={`/profile/${userData.username}`}>
                <img
                  className='rounded-full border border-2 border-white w-16 h-16 object-cover'
                  src={userData?.profilePicture ? userData.profilePicture : TestImage}
                  // Add referrer policy to stop Google profile images src issue.
                  referrerPolicy='no-referrer'
                />
              </Link>
              <span className='ml-2'>
                <Link className='block text-lg font-bold' to={`/profile/${userData.username}`}>
                  {userData.firstName} {userData.lastName}
                </Link>
                <Link to={`/profile/${userData.username}/edit`} className='text-red'>
                  Edit Profile
                </Link>
              </span>
            </div>
          )}
          <div className='sidebarmenu pt-10'>
            <div className='menulinks'>
              {activeMenuItem.navMenuItems.map((navItem, index) => (
                <NavLink
                  key={index}
                  id={navItem.id.toString()}
                  to={navItem.link}
                  className={({ isActive }) =>
                    isActive
                      ? 'text-white font-bold mb-8 transition-colors flex active'
                      : 'text-white font-bold mb-8 transition-colors flex'
                  }
                >
                  <div
                    className='iconwrap w-16 pl-3'
                    dangerouslySetInnerHTML={{ __html: navItem.icon }}
                  />
                  {navItem.title}
                </NavLink>
              ))}
              {(userData.userAccountType === 'advertiser' ||
                userData.userAccountType === 'admin') && (
                <NavLink
                  to='/products'
                  className={({ isActive }) =>
                    isActive
                      ? 'text-white font-bold mb-8 transition-colors flex active'
                      : 'text-white font-bold mb-8 transition-colors flex'
                  }
                >
                  <div className='iconwrap w-16 pl-3'>
                    <svg className='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
                      <path d='M0 155.2C0 147.9 2.153 140.8 6.188 134.7L81.75 21.37C90.65 8.021 105.6 0 121.7 0H518.3C534.4 0 549.3 8.021 558.2 21.37L633.8 134.7C637.8 140.8 640 147.9 640 155.2C640 175.5 623.5 192 603.2 192H36.84C16.5 192 .0003 175.5 .0003 155.2H0zM64 224H128V384H320V224H384V464C384 490.5 362.5 512 336 512H112C85.49 512 64 490.5 64 464V224zM512 224H576V480C576 497.7 561.7 512 544 512C526.3 512 512 497.7 512 480V224z' />
                    </svg>
                  </div>
                  Products
                </NavLink>
              )}
              {userData.userAccountType === 'admin' && (
                <NavLink
                  to='/adminarea/tags'
                  className={({ isActive }) =>
                    isActive
                      ? 'text-white font-bold mb-8 transition-colors flex active'
                      : 'text-white font-bold mb-8 transition-colors flex'
                  }
                >
                  <div className='iconwrap w-16 pl-3'>
                    <svg className='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
                      <path d='M0 155.2C0 147.9 2.153 140.8 6.188 134.7L81.75 21.37C90.65 8.021 105.6 0 121.7 0H518.3C534.4 0 549.3 8.021 558.2 21.37L633.8 134.7C637.8 140.8 640 147.9 640 155.2C640 175.5 623.5 192 603.2 192H36.84C16.5 192 .0003 175.5 .0003 155.2H0zM64 224H128V384H320V224H384V464C384 490.5 362.5 512 336 512H112C85.49 512 64 490.5 64 464V224zM512 224H576V480C576 497.7 561.7 512 544 512C526.3 512 512 497.7 512 480V224z' />
                    </svg>
                  </div>
                  Admin: Manage Tags
                </NavLink>
              )}
              <a
                href='#'
                onClick={handleLogout}
                className='text-white flex font-bold transition-colors'
              >
                <div className='iconwrap w-16 pl-3'>
                  <svg className='icon' viewBox='0 0 24 24'>
                    <path d='M12,10c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2s-2,0.9-2,2v4C10,9.1,10.9,10,12,10z' />
                    <path d='M19.1,4.9L19.1,4.9c-0.3-0.3-0.6-0.4-1.1-0.4c-0.8,0-1.5,0.7-1.5,1.5c0,0.4,0.2,0.8,0.4,1.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0    c1.3,1.3,2,3,2,4.9c0,3.9-3.1,7-7,7s-7-3.1-7-7c0-1.9,0.8-3.7,2.1-4.9l0,0C7.3,6.8,7.5,6.4,7.5,6c0-0.8-0.7-1.5-1.5-1.5    c-0.4,0-0.8,0.2-1.1,0.4l0,0C3.1,6.7,2,9.2,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,9.2,20.9,6.7,19.1,4.9z' />
                  </svg>
                </div>{' '}
                Logout
              </a>
            </div>
          </div>
        </div>
        <AdvertisersWidgets />
      </div>
    </Sticky>
  );
};

export default UserMenu;
