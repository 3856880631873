import React from 'react';
import BmwImage from '@images/no_image_found.png';
import FlameIcon from '@images/icon-metro-fire.svg';
import ShareIcon from '@images/icon-awesome-share.svg';
import MHPLogo from '@images/logo.svg';
import CommentIcon from '@images/icon-material-message.svg';
import { Utilities } from '@/utils/utilities';

export class DemoContent {
  // TODO - remove assortment of images below when no longer required.
  DemoImages = [
    'https://cdn.wallpapersafari.com/51/62/61kG0K.jpg',
    'https://cdn.wallpapersafari.com/56/91/luLPnm.jpg',
    'https://7wallpapers.net/wp-content/uploads/Tesla-Cybertruck-47.jpg',
    'https://i.pinimg.com/originals/82/7b/70/827b700b4c9861ea748858e164cd4676.jpg',
    'https://wallpapercave.com/wp/wp6793772.jpg',
    'https://images6.alphacoders.com/683/thumb-1920-683123.jpg',
    'https://wallpapercave.com/dwp2x/UK4TcKI.jpg',
    'https://wallpapercave.com/wp/G9QKGl1.jpg',
    'https://images.unsplash.com/photo-1546998557-f4da4a981c78?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
    'https://images.unsplash.com/photo-1621954927815-38af7e3efd93?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80',
    'https://images.unsplash.com/photo-1583355497633-e4248ca3fdb0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2787&q=80',
    'https://wallpapercave.com/wp/wp5177230.jpg',
    'https://c4.wallpaperflare.com/wallpaper/408/566/570/lada-niva-car-dirt-mud-wallpaper-preview.jpg',
    'https://wallpapercave.com/wp/wp5599592.jpg',
  ];

  // TODO - remove demo comments and add array to project schema comments array list
  DemoComments = [
    {
      name: 'Jordy Yeoman',
      comment: 'Dayummmmm son, that looks killa!! 🔥',
    },
    {
      name: 'Samuel Hawley',
      comment: 'Wowsers, wish my Dad would buy me a car like that.',
    },
    {
      name: 'Jordy Yeoman',
      comment: 'Jason Statham lookin now arent ya!',
    },
    {
      name: 'Bipu Da Boss',
      comment: 'Sweet ride, does your nanna drive it for you?',
    },
    {
      name: 'J-Swizzle',
      comment: 'Time to sell my bitcoin and get something like this',
    },
    {
      name: 'S-bomb',
      comment: 'Yessssss 🔥🔥🔥🔥🔥',
    },
    {
      name: 'SubaruLover93',
      comment: 'Its still not a suby though.....',
    },
    {
      name: 'BaconMaster123',
      comment:
        'Buffalo jowl pancetta alcatra. Shoulder turkey capicola, spare ribs biltong beef ribs corned beef chislic shank drumstick swine pig.',
    },
    {
      name: 'Ilovemeat74',
      comment:
        'Rump meatloaf porchetta shank kielbasa alcatra buffalo short ribs tri-tip ground round. ',
    },
    {
      name: 'BaconMaster123',
      comment:
        'Prosciutto hamburger spare ribs, sirloin tail kevin leberkas pork buffalo jowl strip steak. Spare ribs brisket rump, hamburger jowl alcatra meatloaf picanha pork belly doner kielbasa tail.',
    },
    {
      name: 'PigCity222',
      comment:
        ' Biltong swine beef ribs, pork chop burgdoggen turducken pancetta strip steak ham hock porchetta',
    },
    {
      name: 'IEATMeat007',
      comment:
        'Shankle picanha salami rump swine sirloin frankfurter, cupim landjaeger kielbasa pastrami jowl chislic. Beef ribs tri-tip beef porchetta.',
    },
    {
      name: 'JDMisMYDAD',
      comment:
        'MUGEN EVERYTHING—camshafts, valve springs, ram air intake, large capacity intake box, 4-2-1 exhaust manifold, high-flow catalytic converter, straight-type silencer (middle), dual exhaust, carbon intake manifold cover',
    },
    {
      name: 'SpeedDemon11',
      comment:
        'The FD2 R was the first with 4 doors, and the limited edition Mugen double R version—of which only 300 were produced—pooped all over the USDM Mugen Si. Available in just one color, Milano Red, ',
    },
    {
      name: 'PerformanceLyf',
      comment:
        'In an exercise to show off its Hurtling Solid & Joker line of affordable aero, Varis proves again it still has the magic touch.',
    },
    {
      name: 'Trevor Pickett',
      comment:
        'This S13 was destined to be a drift missile, but owner Hitomi Ishikawa sweettalked her hubby Masaru, who happens to be an autobody repair tech as well as a bona fide enthusiast, into creating this drift-able show piece.',
    },
    {
      name: 'Neil-Meister',
      comment:
        'These EFs are about as grassroots JDM as they come. The stories of No Good Racing crews Kazuma Tsujimoto and Tsuyoshi Okada from Osaka and their loyalty to the boxy Civic platform takes us back to why we got into this stuff in the first place and puts perspective on the big goals of chasing that tuner life.',
    },
  ];
}

// TODO - component below will be extract to its own file or deleted once we have enough projects in the DB.
export const DemoProject = () => {
  return (
    <div className='group rounded-lg overflow-hidden mb-4 relative h-72 shadow bg-white flex items-end min-h-[250px] cursor-pointer'>
      <div
        className='bg-cover bg-center absolute h-full w-full z-0 pointer-events-none'
        style={{
          backgroundImage: `url(${Utilities.getRandomElementFromArray(
            new DemoContent().DemoImages,
          )})`,
        }}
      />
      <div className='grid grid-cols-6 mt-auto w-full'>
        <div className='h-full col-span-12 p-4 lg:p-6 lg:px-8 text-black bg-white transition-opacity opacity-0 group-hover:opacity-100 duration-300 group-hover:z-10'>
          <div className='flex'>
            <img src={FlameIcon} />
            <img className='ml-3' src={ShareIcon} />
            <img className='ml-3' src={CommentIcon} />
          </div>
          <h4 className='font-bold text-lg'>Jeremy Pickett</h4>
          <h5 className='text-xs text-red'>Following</h5>
          <p className='pt-5'>
            New M3. Thanks, <a href='#'>Trevor Pickett</a> for the sweet shots!
          </p>
        </div>
      </div>
    </div>
  );
};
// TODO - component below will be extract to its own file or deleted once we have enough articles in the DB.
export const DemoArticle = () => {
  return (
    <div className='rounded-lg bg-white overflow-hidden mb-4'>
      <div className='grid grid-cols-12'>
        <div className='col-span-5 text-black'>
          <div
            className='h-full w-full bg-cover bg-center'
            style={{
              backgroundImage: `url(${Utilities.getRandomElementFromArray(
                new DemoContent().DemoImages,
              )})`,
            }}
          />
        </div>
        <div className='bg-gray-100 h-full col-span-12 lg:col-span-7 p-4 lg:p-6 lg:px-10 text-black'>
          <div className='flex justify-center pt-2 pb-4'>
            <img className='h-5 self-center fill-red' src={MHPLogo} />
          </div>
          <div>
            <h4 className='font-bold text-lg'>Jordy Yeoman</h4>
            <p className='pt-2'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore.
            </p>
            <div className='grid grid-cols-8 gap-2 pt-8'>
              <div className='col-span-4 flex justify-start'>
                <img className='h-5 self-center fill-red' src={FlameIcon} />
                <img className='h-5 ml-3 self-center' src={ShareIcon} />
                <img className='h-5 ml-3 self-center' src={CommentIcon} />
              </div>
              <div className='col-span-4'>
                <button className='w-full whitespace-nowrap transition ease-in-out duration-300 bg-neutral-600 hover:bg-red py-2 px-4 text-sm text-white rounded-3xl'>
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// TODO - remove and replace with actual comments returned from DB
export const GetRandomCommentComponent = () => {
  let comment = Utilities.getRandomElementFromArray(new DemoContent().DemoComments);
  return (
    <div>
      <h4 className='text-lg font-bold'>{comment.name}</h4>
      <div>{comment.comment}</div>
    </div>
  );
};
// TODO - remove when project tags have been implemented and are returned from project query
export const GetRandomTagComponent = (tagsArr: any) => {
  let tag = Utilities.getRandomElementFromArray(tagsArr.tagsData);
  return (
    <div
      className='text-white p-2 mt-2 pl-4 bg-neutral-600 mr-2'
      style={{
        fontSize: '10px',
        clipPath: 'polygon(25% 0, 100% 0, 100% 100%, 25% 100%, 0% 50%)',
      }}
    >
      {tag}
    </div>
  );
};

export const ProjectPreview = ({ projectData, tagsData }: { projectData: any; tagsData: any }) => {
  return (
    <>
      <div className='rounded-lg bg-white overflow-hidden mb-4'>
        <div className='grid grid-cols-2'>
          <div className='border-blue col-span-2 border-2 bg-cover bg-center h-72' style={{
           backgroundImage: `url(${
             projectData?.projectCoverImage ??
             Utilities.getRandomElementFromArray(new DemoContent().DemoImages)
           })`,
         }}>
            
          </div>
          <div className='border-blue col-span-1 border-2'>
              <h3 className='mb-4 text-black text-xl text-center font-semibold'>
                Heading
              </h3>
          </div>
          <div className='border-blue col-span-1 border-2'>
              <h3 className='mb-4 text-black text-xl text-center font-semibold'>
                Heading
              </h3>
          </div>
        </div>
      </div>
    </>
    // <div className='rounded-lg bg-white overflow-hidden mb-4 border-2 border-red'>
    //   <div
    //     className='h-72 bg-cover bg-center'
    //     style={{
    //       backgroundImage: `url(${
    //         projectData?.projectCoverImage ??
    //         Utilities.getRandomElementFromArray(new DemoContent().DemoImages)
    //       })`,
    //     }}
    //   />
    //   <div className='grid grid-cols-6'>
    //     <div className='h-full col-span-12 xl:col-span-2 p-4 xl:p-6 xl:px-8 text-black'>
    //       <div className='flex'>
    //         <img src={FlameIcon} />
    //         <img className='ml-3' src={ShareIcon} />
    //         <img className='ml-3' src={CommentIcon} />
    //       </div>
    //       <h4 className='font-bold text-lg'>{projectData?.projectCreator ?? 'Missing Userdata'}</h4>
    //       {/* // TODO - Create follow functionality */}
    //       <div className='flex'>
    //         <button className='w-full whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-neutral-600 py-1 px-2 mr-2 text-xs text-white rounded-3xl'>
    //           Read More
    //         </button>
    //         <h5 className='text-xs text-red'>Recommended for you</h5>
    //       </div>
    //       {/* // TODO - Add user tagging ability to link */}
    //       <p className='pt-5'>{projectData?.description ?? ''}</p>
    //       <div className='flex flex-wrap pt-3'>
    //         <GetRandomTagComponent tagsData={tagsData} />
    //         <GetRandomTagComponent tagsData={tagsData} />
    //         <GetRandomTagComponent tagsData={tagsData} />
    //         <GetRandomTagComponent tagsData={tagsData} />
    //         <GetRandomTagComponent tagsData={tagsData} />
    //       </div>
    //     </div>
    //     <div className='bg-gray-100 h-full col-span-12 xl:col-span-4 p-4 xl:p-6 xl:px-10 text-black'>
    //       <GetRandomCommentComponent />
    //       <div className='pt-4'>
    //         <GetRandomCommentComponent />
    //       </div>
    //       <div className='grid grid-cols-8 gap-2 pt-8'>
    //         <div className='col-span-12 lg:col-span-5'>
    //           <input
    //             type='text'
    //             className='text-black p-2 px-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border border-gray-300 rounded-3xl'
    //             placeholder='Hype it up...'
    //           />
    //         </div>
    //         <div className='col-span-12 lg:col-span-3'>
    //           <button className='whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey py-2 px-4 text-sm text-white rounded-3xl'>
    //             View All Comments
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};
