import { gql } from '@apollo/client';

export const GET_ALL_ARTICLES = gql`
  query GetArticles {
    getAllArticles {
      id
      createdBy
      heading
      subHeading
      bodyContentList
      tags
      approved
      articleImageList
      createdAt
    }
  }
`;

export const GET_ALL_ADVERTISERS = gql`
  query GetAllAdvertisers {
    getAllAdvertisers {
      _id
      email
      username
      profilePicture
      firstName
      lastName
    }
  }
`;

export const GET_ALL_PROJECTS = gql`
  query GetAllProjects {
    getAllProjects {
      id
      projectName
      year
      make
      model
      projectCoverImage
      projectCreator
      colour
      description
    }
  }
`;

export const GET_ALL_TAGS = gql`
  query GetTags {
    getAllTags
  }
`;
