import { gql } from '@apollo/client/core';

export const CREATE_ARTICLE = gql`
  mutation CreateArticle($input: ArticleInput) {
    createArticle(input: $input) {
      createdBy
      heading
      subHeading
      bodyContentList
      tags
      articleImageList
      articleTemplate
    }
  }
`;
