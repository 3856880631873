import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, gql, useLazyQuery } from '@apollo/client';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { SearchIcon, UserGroupIcon } from '@heroicons/react/solid';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { useForm, useWatch } from "react-hook-form";

import MHPLogo from '@images/logo-dark.svg';
import TestImage from '@/assets/images/no_image_found.png';
import QuickEnquiry from '@/parts/QuickEnquiry';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

enum SearchCategory {
  ALL = 'all',
  ARTICLE = 'articles',
  PRODUCT = 'products',
  PROJECT = 'projects',
  USER = 'users',
  ADVERTISER = 'advertisers',
}

const tabs = [
  { name: 'People', href: '#', current: false },
  { name: 'Advertisers', href: '#', current: false },
  { name: 'Articles', href: '#', current: true },
  { name: 'Projects', href: '#', current: false },
  { name: 'Products', href: '#', current: false },
]

const people = [
  {
    name: 'Lindsay Walton',
    imageUrl:
      'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80',
  },
]
const activityItems = [
  { id: 1, person: people[0], project: 'Workcation', commit: '2d89f0c8', environment: 'production', time: '1h' },
  { id: 2, person: people[0], project: 'Workcation', commit: '2d89f0c8', environment: 'production', time: '1h' },
  { id: 3, person: people[0], project: 'Workcation', commit: '2d89f0c8', environment: 'production', time: '1h' },
  { id: 4, person: people[0], project: 'Workcation', commit: '2d89f0c8', environment: 'production', time: '1h' },
]

const GLOBAL_APP_SEARCH = gql`
  query Query($input: GlobalIntelligentSearchInput!) {
    globalIntelligentSearch(input: $input) {
      users {
        username
        firstName
        lastName
        profilePicture
      }
      articles {
        id
        heading
        articleImageList
        subHeading
      }
      products {
        id
        title
        price
        productImageURL
        bodyContent
      }
      projects {
        id
        projectCoverImage
        projectName
        projectCreator
        description
      }
      advertisers {
        username
        firstName
        lastName
        profilePicture
      }
    }
  }
`;

const Header = ({ userData, quickEnquiry, triggerQuickEnquiry, showQuickEnquiry }: any) => {
  const navigate = useNavigate();
  const { register, control, handleSubmit, formState: { errors }, reset } = useForm();
  const [searchParam, filterParam] = useWatch({
    control,
    name: ["search", "filter"]
  });
  const [ searchDropdown, setSearchDropdown ] = useState<boolean>(false);
  const [ searchResult, setSearchResult ] = useState<any>([]);
  const [runGlobalIntelligentSearch, { data }] = useLazyQuery(GLOBAL_APP_SEARCH, { 
    variables: { input: { searchQuery: searchParam, category: filterParam }},
    onCompleted: (data: any) => {
      setSearchResult([]);
      data.globalIntelligentSearch[filterParam].map((item: any) => {
        switch(filterParam) {
          case SearchCategory.ARTICLE:
            let articleObject: any = {
              title: item.heading,
              subHeding: item.subHeading,
              actionText: 'View',
              link: `/articles/${item.id}`,
              image: item.articleImageList[0]
            }
            setSearchResult((searchResult: any) => [
              ...searchResult,
              articleObject
            ]);
            break;
          case SearchCategory.PROJECT:
            let projectObject: any = {
              title: item.projectName,
              subHeding: `By ${item.projectCreator}`,
              actionText: 'View',
              link: `/project/${item.id}`,
              image: item.projectCoverImage
            }
            setSearchResult((searchResult: any) => [
              ...searchResult,
              projectObject
            ]);
            break;
          case SearchCategory.PRODUCT:
            let productObject: any = {
              title: item.title,
              subHeding: `Price: ${item.price}`,
              actionText: 'View',
              link: `/products/${item.id}`,
              image: item.projectCoverImage
            }
            setSearchResult((searchResult: any) => [
              ...searchResult,
              productObject
            ]);
            break;
          default:
            let defaultUserObject = {
              title: `${item.firstName} ${item.lastName}`,
              subHeding: 'Click to view',
              actionText: 'View',
              link: `/profile/${item.username}`,
              image: item.profilePicture
            }
            setSearchResult((searchResult: any) => [
              ...searchResult,
              defaultUserObject
            ]);
            break;
        }
      })

      console.log('searchRes', searchResult)
    }
  });

  useEffect(() => {
    handleSearchSite();
  }, [searchParam, handleSubmit])

  const handleSearchSite = () => {
    if(searchParam && searchParam.length > 0) {
      setSearchDropdown(true)
      runGlobalIntelligentSearch();
    } else {
      setSearchDropdown(false)
    }
  }

  return (
    <Disclosure as='nav' className='bg-black fixed left-0 right-0 top-0 z-10'>
      {({ open }: any) => (
        <>
          <div className="container mx-auto px-2 sm:px-4 lg:px-8">
            <div className="relative flex items-center justify-between h-16 lg:grid lg:grid-cols-12">
              <div className={"grid items-center px-2 lg:px-0 logo-container col-span-4 lg:col-span-3 items-center " + (quickEnquiry ? 'opacity-50' : '')}>
                <div className="flex-shrink-0">
                  <Link to="/">
                    <img
                      className="block h-8 w-auto"
                      src={MHPLogo}
                      alt="Workflow"
                    />
                  </Link> 
                </div>
              </div>
              <form className={"grid px-2 lg:col-span-6 w-2/3 lg:w-auto " + (quickEnquiry ? 'opacity-50' : '')} onSubmit={handleSubmit(handleSearchSite)}>
                <div className="w-full">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                      <SearchIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                    </div>
                    <input
                      {...register('search')}
                      id='search'
                      name='search'
                      className='block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-800 text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-gray-100 focus:border-gray-100 focus:ring-white focus:text-gray-900 sm:text-sm'
                      placeholder='Search'
                      type='text'
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center text-red">
                      <label htmlFor="filter" className="sr-only">
                        Filter
                      </label>
                      <select
                        {...register('filter')}
                        id="filter"
                        name="filter"
                        className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value={SearchCategory.USER}>People</option>
                        <option value={SearchCategory.ADVERTISER}>Advertisers</option>
                        <option value={SearchCategory.ARTICLE}>Articles</option>
                        <option value={SearchCategory.PROJECT}>Projects</option>
                        <option value={SearchCategory.PRODUCT}>Products</option>
                      </select>
                    </div>
                    { ( searchDropdown && searchResult ) && <div className="absolute left-0 w-full top-full bg-white border border-transparent rounded-md mt-2">
                      <ul role="list" className="divide-y divide-gray-200 text-gray-500">
                        {searchResult && searchResult.map((item: any, index: any) => (
                          <li key={index}>
                            <a className="block py-4 px-4 hover:bg-gray-200 cursor-pointer" href={item.link}>
                              <div className="flex space-x-3 items-center">
                                <img className="h-6 w-6 rounded-full" src={item.image} alt="" />
                                <div className="flex-1">
                                  <div className="flex items-center justify-between">
                                    <h3 className="text-sm font-bold text-black">{item.title}</h3>
                                    <p className="text-sm text-red">{ item.actionText }</p>
                                  </div>
                                  <p className="text-sm text-gray-500">{ item.subHeading }</p>
                                </div>
                              </div>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div> }
                  </div>
                </div>
              </form>
              <div className={'flex lg:hidden ' + (quickEnquiry ? 'opacity-50' : '')}>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-white transition-ease-in-out duration-250 hover:text-white bg-red hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:block lg:ml-4 lg:grid lg:col-span-3">
                <div className="items-center">

                  {/* Profile dropdown */}
                  <Menu as='div' className='relative flex-shrink-0'>
                    <div className='quick-enquiry-holder col-span-6 pl-6 lg:col-span-3 relative'>
                      <div className='enquiry__wrapper relative'>
                        <button
                          onClick={triggerQuickEnquiry}
                          style={{
                            borderBottomLeftRadius: `${quickEnquiry ? '0' : '0.375rem'}`,
                            borderBottomRightRadius: `${quickEnquiry ? '0' : '0.375rem'}`,
                          }}
                          className='headerbutton w-full rounded-md text-white p-1 transition ease-in-out duration-300 bg-red hover:bg-darkgrey'
                        >
                          <div className='absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none'>
                            <UserGroupIcon className='h-5 w-5 text-white-400' aria-hidden='true' />
                          </div>
                          Quick Enquiry
                        </button>
                        {quickEnquiry ? <QuickEnquiry showQuickEnquiry={showQuickEnquiry} /> : null}
                      </div>
                    </div>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        <Menu.Item>
                          {({ active }: any) => (
                            <a
                              href='#'
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}
                            >
                              Your Profile
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }: any) => (
                            <a
                              href='#'
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='lg:hidden'>
            <div className='px-2 pt-2 pb-3 space-y-1'>
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              <div className='quick-enquiry-holder col-span-6 mx-6 mb-6 lg:col-span-3 relative'>
                <div className='enquiry__wrapper relative'>
                  <button
                    onClick={triggerQuickEnquiry}
                    style={{
                      borderBottomLeftRadius: `${quickEnquiry ? '0' : '0.375rem'}`,
                      borderBottomRightRadius: `${quickEnquiry ? '0' : '0.375rem'}`,
                    }}
                    className='headerbutton w-full rounded-md text-white p-1 py-2 transition ease-in-out duration-300 bg-red hover:bg-darkgrey'
                  >
                    <div className='absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none'>
                      <UserGroupIcon className='h-5 w-5 text-white-400' aria-hidden='true' />
                    </div>
                    Quick Enquiry
                  </button>
                  {quickEnquiry ? <QuickEnquiry /> : null}
                </div>
              </div>
              <div className={'quickenquiry-enabled-overlay ' + (quickEnquiry ? 'opacity-50' : '')}>
                <Disclosure.Button
                  as='a'
                  href='/'
                  className='bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Home
                </Disclosure.Button>
                <Disclosure.Button
                  as='a'
                  href='/projects'
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  My Projects
                </Disclosure.Button>
                <Disclosure.Button
                  as='a'
                  href='/messages'
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Messages
                </Disclosure.Button>
                <Disclosure.Button
                  as='a'
                  href='/articles'
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  My Articles
                </Disclosure.Button>
                {userData.userAccountType === 'advertiser' && (
                <Disclosure.Button
                  as='a'
                  href='/products'
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  My Products
                </Disclosure.Button>
                )}
              </div>
            </div>
            <div
              className={'pt-4 pb-3 border-t border-gray-700 ' + (quickEnquiry ? 'opacity-50' : '')}
            >
              <div className='flex items-center px-5'>
                <div className='flex-shrink-0'>
                  <img
                    className='h-10 w-10 rounded-full'
                    src={userData.profilePicture ? userData.profilePicture : TestImage}
                    alt=''
                  />
                </div>
                <div className='ml-3'>
                  <div className='text-base font-medium text-white'>
                    {userData.firstName} {userData.lastName}
                  </div>
                  <div className='text-sm font-medium text-gray-400'>{userData.email}</div>
                </div>
                {/* <button
                  type="button"
                  className="ml-auto flex-shrink-0 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}
              </div>
              <div className='mt-3 px-2 space-y-1'>
                <Disclosure.Button
                  as='a'
                  href={`/profile/${userData.username}`}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                >
                  Your Profile
                </Disclosure.Button>
                <Disclosure.Button
                  as='a'
                  href='#'
                  className='block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700'
                >
                  Logout
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Header;