import { useEffect, useRef, useState } from 'react';
import { DemoProject } from './demoContent';
import { Utilities } from './utilities';

export const ScrollingContent = () => {
  const itemEls = useRef(new Array());
  const [width, setWidth] = useState(window.innerWidth);
  const amountOfDemoProjects = 40;
  let numberOfColumnsOfProjects = 3;

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    // Set random offset scroll position of elements
    itemEls?.current?.map((item) => {
      if (item) {
        item.scrollTop = Utilities.getRandomNumberFromRange(0, 300);
      }
    });
    // Check window exists before trying to add event listener
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateDimensions);
      return () => window.removeEventListener('resize', updateDimensions);
    }
  }, []);

  // If width < 800px, set num of columns to 2
  if (width < 1250) {
    numberOfColumnsOfProjects = 2;
  }

  // TODO - When sufficient customers exist in the DB we can poll a bunch of projects - cache and then render based on the real data.
  return (
    <div className='h-screen relative bg-slate-300 pt-4'>
      <div className='relative flex px-2'>
        {[...Array(numberOfColumnsOfProjects)].map((e, i: number) => {
          return (
            <div
              // Generate margin value so columns are offset randomly
              className='w-auto -mt-4 px-2 mx-auto relative flex h-screen flex-col overflow-y-scroll hide-scrollbar'
              key={i}
              ref={(element) => itemEls.current.push(element)}
            >
              {[...Array(amountOfDemoProjects)].map((e, i: number) => (
                <DemoProject key={i} />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const scrollingVariant = {};
