import { useMutation, gql } from "@apollo/client";
import { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";

import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';

const SEND_ENQUIRY_MUTATION = gql`
  mutation Mutation($input: EnquiryInput) {
    createQuickEnquiry(input: $input)
  }
`;

const QuickEnquiry = ({ showQuickEnquiry }: any) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const { userData } = useSelector((state: RootState) => state.user);
    const [sendEnquiryMutation] = useMutation(SEND_ENQUIRY_MUTATION);

    const [ enquirySubmitting, setEnquirySubmitting ] = useState(false);
    const [ submissionComplete, setSubmissionComplete ] = useState(false);
    const [formError, setFormError] = useState('');

    const handleEnquirySubmit = async (inputs: any) => {
        setEnquirySubmitting(true)
        const { topic, location, phone, description } = inputs;
        try {
        const { data } = await sendEnquiryMutation({
            variables: {
                input: {
                    topic,
                    location,
                    phone,
                    description,
                    userId: userData._id
                }
            }
        });
        if(data.createQuickEnquiry) {
            setEnquirySubmitting(false)
            setSubmissionComplete(true)
        }
        } catch (err: any) {
            setEnquirySubmitting(false)
            setFormError(err.message)
        }
    }

    // Close Quick Enquiry when clicked outside of area of #quick-enquiry
    const quickEnquiryRef = useRef<any>(null);
    const mainArea = document.querySelector('.contentwrapper');

    useEffect(() => {

        function clickOutsideQuickEnquiry(e: any) {
            if(!quickEnquiryRef.current?.includes(e.target)) {            
                showQuickEnquiry(false);
            }
        }

        mainArea?.addEventListener('click', clickOutsideQuickEnquiry);
        return () => mainArea?.removeEventListener('click', clickOutsideQuickEnquiry);

    }, [quickEnquiryRef])

    return (
        <>
        <div className="w-full absolute bg-black border-red border-2 rounded-b-lg z-10 top-full p-4" id="quick-enquiry" ref={quickEnquiryRef.current}>
            { submissionComplete ? <div className="text-center py-3"><h3 className="font-bold text-lg">Thank you for your enquiry.</h3><p>We will get back to you as soon as we can.</p></div> : <><form onSubmit={handleSubmit(handleEnquirySubmit)} className="quick-enquiryform">
                { enquirySubmitting ? <LoadingSpinner /> : <>
                    <div className="pb-3">
                        <input 
                            {...register("topic", {
                                required: "Please type a product or a service.",
                                minLength: {
                                    value: 1,
                                    message: "Minimum 1 character."
                                }
                            })}
                            className="w-full text-black mb-3 text-sm rounded-xl px-3 py-1 placeholder-black" 
                            type="text" 
                            placeholder="Product or Service" 
                        />
                        {errors.topic && <span role="alert" className="text-xs block -mb-1 text-red">{errors.topic.message}</span>}
                    </div>
                    <div className="pb-3">
                        <input 
                            {...register("location")}
                            className="w-full text-black mb-3 text-sm rounded-xl px-3 py-1 placeholder-black" 
                            type="text" 
                            placeholder="Location" 
                        />
                        {errors.location && <span role="alert" className="text-xs block -mb-1 text-red">{errors.location.message}</span>}
                    </div>
                    <div className="pb-3">
                        <input 
                            {...register("phone", {
                                required: "Please type your phone number.",
                                minLength: {
                                    value: 5,
                                    message: "Minimum 5 character."
                                }
                            })}
                            className="w-full text-black mb-3 text-sm rounded-xl px-3 py-1 placeholder-black" 
                            type="text" 
                            placeholder="Phone number" 
                        />
                        {errors.phone && <span role="alert" className="text-xs block -mb-1 text-red">{errors.phone.message}</span>}
                    </div>
                    <div className="pb-3">
                        <textarea
                            {...register("description", {
                                required: "Please add a message.",
                                minLength: {
                                    value: 5,
                                    message: "Minimum 5 character."
                                }
                            })} 
                            className="w-full text-black mb-3 text-sm rounded-xl px-3 py-1 placeholder-black" 
                            rows={4} 
                            placeholder="Description" 
                        />
                        {errors.description && <span role="alert" className="text-xs block -mb-1 text-red">{errors.description.message}</span>}
                    </div>
                    <button className="text-white bg-red rounded-lg px-6 py-2 inline-block order-2 text-center float-right" type="submit">Send</button>
                </>}
            </form></>}
        </div>
        </>
    )
}

export default QuickEnquiry;