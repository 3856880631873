import { getHTMLFromDraftState, Utilities } from '@/utils/utilities';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ArticleData } from '../../SingleArticle';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';

export const ArticleTemplateOneDisplay = ({
  userData,
  articleData,
}: {
  articleData: ArticleData;
  userData: any;
}) => {
  const { heading, subHeading, bodyContentList, articleImageList, createdAt } =
    articleData || ({} as ArticleData);
  const [lightBoxOpen, setLightBoxOpen] = useState<boolean>(false);
  const [galleryIndex, setGalleryIndex] = useState<number>(0);

  console.log('Yo: ', articleData);

  const navigate = useNavigate();

  // Parse WYSIWYG Content
  const markup = getHTMLFromDraftState(bodyContentList[0]);

  console.log('Gimme a date brah: ', articleData);

  return (
    <>
      <div className='relative gap-2 grid grid-cols-12 pb-12'>
        <div className='col-span-12 lg:col-span-8 lg:pr-3 relative'>
          <div className='col-span-12 text-white text-3xl mt-2 font-bold pb-2'>
            {Utilities.capitalizeFirstLetter(heading)}
          </div>
          <div className='grid grid-flow-col col-span-12 border-red border-y border-t-0 pb-4 items-center my-2'>
            <div className='left grid grid-flow-row grid-flow-col col-span-1 col-span-2 justify-start items-center'>
              <div className='col-span-3 grid'>
                <Link to='/profile'>
                  <img
                    className='author-profile-image rounded-full'
                    width='48px'
                    height='48px'
                    src={userData.profilePicture ? userData.profilePicture : ''}
                  />
                </Link>
              </div>
              <div className='col-span-1 lg:col-span-12 ml-4'>
                <div className='mt-1 text-md text-white font-bold'>
                  {userData.firstName} {userData.lastName}
                </div>
                <div className='mt-1 text-xs text-white'>
                  {new Date(createdAt)?.toLocaleDateString()}
                </div>
              </div>
            </div>
            <div className='right col-span-1 lg:col-span-2 mt-4 mb-2 lg:my-0 text-right'>
              <button
                className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </div>
          <div className='grid grid-flow-row col-span-12 mt-3 mb-2'>
            <h3 className='col-span-12 mb-2 font-bold text-md'>
              {Utilities.capitalizeFirstLetter(subHeading)}
            </h3>
            {articleImageList ? (
              <div
                className='col-span-12 mt-0 mb-5 rounded-lg h-80 w-full bg-cover bg-center cursor-pointer'
                style={{ backgroundImage: `url(${articleImageList[0]})` }}
              />
            ) : (
              ''
            )}
            <div className='col-span-12 text-white text-sm break-all mt-4'>
              <div dangerouslySetInnerHTML={{ __html: markup }} />
            </div>
          </div>
        </div>
        {lightBoxOpen ? (
          <Lightbox
            startIndex={galleryIndex}
            onClose={() => {
              setLightBoxOpen(false);
            }}
            images={articleImageList}
          ></Lightbox>
        ) : null}
      </div>
    </>
  );
};
