import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

export const GET_ALL_PRODUCTS = gql`
query Query($userId: ID, $filters: GetProductFilter) {
    getAllProducts(userId: $userId, filters: $filters) {
      id
      title
      productImageURL
      bodyContent
      price
      createdBy
    }
  }
`;

function RecommendedProducts() {
  const {
    loading: productLoading,
    error: productError,
    data: productData,
  } = useQuery(GET_ALL_PRODUCTS, {
    variables: {
        filters: {
            vendor: [],
            category: [],
            tag: []
        }
    }
  });

  return (
    <div className='mb-4'>
      <h4 className='text-white font-semibold text-xl mb-2'>Recommended Products</h4>
      <div className='flex flex-row'>
        {productData && productData.getAllProducts && (
          <Swiper
            slidesPerView={4}
            spaceBetween={16}
            className='overflow-hidden flex-1 custom-swiper'
            breakpoints={{
              // when window width is >= 768px
              768: {
                spaceBetween: 16,
                slidesPerView: 6,
              },
              1400: {
                slidesPerView: 8,
              },
            }}
          >
            {productData.getAllProducts.map((product: any) => {
              return (
                <SwiperSlide className='relative' key={product.id}>
                  <Link
                    to={`/products/${product.id}`}
                    state={{
                      title: product.title,
                      price: product.price,
                      bodyContent: product.bodyContent,
                      productImageURL: product.productImageURL,
                      productId: product.id,
                    }}
                  >
                    <img className='rounded-lg w-[98px] h-[98px]' src={product.productImageURL} />
                    <h3
                      className='absolute text-[10px] bottom-0 w-full p-2 font-bold whitespace-nowrap overflow-hidden text-ellipsis'
                      style={{ backgroundColor: '#000000a8' }}
                    >
                      {product.title}
                    </h3>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
}

export default RecommendedProducts;
