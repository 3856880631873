import steeringWheel from '@images/steering-wheel-icon.svg'
import { GET_ALL_PROJECTS } from '../../utils/queries/queries';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { Link } from 'react-router-dom';

function CommunityProjects() {

    const {
        loading: projectsLoading,
        error: projectsError,
        data: projectsData,
      } = useQuery(GET_ALL_PROJECTS);

    return (
        <>
            {projectsLoading ? (
                <LoadingSpinner />
            ) :
            <div className="bg-white rounded-md mt-10 p-5 text-black mb-10">
            <div>
                <img className="m-auto" src={steeringWheel} />
                <h4 className="w-full text-xl font-bold text-center mb-5">Projects for you</h4>
            </div>
            <div className="grid grid-cols-2 gap-6">
                {projectsData.getAllProjects.slice(0, 4).map((project: any, index: any) => (
                    <div key={index} className="col-span-2 md:col-span-1">
                        <Link 
                            to={`/project/${project.id}`}
                            state={{
                                projectId: project.id,
                                projectName: project.projectName,
                                projectCoverImage: project.projectCoverImage,
                                year: project.year,
                                make: project.make,
                                model: project.model,
                                colour: project.colour,
                                description: project.description,
                            }}
                        >
                            <img className="object-cover h-32 w-full rounded-md" src={project.projectCoverImage} />
                            <div className="flex -mt-1 items-center px-3">
                                <img className="rounded-full border-2 border-white w-10 h-10" src={project.projectCoverImage} />
                                <h4 className="ml-4 font-bold">{project.projectCreator}</h4>
                                <div className="ml-auto text-red font-bold text-lg">{project.make}</div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
            }
        </>
        
    )
}

export default CommunityProjects