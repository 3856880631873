export enum UserActionType {
  SET_NAME = 'SET_NAME',
  SET_USERDATA = 'SET_USERDATA',
  SET_PROFILE_PHOTO = 'SET_PROFILE_PHOTO',
}

interface ISetNameAction {
  readonly type: UserActionType.SET_NAME;
  payload: string;
}

interface ISetProfilePhotoAction {
  readonly type: UserActionType.SET_PROFILE_PHOTO;
  payload: string;
}

interface ISetUserDataAction {
  readonly type: UserActionType.SET_USERDATA;
  payload: any;
}
export type UserActions = ISetNameAction | ISetUserDataAction | ISetProfilePhotoAction