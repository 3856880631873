import { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useMutation, gql, useQuery, useLazyQuery } from '@apollo/client';
import LoadingSpinner from '../../utils/loading/LoadingSpinner';
import TestImage from '@/assets/images/no_image_found.png';
import { useForm } from 'react-hook-form';
import { Utilities } from '@/utils/utilities';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import { isKeyObject } from 'util/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { PlusIcon } from '@heroicons/react/outline';
import Shop from './Shop';
import NewMessagePopup from '@/components/usercomponents/NewMessagePopup';

enum UserImageType {
  profile,
  cover,
}

const GET_MY_ID = gql`
  query Query {
    me {
      _id
      username
    }
  }
`;

const GET_USERS_DATA = gql`
  query Query($userId: ID) {
    getProjects(userId: $userId) {
      id
      projectName
      projectCoverImage
      year
      make
      model
      colour
      description
    }
    getArticles(userId: $userId) {
      id
      createdBy
      heading
      subHeading
      bodyContentList
      articleImageList
    }
    getProducts(userId: $userId) {
      id
      createdBy
      title
      bodyContent
      productImageURL
    }
  }
`;

const GET_USER_PROFILE = gql`
  query userProfile($username: String!) {
    userProfile(username: $username) {
      _id
      email
      userAccountType
      firstName
      lastName
      username
      account_status
      completedInitialProfileSetup
      profilePicture
    }
  }
`;

const GET_POSTS = gql`
  query Query($userId: ID) {
    getFeeds(userId: $userId) {
      heading
      bodyContent
      feedImage
      tags
    }
  }
`;

const Profile = () => {
  const navigate = useNavigate();
  const { username } = useParams();
  const { userData } = useSelector((state: RootState) => state.user);
  const {
    loading,
    error,
    data: profileData,
  } = useQuery(GET_USER_PROFILE, {
    variables: { username },
  });
  const [fetchUserData, { data }] = useLazyQuery(GET_USERS_DATA);
  const [fetchPostsData, { data: getPostsData }] = useLazyQuery(GET_POSTS);
  const { loading: myIdLoading, data: myIdData } = useQuery(GET_MY_ID);
  const [selected, setSelected] = useState<AdvertiserFeedSelection>(AdvertiserFeedSelection.FEED);
  const [messageUser, setMessageUser] = useState<boolean>(false);

  useEffect(() => {
    console.log('profileData', profileData);
    profileData &&
      profileData.userProfile &&
      (() => {
        fetchUserData({ variables: { userId: profileData.userProfile._id } });
        fetchPostsData({ variables: { userId: profileData.userProfile._id } });
      })();
  }, [profileData]);

  function getProfileType(accountType: String) {
    // These should be enums
    switch (accountType) {
      case 'admin':
        return 'Admin Account';
      case 'advertiser':
        return 'Advertiser Account';
      default:
        return 'Standard Account';
    }
  }

  const handleCategoryClick = (category: string) => {
    // TODO - Add update method to apply product filter automatically
    // EG -> User clicks 'engine', then the engine category filter is automatically checked
    console.log('Category Clicked: ', category);
    setSelected(AdvertiserFeedSelection.PRODUCT);
  };

  const handleMessageUserClickFnc = () => {
    setMessageUser(!messageUser);
  };

  console.log('Articles: ', data?.getArticles);

  return (
    <div className='mb-10'>
      <div className='md:grid grid-cols-1 xl:grid-cols-3 md:gap-6 lg:mt-6'>
        <div className='md:col-span-2'>
          <div className='px-4 sm:px-0'>
            {profileData && profileData.userProfile && (
              <div className='flex flex-col items-center justify-center'>
                <img
                  className='rounded-full border border-2 border-white w-16 h-16 object-cover'
                  src={
                    profileData?.userProfile ? profileData?.userProfile?.profilePicture : TestImage
                  }
                  // Add referrer policy to stop Google profile images src issue.
                  referrerPolicy='no-referrer'
                />
                <h1 className='block text-center text-lg font-bold mt-3'>
                  {profileData.userProfile.firstName} {profileData.userProfile.lastName}
                </h1>
                <p className='block text-center text-sm mt-1'>
                  {profileData?.userProfile
                    ? getProfileType(profileData.userProfile.userAccountType)
                    : ''}
                </p>
              </div>
            )}
          </div>
          {myIdData?.me?.username === profileData?.userProfile?.username ? (
            <ActionButtonHolder profileData={profileData} />
          ) : (
            ''
          )}
          {profileData &&
            profileData.userProfile &&
            profileData.userProfile.userAccountType === 'advertiser' && (
              <>
                <TabSelector
                  selected={selected}
                  handleSelection={setSelected}
                  handleMessageUserClick={handleMessageUserClickFnc}
                />
                {/* <CategoriesList handleClick={handleCategoryClick} />
                <Swiper
                  breakpoints={{
                    640: {
                      slidesPerView: 4,
                    },
                    768: {
                      slidesPerView: 6,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                    1280: {
                      slidesPerView: 4,
                    },
                  }}
                ></Swiper> */}
                <TabContent
                  data={data}
                  tabSelected={selected}
                  feedData={getPostsData}
                  profileData={profileData}
                />
              </>
            )}
        </div>
        <div className='mt-5 md:mt-8 md:col-span-1'>
          {myIdData?.me?.username === profileData?.userProfile?.username ? (
            <>
              <div className='action-btn-holder hidden xl:grid grid-cols-2 grid-flow-row'>
                <Link
                  className='col-span-1 mx-2 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
                  to='/projects/add'
                >
                  <span className='text-xl mr-1'>+</span> Add Project
                </Link>
                <Link
                  className='col-span-1 mx-2 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
                  to='/articles/add'
                >
                  <span className='text-xl mr-1'>+</span> Add Article
                </Link>
                {profileData &&
                profileData.userProfile &&
                profileData.userProfile.userAccountType === 'advertiser' ? (
                  <>
                    <Link
                      className='col-span-1 mx-2 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
                      to='/post/add'
                    >
                      <span className='text-xl mr-1'>+</span> Add Post
                    </Link>
                    <Link
                      className='col-span-1 mx-2 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
                      to='/products/add'
                    >
                      <span className='text-xl mr-1'>+</span> Add Product
                    </Link>
                  </>
                ) : (
                  ''
                )}
              </div>
            </>
          ) : (
            ''
          )}
          <h2 className='text-lg font-bold mt-8'>Latest Articles</h2>
          {data && profileData && data.getArticles.length ? (
            data?.getArticles?.map((article: any) => {
              return (
                <div
                  key={article.id}
                  className='relative xl:col-span-1 m-2 grid grid-flow-row ml-0'
                >
                  <Link
                    className='single-project rounded-lg transition-all hover:opacity-90 h-48 bg-center bg-cover'
                    style={{
                      backgroundImage: `url(${
                        article?.articleImageList != '' || null
                          ? article?.articleImageList[0]
                          : TestImage
                      })`,
                    }}
                    to={`/articles/${article.id}`}
                    state={{
                      articleId: article.id,
                      heading: article.heading,
                      subHeading: article.subHeading,
                      bodyContentList: article.bodyContentList,
                      articleImageList: article.articleImageList,
                      userId: profileData.userProfile.username,
                    }}
                  >
                    <h3
                      className='absolute text-md bottom-0 w-full px-3 py-2 font-bold'
                      style={{ backgroundColor: '#000000a8' }}
                    >
                      {article.heading}
                    </h3>
                  </Link>
                </div>
              );
            })
          ) : (
            <h3 className='-ml-4 md:ml-0'>This user currently has no projects.</h3>
          )}
          <ProjectsList data={data} />
        </div>
      </div>
      {messageUser && <NewMessagePopup userId={profileData.userProfile._id} />}
    </div>
  );
};

export default Profile;

const TabContent = ({
  data,
  tabSelected,
  feedData,
  profileData,
}: {
  data: any;
  tabSelected: AdvertiserFeedSelection;
  feedData: any;
  profileData: any;
}) => {
  switch (tabSelected) {
    case AdvertiserFeedSelection.FEED:
      return <PostsFeed data={feedData} />;
    case AdvertiserFeedSelection.PRODUCT:
      return <Shop profile={profileData.userProfile} />;
    default:
      return <PostsFeed data={feedData} />;
  }
};

const PostsFeed = ({ data }: { data: any }) => {
  if (data && data.getFeeds.length > 0) {
    return (
      <>{data && data.getFeeds.map((post: any, index: any) => <Post post={post} key={index} />)}</>
    );
  } else {
    return <div className='my-8'>No posts available</div>;
  }
};

const Post = ({ post }: any) => {
  console.log('Post', post);
  return (
    <div className='bg-white w-full mt-2 rounded relative'>
      <div className='h-[75%] w-full'>
        <img
          className='border-white h-full w-full object-cover'
          src={post.feedImage}
          referrerPolicy='no-referrer'
        />
      </div>
      <div className='px-6 py-4'>
        <div className='text-black font-bold text-lg'>{post.heading}</div>
        <div className='text-black'>{post.bodyContent}</div>
        {post &&
          post.tags.map((tag: any, index: any) => (
            <div
              key={index}
              className='bg-indigo-100 inline-flex items-center text-xs rounded mt-2 mr-1 p-0.5 text-black'
            >
              <span className='ml-2 mr-1 leading-relaxed truncate max-w-xs'>{tag}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

const CategoriesItems = [
  'Engines',
  'Exhausts',
  'Tyres',
  'Turbos',
  'Rims',
  'Intercoolers',
  'Suspension',
  'Stickers',
  'Misc',
]; // TODO: Add dynamic list based on advertiser

const CategoriesList = ({ handleClick }: { handleClick: Function }) => {
  return (
    <div className='articles-section mt-2'>
      <h2 className='text-lg font-bold'>Categories</h2>
      <div className='px-4 sm:px-0 grid grid-cols-3 md:grid-cols-5'>
        {CategoriesItems.map((item) => (
          <div key={item} className='relative xl:col-span-1 m-2 grid grid-flow-row ml-0'>
            <div
              onClick={() => {
                handleClick(item);
              }}
              className='single-project rounded-lg transition-all hover:opacity-90 h-24 bg-cover bg-center'
              style={{
                backgroundImage: `url(${TestImage})`,
              }}
            >
              <h3
                className='absolute text-md bottom-0 w-full px-3 py-2 font-bold'
                style={{ backgroundColor: '#000000a8' }}
              >
                {item}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ActionButtonHolder = ({ profileData }: { profileData: any }) => {
  return (
    <div className='grid grid-cols-4 action-btn-holder w-full mt-4 xl:hidden'>
      <Link
        className='col-span-2 md:col-span-1 mx-2 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
        to='/projects/add'
      >
        <span className='text-xl mr-1'>+</span> Add Project
      </Link>
      <Link
        className='col-span-2 md:col-span-1 mx-2 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
        to='/articles/add'
      >
        <span className='text-xl mr-1'>+</span> Add Article
      </Link>

      {profileData &&
      profileData.userProfile &&
      profileData.userProfile.userAccountType === 'advertiser' ? (
        <>
          <Link
            className='col-span-2 md:col-span-1 mx-2 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
            to='/products/add'
          >
            <span className='text-xl mr-1'>+</span> Add Product
          </Link>
          <Link
            className='col-span-2 md:col-span-1 mx-2 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
            to='/post/add'
          >
            <span className='text-xl mr-1'>+</span> Add Post
          </Link>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export enum AdvertiserFeedSelection {
  FEED,
  PRODUCT,
}

const TabSelector = ({
  selected,
  handleSelection,
  handleMessageUserClick,
}: {
  selected: AdvertiserFeedSelection;
  handleSelection: Function;
  handleMessageUserClick: Function;
}) => {
  return (
    <div className='flex flex-row mt-2 justify-center'>
      <div
        className={`w-full max-w-[8rem] pt-2 transition duration-300 border-b-2 border-transparent ${
          selected === AdvertiserFeedSelection.FEED ? 'pb-1 border-red' : ''
        }`}
      >
        <div
          onClick={() => {
            handleSelection(AdvertiserFeedSelection.FEED);
          }}
          className={`cursor-pointer col-span-1 mx-0 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl `}
        >
          Feed
        </div>
      </div>
      <div className='w-3'></div>
      <div
        className={`w-full max-w-[8rem] pt-2 transition duration-300 border-b-2 border-transparent ${
          selected === AdvertiserFeedSelection.PRODUCT ? 'pb-1 border-red' : ''
        }`}
      >
        <div
          onClick={() => {
            handleSelection(AdvertiserFeedSelection.PRODUCT);
          }}
          className={`cursor-pointer col-span-1 mx-0 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl`}
        >
          Products
        </div>
      </div>
      <div className='w-3'></div>
      <div className='w-full max-w-[8rem] pt-2 transition duration-300 border-b-2 border-transparent'>
        <div
          onClick={() => handleMessageUserClick()}
          className='cursor-pointer col-span-1 mx-0 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
        >
          Message User
        </div>
      </div>
    </div>
  );
};

const ProductsList = ({ data }: { data: any }) => {
  return (
    <div className='articles-section mt-4'>
      <div className='h-48 rounded bg-white w-full'>
        <div className='font-bold text-3xl text-black p-4'>Product Filter Goes Here</div>
      </div>
      <div className='px-4 sm:px-0 grid grid-cols-3 md:grid-cols-5 mt-2'>
        {data && data.getProducts.length ? (
          data.getProducts.map((product: any) => {
            return (
              <div key={product.id} className='relative xl:col-span-1 m-2 grid grid-flow-row ml-0'>
                <Link
                  className='single-project rounded-lg transition-all hover:opacity-90 h-24 bg-cover bg-center'
                  style={{
                    backgroundImage: `url(${
                      product?.productImageURL && product?.productImageURL !== ''
                        ? product.productImageURL
                        : TestImage
                    })`,
                  }}
                  to={`/products/${product.id}`}
                  state={{
                    title: product.title,
                    price: product.price,
                    bodyContent: product.bodyContent,
                    productImageURL: product.productImageURL,
                    productId: product.id,
                  }}
                >
                  <h3
                    className='absolute text-md bottom-0 w-full px-3 py-2 font-bold'
                    style={{ backgroundColor: '#000000a8' }}
                  >
                    {product.title}
                  </h3>
                </Link>
              </div>
            );
          })
        ) : (
          <h3 className='-ml-4 md:ml-0'>This user currently has no products.</h3>
        )}
      </div>
    </div>
  );
};

const ProjectsList = ({ data }: { data: any }) => {
  return (
    <>
      <h2 className='text-lg font-bold mt-8'>Projects</h2>
      <div className='px-4 sm:px-0 mt-4'>
        {data && data.getProjects.length ? (
          data.getProjects.map((project: any) => {
            return (
              <div key={project.id} className='relative xl:col-span-1 m-2 grid grid-flow-row ml-0'>
                <Link
                  className='single-project rounded-lg transition-all hover:opacity-90 h-48 bg-center bg-cover'
                  style={{
                    backgroundImage: `url(${
                      project?.projectCoverImage != '' ? project.projectCoverImage : TestImage
                    })`,
                  }}
                  to={`/project/${project.id}`}
                  state={{
                    projectId: project.id,
                    projectName: project.projectName,
                    projectCoverImage: project.projectCoverImage,
                    year: project.year,
                    make: project.make,
                    model: project.model,
                    colour: project.colour,
                    description: project.description,
                  }}
                >
                  <h3
                    className='absolute text-md bottom-0 w-full px-3 py-2 font-bold'
                    style={{ backgroundColor: '#000000a8' }}
                  >
                    {project.projectName}
                  </h3>
                </Link>
              </div>
            );
          })
        ) : (
          <h3 className='-ml-4 md:ml-0'>This user currently has no projects.</h3>
        )}
      </div>
    </>
  );
};
