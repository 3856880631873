import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { gql, useQuery } from '@apollo/client';
import { XIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';

const GET_ALL_TAGS = gql`
  query GetAllTags {
    getAllTags
  }
`;

// TODO - add better name for the tag
export enum MHPSearchTagType {
  Article = 'article',
  Project = 'project',
  Product = 'product',
}

function TagSearch({
  tagType,
  handleFormTags,
}: {
  tagType: MHPSearchTagType;
  handleFormTags: Function;
}) {
  const [allTags, setAllTags] = useState<Array<any>>([]);
  const [userTags, setUserTags] = useState<Array<any>>([]);
  const [suggestedTags, setSuggestedTags] = useState<Array<any>>([]);
  const [tagSearchQuery, setTagSearchQuery] = useState<string>();
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [tagsSelectedError, setTagsSelectedError] = useState(false);

  const {
    loading: tagsLoading,
    error: tagsError,
    data: tagsData,
  } = useQuery(GET_ALL_TAGS, {
    onCompleted: (data) => {
      setAllTags(data.getAllTags);
    },
  });

  const handleTagSearch = (e: any) => {
    const query = e.target.value.toLowerCase();
    setTagSearchQuery(query);
    if (query.length > 1) {
      const filterSuggestions = allTags.filter(
        (suggestion) => suggestion.toLowerCase().indexOf(query) > -1,
      );
      console.log(filterSuggestions);
      setSuggestedTags(filterSuggestions);
      setSuggestionsActive(true);
    } else {
      setSuggestionsActive(false);
    }
  };

  function toggleSelect(newTag: any) {
    if (userTags.includes(newTag)) {
      let filteredTagArr = userTags.filter((el) => el !== newTag);
      setUserTags(filteredTagArr);
      handleFormTags(filteredTagArr);
      return;
    }
    if (userTags.length === 5) {
      setTagsSelectedError(false);
      return;
    }
    var newTags: any = [...userTags, newTag];
    setUserTags((userTags) => [...userTags, newTag]);
    handleFormTags(newTags);
  }

  const handleRemoveTag = (tag: string) => {
    let updatedUserTags = userTags.filter((e) => e !== tag);
    setUserTags(updatedUserTags);
    handleFormTags(updatedUserTags);
  };

  return (
    <div>
      {tagsLoading ? (
        <LoadingSpinner />
      ) : (
        <div className='mt-1'>
          <input
            type='search'
            placeholder={`Help people find your ${tagType} by adding tags`}
            className='text-black p-2 shadow-sm focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
            onChange={handleTagSearch}
          />
          {userTags && (
            <ul>
              {userTags.map((tag, index) => (
                <div
                  className='bg-red50 inline-flex items-center text-sm rounded mt-2 mr-1 p-1 mb-1'
                  key={index}
                >
                  <span
                    className='ml-2 mr-1 leading-relaxed truncate max-w-xs text-gray-700 font-bold'
                    key={index}
                  >
                    {tag}
                  </span>
                  <span onClick={() => handleRemoveTag(tag)} className='ml-1 cursor-pointer'>
                    <XIcon className='h-4 w-4 text-red' aria-hidden='true' />
                  </span>
                </div>
              ))}
            </ul>
          )}
          {suggestionsActive && suggestedTags ? (
            <div className='mt-1 grid grid-cols gap-y-1 gap-x-1 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8'>
              {suggestedTags.map((tag: any, i: any) => (
                <div
                  key={i}
                  onClick={() => {
                    toggleSelect(tag);
                  }}
                  className='group relative cursor-pointer'
                >
                  <div
                    className={`py-1 px-2 w-full text-black bg-gray-100 rounded-md group-hover:opacity-75 border-2 ${
                      userTags.includes(tag) ? 'border-red' : 'border-transparent'
                    } hover:border-red hover:bg-gray-200 transition duration-300 ease-in-out`}
                  >
                    <div>{tag}</div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default TagSearch;
