// Import packages
import { useLocation, useNavigate } from 'react-router-dom'

// Import icons
import PistonIcon from '@/assets/icons/PistonIcon'
import TestImage from '@/assets/images/no_image_found.png'

const SingleProject = () => {

    interface ProjectData {
        projectId: string;
        projectName: string;
        projectCoverImage: string;
        year: number;
        make: string;
        model: string;
        colour: string;
        description: string;
    }

    const navigate = useNavigate();
    const location = useLocation();

    const { projectId, projectName, projectCoverImage, year, make, model, colour, description } = location.state as ProjectData;

    return (
        <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-8 lg:pr-3 relative">
                <div className="flex flex-row text-white font-semibold items-center text-xl justify-end mb-8 w-full">
                    <button className="col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl" onClick={() => navigate(-1)}>Go Back</button>
                </div>

                <div className="single-project-images-container relative gap-2 grid grid-cols-12">
                    <div className="single-project-featured-image col-span-12 my-2 rounded-lg h-80 bg-cover bg-center" style={{ backgroundImage: `url(${projectCoverImage ? projectCoverImage : TestImage})` }} />
                    <h2 className="single-project-title -mt-16 col-span-12 text-xl bottom-0 w-full px-4 py-2 font-bold" style={{ 'backgroundColor': '#000000a8' }}>
                        {projectName}
                    </h2>
                </div>

                <div 
                    className="single-project-description-container p-6 grid grid-flow-row grid-cols-12 my-2 gap-2 rounded-lg bg-white">
                    <div className="single-project-feature engine-specs col-span-3 py-4 flex items-center flex-col">
                        <PistonIcon />
                        <h5 className="mt-4 text-black font-bold text-sm text-center">{make}</h5>
                    </div>
                    <div className="single-project-feature engine-specs col-span-3 py-4 flex items-center flex-col">
                        <PistonIcon />
                        <h5 className="mt-4 text-black font-bold text-sm text-center">{model}</h5>
                    </div>
                    <div className="single-project-feature engine-specs col-span-3 py-4 flex items-center flex-col">
                        <PistonIcon />
                        <h5 className="mt-4 text-black font-bold text-sm text-center">{year}</h5>
                    </div>
                    <div className="single-project-feature engine-specs col-span-3 py-4 flex items-center flex-col">
                        <PistonIcon />
                        <h5 className="mt-4 text-black font-bold text-sm text-center">{colour}</h5>
                    </div>

                    <div className="single-project-owner-details col-span-12 p-2 md:col-span-6">
                    <h4 className="text-black font-bold text-md">Owner Description</h4>
                    <p className="mt-2 text-black text-sm">{description}</p>
                    </div>
                    <div className="single-project-car-details col-span-12 p-2 md:col-span-6">
                    <h4 className="text-black font-bold text-md">Car Details</h4>
                    <p className="mt-2 text-black text-sm">Model: {model}<br/>
                        Make: {make}<br/>
                        Colour: {colour}<br/>
                        Year: {year}<br/>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SingleProject