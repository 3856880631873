import { UserActions, UserActionType } from '../actions/userActions';

interface UserData {
    _id: string
    email: string
    userAccountType: string
    firstName: string
    lastName: string
    fullName: string
    username: string
    account_status: string
    completedInitialProfileSetup: boolean
    profilePicture: string
}

type UserStateDictionary = {
  userData: {
    [key in keyof UserData]?: UserData[key];
  };
};

const initialState: UserStateDictionary = {
    userData: {
        _id: '',
        email: '',
        userAccountType: '',
        firstName: '',
        lastName: '',
        fullName: '',
        username: '',
        account_status: '',
        completedInitialProfileSetup: false,
        profilePicture: ''
    }
}

const userReducer = (state: UserStateDictionary = initialState, action: UserActions) => {
    switch(action.type) {
        case UserActionType.SET_NAME:
            return {
                ...state,
                name: action.payload,
            }
        case UserActionType.SET_PROFILE_PHOTO:
            return {
                ...state,
                profilePhoto: action.payload,
            }
        case UserActionType.SET_USERDATA:
            let makeFullName = `${action.payload.firstName.trim()} ${action.payload.lastName.trim()}`
            return {
                ...state,
                userData: {
                    ...state.userData,
                    _id: action.payload._id,
                    email: action.payload.email,
                    userAccountType:action.payload.userAccountType,
                    firstName:action.payload.firstName,
                    lastName:action.payload.lastName,
                    fullName: makeFullName.trim(),
                    username:action.payload.username,
                    account_status:action.payload.account_status,
                    completedInitialProfileSetup: action.payload.completedInitialProfileSetup,
                    profilePicture: action.payload.profilePicture
                }
            }
        default:
            return state;
    }
}
export default userReducer;
