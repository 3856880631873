import { useState, useEffect, Dispatch } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { gapi, loadAuth2 } from 'gapi-script';
import { RootState } from '@/store/reducers/rootReducer';
import { BaseActionType, BaseActions } from '@/store/actions/baseActions';
import { ScrollingContent } from '@/utils/ScrollingContent';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';

const GOOGLE_AUTH = gql`
  mutation Mutation($input: AuthInput!) {
    authGoogle(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

const CREATE_USER_MUTATION = gql`
  mutation Mutation($input: NewUser!) {
    createUser(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

const Login = () => {
  const navigate = useNavigate();
  const { accessTokenName, refreshTokenName } = useSelector(
    (state: RootState) => state.base.baseToken,
  );
  const { googleLoginClientId } = useSelector((state: RootState) => state.base.socialAuth);
  const baseDispatch = useDispatch<Dispatch<BaseActions>>();
  const [googleAuth, { data, loading, error }] = useMutation(GOOGLE_AUTH);
  const [createUserMutation, { loading: addUserLoading }] = useMutation(CREATE_USER_MUTATION);
  const { register, handleSubmit, formState: { errors }} = useForm();

  const [formError, setFormError] = useState('');

  useEffect(() => {
    handleGoogleAuth2();
  },[]);

  const handleGoogleAuth2 = async () => {
    const auth2 = await loadAuth2(gapi, process.env.REACT_APP_GG_APP_ID ?? '', '');
    attachSignin(document.getElementById('google-sign-in'), auth2);
  }

  const attachSignin = (element: any, auth2: any) => {
    auth2.attachClickHandler(element, {}, (googleUser: any) => {
      handleGoogleLogin(googleUser);
      }, (error: any) => {
      console.log(JSON.stringify(error))
    });
  };

  const handleSocialLogin = async (provider: string, data: any) => {
    switch (provider) {
      case 'google':
        const auth2 = await loadAuth2(gapi, process.env.REACT_APP_GG_APP_ID ?? '', '');
        attachSignin(document.getElementById('google-sign-in'), auth2);
        break;
      default:
    }
  };

  const handleGoogleLogin = async (data: any) => {
    if (data) {
      try {
        const authData = await googleAuth({
          variables: { input: { accessToken: data.xc.id_token } },
        });
        if (authData?.data.authGoogle) {
          const { accessToken, refreshToken } = authData.data.authGoogle;
          signInLogic(accessToken, refreshToken);
        }
      } catch (err: any) {
        setFormError(err.message);
      }
    }
  }

  const handleSignup = async (inputs: any) => {
    setFormError('');
    const { firstName, lastName, email, password } = inputs;

    try {
      const { data } = await createUserMutation({
        variables: {
          input: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
          },
        },
      });

      if (data?.createUser) {
        const { accessToken, refreshToken } = data.createUser;
        signInLogic(accessToken, refreshToken);
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  const signInLogic = (access: string, refresh: string) => {
    localStorage.setItem(accessTokenName, access);
    localStorage.setItem(refreshTokenName, refresh);
    baseDispatch({
      type: BaseActionType.SET_ROOT_STATE,
      payload: {
        isAppReady: true,
        isUserAuthenticated: true,
      },
    });
    navigate('/');
  };

  return (
    <div className='min-h-screen flex dark:bg-slate-900 bg-white'>
      <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
        <div className='mx-auto w-full max-w-sm lg:w-128'>
          <div>
            <svg
              id='Component_33_2'
              data-name='Component 33 – 2'
              xmlns='http://www.w3.org/2000/svg'
              width='262.947'
              height='54.602'
              viewBox='0 0 262.947 54.602'
            >
              <g id='Group_68' data-name='Group 68' transform='translate(-1861.027 -1309.473)'>
                <path
                  id='Path_46'
                  data-name='Path 46'
                  d='M1910.715,1364.073l-.078-32.762-16.068,26.99h-5.7l-15.99-26.287v32.059h-11.855v-54.6h10.451l20.437,33.93,20.125-33.93h10.373l.157,54.6Z'
                  fill='#fff'
                />
                <path
                  id='Path_47'
                  data-name='Path 47'
                  d='M1980.133,1309.472v54.6H1967.5v-22.387h-24.806v22.387h-12.635v-54.6h12.635V1331H1967.5v-21.528Z'
                  fill='#fff'
                />
                <path
                  id='Path_48'
                  data-name='Path 48'
                  d='M2034.967,1329.284c0,12.168-9.127,19.734-23.713,19.734h-11v15.055h-12.637v-54.6h23.635C2025.84,1309.472,2034.967,1317.037,2034.967,1329.284Zm-12.793,0c0-6.007-3.9-9.517-11.621-9.517h-10.3v18.956h10.3C2018.276,1338.723,2022.174,1335.212,2022.174,1329.284Z'
                  fill='#fff'
                />
              </g>
              <path
                id='Path_49'
                data-name='Path 49'
                d='M2121,1320.2a46.209,46.209,0,0,0-5.71-10.73H2105.2c.33.329.64.669.95,1.009a5.773,5.773,0,0,1-1.86,9.071l-15.85,7.46a5.27,5.27,0,0,1-5.031-.36,11.691,11.691,0,0,0-17.29,6.29,5.253,5.253,0,0,1-3.62,3.51l-16.94,4.48a5.781,5.781,0,0,1-7.26-5.76,39.05,39.05,0,0,1,10.949-25.7h-10.09a46.7,46.7,0,0,0,.3,54.6h10.191a38.541,38.541,0,0,1-2.76-3.08,5.774,5.774,0,0,1,3.04-9.19l15.38-4.06a5.743,5.743,0,0,1,6.23,2.33l5.03,7.45,3.7,6.55h13.52a5.931,5.931,0,0,1-.75-2l-1.831-9.87-.939-8.951a5.741,5.741,0,0,1,3.27-5.78l14.391-6.78a5.794,5.794,0,0,1,7.239,1.96,5.688,5.688,0,0,1,1,3.12,38.968,38.968,0,0,1-11.369,28.3H2115A46.654,46.654,0,0,0,2121,1320.2Zm-41.11,23.681a7.786,7.786,0,1,1,4.66-9.981A7.788,7.788,0,0,1,2079.893,1343.883Z'
                transform='translate(-1861.027 -1309.473)'
                fill='#f2133c'
              />
            </svg>
            <h2 className='mt-6 text-3xl font-extrabold text-gray-900 dark:text-white'>
              Create your account
            </h2>
            <p className='mt-2 text-sm text-gray-600'>
              Or{' '}
              <Link to='/login' className='font-medium text-red hover:text-red'>
                sign in if you already have an account.
              </Link>
            </p>
          </div>

          <div className='mt-8'>
            <div>
              {formError && (
                <div className='rounded-md bg-red50 p-4 mb-5'>
                  <div className='flex'>
                    <div className='flex-shrink-0'>
                      <svg
                        className='h-5 w-5 text-red'
                        xmlns='http:www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        aria-hidden='true'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </div>
                    <div className='ml-3'>
                      <h3 className='text-sm font-medium text-red'>{formError}</h3>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <p className='text-sm font-medium text-gray-200  font-semibold'>Sign up with</p>
                <div className='mt-1 grid grid-cols-2 gap-3'>
                  <div>
                    <button onClick={() => handleSocialLogin('google', '')} id="google-sign-in" className='w-full inline-flex justify-center py-3 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='inline-block mt-1 w-4 h-4 mr-3 text-gray-900 fill-current'
                        viewBox='0 0 48 48'
                        width='48px'
                        height='48px'
                      >
                        <path
                          fill='#fbc02d'
                          d='M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z'
                        ></path>
                        <path
                          fill='#e53935'
                          d='M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z'
                        ></path>
                        <path
                          fill='#4caf50'
                          d='M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z'
                        ></path>
                        <path
                          fill='#1565c0'
                          d='M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z'
                        ></path>
                      </svg>
                      Google
                    </button>
                  </div>
                </div>
              </div>
              <div className='mt-6 relative'>
                <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                  <div className='w-full border-t border-gray-300' />
                </div>
                <div className='relative flex justify-center text-sm'>
                  <span className='px-2 bg-slate-900 rounded-lg text-gray-500 dark:text-gray-200  font-semibold'>
                    Or continue with
                  </span>
                </div>
              </div>
            </div>
            <div className='mt-6'>
            <form onSubmit={handleSubmit(handleSignup)} className='space-y-6'>
                <div className='grid grid-cols-6 gap-6'>
                  <div className='col-span-6 sm:col-span-3'>
                    <label
                      htmlFor='firstName'
                      className='block text-sm font-medium text-gray-700 dark:text-gray-200  font-semibold'
                    >
                      First name
                    </label>
                    <input
                      type='text'
                      {...register('firstName', {
                        required: 'required',
                        minLength: {
                          value: 1,
                          message: 'Minimum 2 character for firstname.',
                        },
                      })}
                      name='firstName'
                      id='firstName'
                      className='text-black mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    />
                    {errors.firstName && (
                      <span role='alert' className='text-xs'>
                        {errors.firstName.message}
                      </span>
                    )}
                  </div>
                  <div className='col-span-6 sm:col-span-3'>
                    <label
                      htmlFor='lastName'
                      className='block text-sm font-medium text-gray-700 dark:text-gray-200  font-semibold'
                    >
                      Last name
                    </label>
                    <input
                      type='text'
                      {...register('lastName', {
                        required: 'required',
                        minLength: {
                          value: 1,
                          message: 'Minimum 1 character for lastname.',
                        },
                      })}
                      name='lastName'
                      id='lastName'
                      className='text-black mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    />
                    {errors.lastName && (
                      <span role='alert' className='text-xs'>
                        {errors.lastName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor='email'
                    className='block text-sm font-medium text-gray-700 dark:text-gray-200  font-semibold'
                  >
                    Email address
                  </label>
                  <div className='mt-1'>
                    <div className='mt-1'>
                      <input
                        id='email'
                        {...register('email', {
                          required: 'required',
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Email is not valid',
                          },
                        })}
                        name='email'
                        type='email'
                        autoComplete='email'
                        required
                        className='text-black appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      />
                      {errors.email && (
                        <span role='alert' className='text-xs'>
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className='space-y-1'>
                  <label
                    htmlFor='password'
                    className='block text-sm font-medium text-gray-700 dark:text-gray-200  font-semibold'
                  >
                    Password
                  </label>
                  <div className='mt-1'>
                    <div className='mt-1'>
                      <input
                        id='password'
                        {...register('password', {
                          required: 'required'
                        })}
                        name='password'
                        type='password'
                        autoComplete='current-password'
                        required
                        className='text-black appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      />
                      {errors.password && (
                        <span role='alert' className='text-xs'>
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className='grid justify-items-end'>
                  <div className='text-sm'>
                    <Link to='/forgot' className='font-medium text-red hover:text-red'>
                      Forgot your password?
                    </Link>
                  </div>
                </div>
                <div className='!mt-1'>
                {addUserLoading ? (
                  <LoadingSpinner />
                ) : (
                  <button
                    type='submit'
                    className='w-full flex justify-center py-2 font-bold text-lg px-4 border border-transparent rounded-md shadow-sm text-sm text-white bg-red hover:bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red'
                  >
                    Register
                  </button>
                )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden lg:block relative w-full'>
        <ScrollingContent />
      </div>
    </div>
  );
};

export default Login;
