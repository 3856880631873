import { Link, useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { isFunction } from 'util';

interface ProductData {
  userId: string;
  productId: string;
}

export const GET_PRODUCT_BY_ID = gql`
  query GetProductById($productId: ID) {
    getProductById(productId: $productId) {
      product {
        id
        createdBy
        title
        price
        comparePrice
        bodyContent
        productImageURL
        vendor
        type
        categories
        tags
      }
      firstName
      lastName
      username
      profilePicture
    }
  }
`;

const SingleProduct = () => {
  const navigate = useNavigate();
  const { productSlug } = useParams();

  const {
    error,
    loading,
    data: productData,
  } = useQuery(GET_PRODUCT_BY_ID, {
    variables: {
      productId: productSlug,
    },
  });

  const profileLink = productData?.getProductById?.username;

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className='relative gap-2 grid grid-cols-12'>
          <div className='col-span-12 lg:col-span-8 lg:pr-3 relative'>
            <div className='col-span-12 text-white text-3xl mt-2 font-bold'>
              {productData?.getProductById?.product?.title}
            </div>
            <div className='grid grid-flow-col col-span-12 border-red border-y border-t-0 pb-4 items-center my-2'>
              <div className='left grid grid-flow-row grid-flow-col col-span-1 col-span-2 justify-start items-center'>
                <div className='col-span-3 grid'>
                  <Link
                    to={
                      productData?.getProductById?.username
                        ? `/profile/${productData.getProductById.username}`
                        : ''
                    }
                  >
                    <img
                      referrerPolicy='no-referrer'
                      className='author-profile-image rounded-full'
                      width='48px'
                      height='48px'
                      src={
                        productData?.getProductById?.profilePicture
                          ? productData.getProductById.profilePicture
                          : ''
                      }
                    />
                  </Link>
                </div>
                <div className='col-span-1 lg:col-span-12 ml-4'>
                  <div className='mt-1 text-md text-white font-bold'>
                    {productData?.getProductById?.firstName} {productData?.getProductById?.lastName}
                  </div>
                  {/* <div className="mt-1 text-xs text-white">
                        From ${price}*
                    </div> */}
                </div>
              </div>
              <div className='right col-span-1 lg:col-span-2 mt-4 mb-2 lg:my-0 text-right'>
                <Link to={`/profile/${profileLink}`}>
                  <button className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'>
                    Contact Seller
                  </button>
                </Link>
                <button
                  className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </button>
              </div>
            </div>

            <div className='grid grid-cols-12 mt-3 mb-2 gap-6'>
              <div className='col-span-9 text-white text-sm'>
                {productData?.getProductById?.product?.bodyContent}
              </div>
              <div className='col-span-3 text-white text-sm'>
                {productData?.getProductById?.product?.vendor && (
                  <div className='mb-1'>
                    <span className='font-bold'>Vendor: </span>
                    {productData.getProductById.product.vendor}
                  </div>
                )}
                {productData?.getProductById?.product?.type && (
                  <div className='mb-1'>
                    <span className='font-bold'>Type: </span>
                    {productData.getProductById.product.type}
                  </div>
                )}
                {productData?.getProductById?.product?.categories.length > 0 && (
                  <div className='mb-1'>
                    <span className='font-bold'>Categories: </span>
                    <ul>
                      {productData?.getProductById?.product?.categories.map(
                        (category: any, index: any) => (
                          <div
                            className='bg-indigo-100 inline-flex items-center text-xs rounded mt-2 mr-1 p-0.5 text-black'
                            key={index}
                          >
                            <span
                              className='ml-2 mr-1 leading-relaxed truncate max-w-xs'
                              key={index}
                            >
                              {category}
                            </span>
                          </div>
                        ),
                      )}
                    </ul>
                  </div>
                )}
                {productData?.getProductById?.product?.tags.length > 0 && (
                  <div className='mb-1'>
                    <span className='font-bold'>Tags: </span>
                    <ul>
                      {productData?.getProductById?.product?.tags.map((tag: any, index: any) => (
                        <div
                          className='bg-indigo-100 inline-flex items-center text-xs rounded mt-2 mr-1 p-0.5 text-black'
                          key={index}
                        >
                          <span className='ml-2 mr-1 leading-relaxed truncate max-w-xs' key={index}>
                            {tag}
                          </span>
                        </div>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {productData?.getProductById?.product?.productImageURL ? (
              <img className="mt-10 mb-10" src={productData.getProductById.product.productImageURL} />
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SingleProduct;
