import { useEffect, useMemo, useRef, useState } from 'react'
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client"
import { useForm } from "react-hook-form"
import { Link } from 'react-router-dom'

import ConfirmPopup from '@/components/ConfirmPopup'
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import QueryError from '@/utils/QueryError';

const GET_CHAT_INFO = gql`
  query ChatInfoQuery($chatId: ID) {
    getUserChatInfo(chatId: $chatId) {
      chatSlug
      chatName
      users
      isChatEnquiry
      advertiserResponsed
      createdAt
      enquiryMessage {
        topic
        location
        phone
        description
      }
    }
  }
`;

const GET_CHAT_MESSAGES = gql`
  query GetChatMessages($chatId: ID) {
    getChatMessages(chatId: $chatId) {
      createdAt
      readerStatus
      senderStatus
      chatId
      messageContent
      senderId
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation CreateUserChatMutation($input: MessageInput) {
    createChatMessage(input: $input) {
      createdAt
      readerStatus
      senderStatus
      chatId
      messageContent
      senderId
    }
  }
`;

const MESSAGE_SENT_SUB = gql`
  subscription MessageSentSubscription {
    messagePosted {
      createdAt
      readerStatus
      senderStatus
      chatId
      messageContent
      senderId
    }
  }
`;

const USER_TYPING_SUB = gql`
	subscription UserIsTyping {
		userTyping {
      fullName
      chatId
    }
	}
`;

const SET_USER_TYPING = gql`
  mutation UserTyping($input: UserTypingData) {
    userTyping(input: $input) {
      fullName
      chatId
    }
  }
`;

const CurrentChat = ({ chatId, mobileChatActive }: any) => {
  const bottomRef = useRef<any>(null);
  const [typedMessage, setTypedMessage] = useState<string>('');
  const [messageTyper, setMessageTyper] = useState<string>();
  const [currentMessages, setCurrentMessages] = useState<any>([]);
  const [ deleteMessage, setDeleteMessage ] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset, control } = useForm();
  const { userData } = useSelector((state: RootState) => state.user);
  const { data: newChatReceived } = useSubscription(MESSAGE_SENT_SUB);
  const { data: userIsTyping } = useSubscription(USER_TYPING_SUB);
  const [userTyping] = useMutation(SET_USER_TYPING);
  const { data: userChats, loading, error } = useQuery(GET_CHAT_MESSAGES, {
    variables: { chatId },
    onCompleted(data) {
      console.log('ran', data)
      setCurrentMessages(data.getChatMessages)
      // console.log(currentMessages)
    },
  })
  const { data: chatInfo } = useQuery(GET_CHAT_INFO, {
    variables: { chatId },
  })
  const [sendMessage, { data: newMessageData }] = useMutation(SEND_MESSAGE, {
    refetchQueries: [GET_CHAT_MESSAGES],
  });

  const handleAddMessage = async (inputs: any) => {
    const { message } = inputs;
    reset()
    setTypedMessage('');
    const { data } = await sendMessage({
      variables: {
        input: {
          chatId,
          content: message,
          userId: userData._id,
        }
      },
    })

    // if(data?.createChatMessage) {
    //   setCurrentMessages((currentMessages: any) => currentMessages.concat(data.createChatMessage))
    // }
  }

  useEffect(() => {
    if(bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
    }
  }, [currentMessages, userIsTyping]);

  useEffect(() => {
    if(userIsTyping?.userTyping && userIsTyping?.userTyping.chatId === chatId) {
      setMessageTyper(userIsTyping?.userTyping.fullName);
    }
		const timer = setTimeout(() => {
			setMessageTyper(undefined);
		}, 1000);
		return () => clearTimeout(timer);
	}, [currentMessages, userIsTyping]);

  useMemo(() => {
    if(newChatReceived?.messagePosted) {
      setCurrentMessages((currentMessages: any) => currentMessages.concat(newChatReceived?.messagePosted))
    }
  }, [newChatReceived]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTyping = async(data: React.KeyboardEvent<HTMLInputElement>) => {
    // console.log(data)
    setTypedMessage(data?.currentTarget.value)
    try {
      await userTyping({ 
        variables: {
          input: {
            fullName: userData.fullName,
            chatId
          }
        }
      });
    } catch(err: any) {
      console.log(err)
    }
  }

  if (loading) return <LoadingSpinner />;
  if (error) return <QueryError />;

  console.log('Chat info: ', chatInfo)

  // if(deleteMessage) {
  //   return <ConfirmPopup heading="Deactivate account" content="Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone." />
  // }

  return (
    <div className={'flex flex-col col-span-12 pb-8 lg:pb-0 lg:col-span-8 px-4 pl-0 lg:pl-12 pr-0 pt-0 ' + ((mobileChatActive === true) ? 'order-none lg:order-1' : '')} style={{height: "calc(100vh - 200px)"}}>
      <div className="flex flex-row items-center justify-between py-4 px-6 bg-darkgrey2 rounded-2xl shadow">
        <div className='flex flex-row'>
          <div className="flex items-center justify-center h-10 w-10 rounded-full bg-pink-500 text-pink-100 capitalize">{ chatInfo?.getUserChatInfo.chatName.replace(userData.fullName, '').trim().replace(',', '').charAt(0)}</div>
          <div className="flex flex-col ml-3">
            <div className="font-semibold text-sm capitalize">{((chatInfo?.getUserChatInfo.chatName.match(/,/g) || []).length >= 2) ? chatInfo?.getUserChatInfo.chatName : chatInfo?.getUserChatInfo.chatName.replace(userData.fullName, '').trim().replace(',', '') }</div>
            <div className="text-xs text-gray-500">Message</div>
          </div>
        </div>
        <div className='block lg:hidden'>
          <Link to="/messages" className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'>
            Close Chat
          </Link>
        </div>
        {/* <div className="ml-auto">
          <ul className="flex flex-row items-center space-x-2">
            <li>
              <button onClick={() => setDeleteMessage(!deleteMessage)}
                className="flex items-center justify-center bg-red hover:bg-darkgrey text-white h-10 w-10 rounded-full transition ease-in-out duration-300">
                <span>
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z"/></svg>
                </span>
              </button>
            </li>
          </ul>
        </div> */}
      </div>
      <div className="h-full overflow-hidden py-4 text-black">
        <div className="h-full overflow-y-auto" ref={bottomRef}>
          <div className="grid grid-cols-12 gap-y-2">
            { chatInfo?.getUserChatInfo.isChatEnquiry && <div className="col-start-1 col-end-8 p-3 rounded-lg">
              <div className="flex flex-row">
                <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0 text-white">En</div>
                <div className="relative ml-3">
                  <div className="text-sm bg-white py-2 px-4 shadow rounded-xl">
                    <div className="font-bold">Enquiry details</div>
                    <div>Topic: {chatInfo?.getUserChatInfo.enquiryMessage.topic}</div>
                    <div>Location: {chatInfo?.getUserChatInfo.enquiryMessage.location}</div>
                    <div>Phone: {chatInfo?.getUserChatInfo.enquiryMessage.phone}</div>
                    <div>Description: {chatInfo?.getUserChatInfo.enquiryMessage.description}</div>
                  </div>
                  <div className="text-xs text-gray-400 ml-1 mt-1">{ new Date(chatInfo?.getUserChatInfo.createdAt).toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })} </div>
                </div>
              </div>
            </div> }
            { (userChats && currentMessages) && currentMessages.map((chat: any, id: any) => {
              let time = new Date(chat.createdAt);
              let convertedTime = time.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });
              if (chat.senderId === userData._id) {
                return (
                  <div className="col-start-1 col-end-8 p-3 rounded-lg" key={id}>
                    <div className="flex flex-row">
                      <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0 text-white">Sn</div>
                      <div className="relative ml-3">
                        <div className="text-sm bg-white py-2 px-4 shadow rounded-xl">{chat.messageContent}</div>
                        <div className="text-xs text-gray-400 ml-1 mt-1">{convertedTime}</div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="col-start-6 col-end-13 p-3 rounded-lg" key={id}>
                    <div className="flex justify-start flex-row-reverse">
                      <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0 text-white">Re</div>
                      <div className="relative ml-3">
                        <div className="mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">{chat.messageContent}</div>
                        <div className="text-xs text-gray-400 ml-1 mt-1">{convertedTime}</div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          {(messageTyper && messageTyper !== userData.fullName) && <div className="text-white text-xs mt-2">{messageTyper} is typing...</div>}
          {/* {(messageTyper && messageTyper !== userData.fullName) && <div className="text-white text-xs mt-2">{messageTyper} is typing...</div>} */}
        </div>
      </div>
      <form className="flex flex-row items-center" autoComplete="off" onSubmit={handleSubmit(handleAddMessage)}>
        <div className="flex flex-row items-center w-full border rounded-3xl h-12 px-2">
          <div className="w-full mx-4">
            <input
            {...register("message", {
              validate: (value) => { return !!value.trim()},
              onChange: (e: React.KeyboardEvent<HTMLInputElement>) => onTyping(e),
              minLength: 1,
            })}
            value={typedMessage}
            type="text"
            className="text-white border border-transparent w-full focus:border-none text-sm h-10 flex items-center bg-transparent" placeholder="Type your message...." />
          </div>
          {/* <div className="flex flex-row">
            <button className="flex items-center justify-center h-10 w-8 text-gray-400" type="submit">
              <svg className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path>
              </svg>
            </button>
          </div> */}
        </div>
        <div className="ml-6">
          <button className="flex items-center justify-center h-10 w-10 rounded-full bg-red hover:bg-darkgrey transition ease-in-out duration-300 text-white" type="submit">
            <svg className="w-5 h-5 transform rotate-90 -mr-px"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
}

export default CurrentChat;