import { Link } from 'react-router-dom';
import TestImage from '@/assets/images/no_image_found.png';

import { useQuery, useMutation, gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import ConfirmPopup from '@/components/ConfirmPopup';
import { PopupModalActionTypes } from '@/utils/types';

const GET_PROJECTS = gql`
  query Query($getProjectsUserId: ID) {
    getProjects(userId: $getProjectsUserId) {
      id
      projectName
      year
      make
      model
      description
      colour
      projectCoverImage
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation DeleteProjectMutation($deleteProjectProjectId: ID) {
    deleteProject(projectId: $deleteProjectProjectId)
  }
`;



function MyProjects() {
  const [deleteProject] = useMutation(DELETE_PROJECT, {
    refetchQueries: [GET_PROJECTS, 'Query'],
  });
  const { loading, data, refetch } = useQuery(GET_PROJECTS);
  const [popupActive, setPopupActive] = useState(false);

  useEffect(() => {
    // TODO - Resolve to a better solution, this is for the navigation back from creating a new project.
    // Query was not working correctly by firing off a new fetch.
    refetch();
  });

  const handleDeleteProject = (e:any, id:any) => {
    e.preventDefault();
    setPopupActive(true);
  }

  const handlePopupStatusChange = (data:any, id: any) => {
    if(data) {
      deleteProject({
        variables: {
          deleteProjectProjectId: id,
        },
      });
      setPopupActive(false);
    } else {
      setPopupActive(false);
    }
  }

  return (
    <>
      {loading ? 
        <LoadingSpinner />
      : 
      <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-span-8 pr-3 relative">
          <div className='flex flex-row text-white font-semibold items-center text-xl justify-between'>
            <h1>Projects</h1>
            <Link
              className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
              to='/projects/add'
            >
              Add Project
            </Link>
          </div>
          {data
            ? data.getProjects.map((project: any) => {
                return (
                  <div key={project.id} className='grid grid-cols-12 h-auto mt-8 relative'>
                    <div
                      className='project-featured-image h-64 md:h-full bg-red col-span-12 md:col-span-6 w-auto left-0 bg-cover lg:bg-center rounded-b-none rounded-t-lg md:w-full md:rounded-l-lg md:rounded-r-none'
                      style={{
                        backgroundImage: `url(${
                          project?.projectCoverImage != '' ? project.projectCoverImage : TestImage
                        })`,
                      }}
                    />
                    <div className='col-span-12 md:col-span-6 right-0 bg-white rounded-t-none rounded-b-lg p-8 md:p-16 md:w-auto md:rounded-l-none md:rounded-r-lg'>
                      <h3 className='myprojects-title mb-4 text-black text-xl text-center font-semibold'>
                        {project.projectName}
                      </h3>
                      <div className='grid grid-cols-12 mt-12'>
                        <Link
                          className='col-span-12'
                          to={`/project/${project.id}`}
                          state={{
                            projectId: project.id,
                            projectName: project.projectName,
                            projectCoverImage: project.projectCoverImage,
                            year: project.year,
                            make: project.make,
                            model: project.model,
                            colour: project.colour,
                            description: project.description,
                          }}
                        >
                          <button className='view-project-btn w-full text-center my-2 bg-red px-1 py-2 rounded-lg font-semibold text-lg uppercase text-white transition ease-in-out duration-300 bg-red hover:bg-darkgrey'>
                            View
                          </button>
                        </Link>
                        <button
                          className='delete-project-btn col-span-12 my-2 bg-red px-1 py-2 rounded-lg font-semibold text-lg uppercase text-white text-center transition ease-in-out duration-300 bg-red hover:bg-darkgrey'
                          onClick={(e) => handleDeleteProject(e, project.id)}>
                          Delete
                        </button>
                        { popupActive && <ConfirmPopup heading="Delete Project" content="Are you sure you want to delete this project? This action cannot be undone." actionType={PopupModalActionTypes.DELETE} onPopupStatusChange={(data: any) => handlePopupStatusChange(data, project.id)} />}
                      </div>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
      }
    </>
  );
}

export default MyProjects;
