import { useState } from 'react'
import { Link } from 'react-router-dom';

import RecommendedProducts from '@/components/usercomponents/RecommendedProducts'
import UpcomingEvents from '@/components/usercomponents/UpcomingEvents'
import Activity from '@/components/usercomponents/Activity'
import MyArticles from '@/components/usercomponents/MyArticles'
import CommunityProjects from '@/components/usercomponents/CommunityProjects'

const Homepage = () => {
  const [tags, setTags] = useState([]);
  const handleFileInputChange = (e: any, i: any) => {

  }

  const formTagToggleSelect = (e: any) => {

  }

  const Logout = () => {

  }
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 2xl:col-span-8 xl:pr-3">
        <Activity />
      </div>
    </div>
  );
};

export default Homepage;