import { Utilities, getHTMLFromDraftState } from '@/utils/utilities';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ArticleData } from '../../SingleArticle';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';

export const ArticleTemplateTwoDisplay = ({
  userData,
  articleData,
}: {
  articleData: ArticleData;
  userData: any;
}) => {
  const { heading, subHeading, bodyContentList, articleImageList, createdAt } =
    articleData as ArticleData;
  const [lightBoxOpen, setLightBoxOpen] = useState<boolean>(false);
  const [galleryIndex, setGalleryIndex] = useState<number>(0);

  // TODO - understand why the returned array of bodyContentList/articleImageList comes in as a tuple?
  const bodyContentSections = Array.from(bodyContentList);
  const articleImages = Array.from(articleImageList);

  const navigate = useNavigate();

  return (
    <>
      <div className='relative gap-2 grid grid-cols-12 pb-12'>
        <div className='col-span-12 lg:col-span-8 lg:pr-3 relative'>
          <div className='grid grid-flow-col col-span-12 border-red border-y border-t-0 pb-4 items-center my-2'>
            <div className='left grid grid-flow-row grid-flow-col col-span-1 col-span-2 justify-start items-center'>
              <Link to='/profile'>
                <div className='col-span-3 grid'>
                  <img
                    className='author-profile-image rounded-full'
                    width='48px'
                    height='48px'
                    src={userData.profilePicture ? userData.profilePicture : ''}
                  />
                </div>
                <div className='col-span-1 lg:col-span-12 ml-4'>
                  <div className='mt-1 text-md text-white font-bold'>
                    {userData.firstName} {userData.lastName}
                  </div>
                  <div className='mt-1 text-xs text-white'>
                    {new Date(createdAt)?.toLocaleDateString()}
                  </div>
                </div>
              </Link>
            </div>
            <div className='right col-span-1 lg:col-span-2 mt-4 mb-2 lg:my-0 text-right'>
              <button
                className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </div>
          <div className='flex'>
            <div className='flex-col flex pt-4 w-full'>
              <div className='text-white text-3xl mt-2 font-bold pb-2'>
                {Utilities.capitalizeFirstLetter(heading)}
              </div>
              <div className='grid grid-flow-row mt-3 mb-2'>
                <h3 className='col-span-12 mb-2 font-bold text-md'>
                  {Utilities.capitalizeFirstLetter(subHeading)}
                </h3>
              </div>
              <div>
                {' '}
                {articleImages ? (
                  <>
                    <div
                      onClick={() => {
                        setLightBoxOpen(true);
                        setGalleryIndex(0);
                      }}
                      className='my-2 w-full rounded-lg h-96 mr-4 bg-cover bg-center cursor-pointer'
                      style={{ backgroundImage: `url(${articleImages[0]})` }}
                    />
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className='col-span-12 text-white text-sm break-all mt-4'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getHTMLFromDraftState(bodyContentSections?.[0]),
                  }}
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col w-full'>
            {articleImages ? (
              <div
                onClick={() => {
                  setLightBoxOpen(true);
                  setGalleryIndex(1);
                }}
                className='col-span-6 my-2 rounded-lg h-80 w-full mr-4 bg-cover bg-center cursor-pointer'
                style={{ backgroundImage: `url(${articleImages[1]})` }}
              />
            ) : (
              ''
            )}
            <div className='col-span-12 text-white text-sm break-all py-2'>
              <div
                dangerouslySetInnerHTML={{
                  __html: getHTMLFromDraftState(bodyContentSections?.[1]),
                }}
              />
            </div>
            {articleImages ? (
              <div
                onClick={() => {
                  setLightBoxOpen(true);
                  setGalleryIndex(2);
                }}
                className='col-span-6 my-2 rounded-lg h-80 w-full mr-4 bg-cover bg-center cursor-pointer'
                style={{ backgroundImage: `url(${articleImages[2]})` }}
              />
            ) : (
              ''
            )}
            <div className='col-span-12 text-white text-sm break-all py-2'>
              <div
                dangerouslySetInnerHTML={{
                  __html: getHTMLFromDraftState(bodyContentSections?.[2]),
                }}
              />
            </div>
          </div>
          {lightBoxOpen ? (
            <Lightbox
              startIndex={galleryIndex}
              onClose={() => {
                setLightBoxOpen(false);
              }}
              images={articleImages}
            ></Lightbox>
          ) : null}
        </div>
      </div>
    </>
  );
};
