import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { useQuery, gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Select, { components } from 'react-select';

import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { RootState } from '@/store/reducers/rootReducer';

const { Option } = components;

const IconOption = (props: any) => {
  return (
    <Option {...props}>
      <div className='flex cursor-pointer'>
        <img src={props.data.profileImage} className='h-6 w-6 rounded-full mr-2' />
        {props.data.label}
      </div>
    </Option>
  );
};

const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers(escape: true) {
      _id
      username
      firstName
      lastName
      email
      profilePicture
    }
  }
`;

const CREATE_NEW_CHAT = gql`
  mutation createChatRoomMutation($input: ChatCreationInput) {
    createChatRoom(input: $input)
  }
`;

const NewMessagePopup = ({ userId, popupActiveState }: any) => {
  const [open, setOpen] = useState(true);
  const [messageSent, setMessageSent] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    control,
  } = useForm({ mode: 'onChange' });
  const [formError, setFormError] = useState('');
  const [createNewChat] = useMutation(CREATE_NEW_CHAT);
  const { userData } = useSelector((state: RootState) => state.user);
  const { loading: loading, data: allUsers } = useQuery(GET_ALL_USERS, {
    fetchPolicy: 'no-cache',
  });

  if (loading || allUsers === undefined) {
    return <LoadingSpinner />;
  }

  const { getAllUsers } = allUsers;

  const allUserOption = getAllUsers.map((user: any) => ({
    value: user._id,
    label: `${user.firstName} ${user.lastName}`,
    profileImage: user.profilePicture,
  }));

  const handleAddChat = async (inputs: any) => {
    setFormError('');
    const { message } = inputs;
    let chatusers = allUserOption.filter((option: any) => option.value === userId);

    let userIds: any = [];
    let chatName: string = '';
    chatusers &&
      Object.keys(chatusers).map((k: any) => {
        userIds.push(chatusers[k]['value']);
        chatName += ` ${chatusers[k]['label'].trim()},`;
      });
    try {
      userIds.push(userData._id);
      chatName += ` ${userData.fullName}`;
      const { data } = await createNewChat({
        variables: {
          input: {
            message,
            users: userIds,
            chatName: chatName.trim().replace(/,\s*$/, ''),
          },
        },
      });

      if (data?.createChatRoom) {
        setMessageSent(true);
        // navigate(`/messages/${data.createChatRoom}`)
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                {messageSent ? (
                  <>
                    <div>
                      <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
                        <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
                      </div>
                      <div className='mt-3 text-center sm:mt-5'>
                        <Dialog.Title
                          as='h3'
                          className='text-lg font-medium leading-6 text-gray-900'
                        >
                          Message Sent
                        </Dialog.Title>
                        <div className='mt-2'>
                          <p className='text-sm text-gray-500'>
                            We have sent a message to the user on your behalf.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='mt-5 sm:mt-6'>
                      <button
                        type='button'
                        className='inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm'
                        onClick={() => setOpen(false)}
                      >
                        Go back to dashboard
                      </button>
                    </div>
                  </>
                ) : (
                  <form onSubmit={handleSubmit(handleAddChat)} className='space-y-6 mt-5'>
                    <div className='shadow sm:rounded-md'>
                      <div className='px-4 py-5 bg-white space-y-6 sm:p-6 text-black'>
                        <div>
                          <label
                            htmlFor='heading'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Select user
                          </label>
                          <div className='mt-1'>
                            <Controller
                              control={control}
                              name='chatusers'
                              render={({ field: { onChange } }: any) => (
                                <Select
                                  options={allUserOption}
                                  name='chatusers'
                                  components={{
                                    Option: IconOption,
                                  }}
                                  isClearable
                                  isSearchable
                                  isMulti
                                  onChange={onChange}
                                  value={
                                    userId
                                      ? allUserOption.filter(
                                          (option: any) => option.value === userId,
                                        )
                                      : ''
                                  }
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='content'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Message
                          </label>
                          <div className='mt-1'>
                            <textarea
                              id='content'
                              {...register('message', {
                                required: 'Please type a message.',
                                minLength: {
                                  value: 1,
                                  message: 'Please type a message.',
                                },
                              })}
                              className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                              placeholder='Enter your message'
                              defaultValue={''}
                            />
                            {errors.message && (
                              <span role='alert' className='text-xs text-red'>
                                {errors.message.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
                        <button
                          type='submit'
                          className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl disabled:bg-gray-300 disabled:text-slate-500 disabled:cursor-not-allowed'
                          disabled={!isValid}
                        >
                          Send message
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewMessagePopup;
