import { BaseActions, BaseActionType } from "../actions/baseActions";

export type BaseToken = {
    accessTokenName: string;
    refreshTokenName: string;
    accessToken: string | null;
    refreshToken: string | null;
}

export type SocialAuth = {
    googleLoginClientId: string;
}

export type RootState = {
    isAppReady: boolean;
    isUserAuthenticated: boolean;
}

export type BaseState = {
    baseToken: BaseToken
    socialAuth: SocialAuth
    rootState: RootState
}

const initialState: BaseState = {
    baseToken: {
        accessTokenName: process.env.REACT_APP_ACCESS_TOKEN_NAME ?? '_hjwt__access_https___hi_performance',
        refreshTokenName: process.env.REACT_APP_REFRESH_TOKEN_NAME ?? '_hjwt__refresh_https___hi_performance',
        accessToken: process.env.REACT_APP_ACCESS_TOKEN_NAME ? localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME) : null,
        refreshToken: process.env.REACT_APP_REFRESH_TOKEN_NAME ? localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME) : null,
    },
    socialAuth: {
        googleLoginClientId: process.env.REACT_APP_GG_APP_ID ?? '',
    },
    rootState: {
        isAppReady: false,
        isUserAuthenticated: false,
    }
}

const baseReducer = (state: BaseState = initialState, action: BaseActions) => {
    switch(action.type) {
        case BaseActionType.SET_ACCESS_TOKEN:
            return {
                ...state,
                baseToken: {
                    ...state.baseToken,
                    accessToken: action.payload,
                }
            }
        case BaseActionType.SET_REFRESH_TOKEN:
            return {
                ...state,
                baseToken: {
                    ...state.baseToken,
                    refreshToken: action.payload,
                }
            }
        case BaseActionType.SET_ROOT_STATE:
            return {
                ...state,
                rootState: {
                    ...state.rootState,
                    isAppReady: action.payload.isAppReady,
                    isUserAuthenticated: action.payload.isUserAuthenticated,
                }
            }
        default:
            return state;
    }
}
export default baseReducer;