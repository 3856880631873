import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { UPLOAD_PHOTO } from '@/utils/mutations/mutations';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { CREATE_ARTICLE } from '../ArticleMutations';
import { ArticleTemplate } from '../AddArticle';
import TagSearch, { MHPSearchTagType } from '@/components/utils/TagSearch';
import WYSIWYGEditor from '@/components/utils/WYSIWYGEditor';
import { ImageUploadSection } from './small-components/ImageUploadSection';
import { disableKeyEnter } from '@/utils/utilities';

const ArticleTemplateOne = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [formError, setFormError] = useState('');
  const [addArticleMutation, { data: addArticleData, loading: addArticleLoading }] =
    useMutation(CREATE_ARTICLE);
  const [articleCoverImageError, setArticleCoverImageError] = useState(false);
  const [uploadPhoto, { data: uploadPhotoData, loading: uploadPhotoLoading }] =
    useMutation(UPLOAD_PHOTO);
  const [formTags, setFormTags] = useState<any[]>([]);
  const [bodyContent, setBodyContent] = useState<string>();
  const [articleImageError, setArticleImageError] = useState(false);
  const [articleImages, setArticleImages] = useState<any>(['']);

  const handleImageUpload = async (image: any) => {
    let uploadUrl = '';
    try {
      await uploadPhoto({
        variables: { uploadPhotoPhoto: image },
      }).then((data: any) => {
        setArticleImageError(false);
        uploadUrl = data?.data?.uploadPhoto;
      });
      return uploadUrl;
    } catch (err: any) {
      console.log('err: ', err);
    }
  };

  const handleAddArticle = async (inputs: any) => {
    if (articleImages[0] === '' || articleImages[0] === null) {
      setArticleCoverImageError(true);
      return;
    }
    // Upload all images - TODO - fix method to await all image uploads - forEach spread into the array?
    Promise.all([handleImageUpload(articleImages[0])])
      .then(async (results) => {
        let articlesImagesUploadUrls = [...results];
        await uploadArticle(inputs, articlesImagesUploadUrls);
      })
      .catch((err) => {
        console.log(err);
        setFormError(err.message);
      });
  };

  const uploadArticle = async (inputs: any, articlesImagesUrls: any) => {
    setFormError('');
    const { heading, subHeading } = inputs;
    try {
      const { data } = await addArticleMutation({
        variables: {
          input: {
            heading,
            subHeading,
            bodyContentList: [bodyContent],
            articleImageList: articlesImagesUrls,
            tags: formTags,
            articleTemplate: ArticleTemplate.TEMPLATE_ONE,
          },
        },
      });

      if (data) {
        navigate('/articles');
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  // Used to set image position in article - starting from 0 as the arr index.
  const handleImageData = (image: any, positionInArticle: number) => {
    let newArticleImages = [...articleImages];
    newArticleImages[positionInArticle] = image;
    setArticleImages(newArticleImages);
  };

  const handleWYSIWYGFormStateUpdate = (convertedWYSIWYGContent: string) => {
    setBodyContent(convertedWYSIWYGContent);
  };

  const handleFormTags = (newTags: any) => {
    setFormTags(newTags);
  };
  // Generate new templates to be rendered dynamically
  return (
    <form onSubmit={handleSubmit(handleAddArticle)} onKeyDown={(e) => disableKeyEnter(e)} className='space-y-6'>
      <div className='shadow sm:rounded-md sm:overflow-hidden'>
        <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
          <div>
            <label htmlFor='heading' className='block text-sm font-medium text-gray-700'>
              Heading
            </label>
            <div className='mt-1'>
              <input
                id='heading'
                {...register('heading', { required: 'Article name required' })}
                className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                placeholder='Enter the name of your new Article'
                defaultValue={''}
              />
              <span className='text-red'>{errors.heading && errors.heading.message}</span>
            </div>
          </div>
          <div>
            <label htmlFor='subheading' className='block text-sm font-medium text-gray-700'>
              Sub heading <span className='text-[8px]'>(optional)</span>
            </label>
            <div className='mt-1'>
              <input
                id='subheading'
                {...register('subHeading')}
                className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                placeholder='Enter the year of your vehicle'
                defaultValue={''}
              />
            </div>
          </div>

          <div>
            <div className='mt-1'>
              <div>
                <WYSIWYGEditor
                  formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
                  formLabel={'Content/Description'}
                />
              </div>
              <span className='text-red'>{errors.bodyContent && errors.bodyContent.message}</span>
            </div>
          </div>
          <div>
            <label className='block text-sm font-medium text-gray-700'>Search for Tags</label>
            <TagSearch tagType={MHPSearchTagType.Article} handleFormTags={handleFormTags} />
          </div>
          <div>
            <ImageUploadSection
              labelText={'Photo'}
              articleImages={articleImages}
              handleImageData={handleImageData}
              articleImageError={articleImageError}
              positionInArticle={0}
              smallPreview={true}
            />
            {articleCoverImageError ? (
              <span className='text-red'>Article cover image required</span>
            ) : null}
          </div>
        </div>
        <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
          {addArticleLoading ? (
            <LoadingSpinner />
          ) : (
            <button
              type='submit'
              className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
            >
              Create Article
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default ArticleTemplateOne;
