import CommunityProjects from './CommunityProjects';
import RecommendedProducts from './RecommendedProducts';
import { useQuery } from '@apollo/client';
import { GET_ALL_TAGS, GET_ALL_ARTICLES, GET_ALL_PROJECTS } from '../../utils/queries/queries';
import { Link } from 'react-router-dom';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { getHTMLFromDraftState } from '@/utils/utilities';

function Activity() {
  const {
    loading: articlesLoading,
    error: articlesError,
    data: articlesData,
  } = useQuery(GET_ALL_ARTICLES);

  const {
    loading: projectsLoading,
    error: projectsError,
    data: projectsData,
  } = useQuery(GET_ALL_PROJECTS);

  const { loading: tagsLoading, error: tagsError, data: tagsData } = useQuery(GET_ALL_TAGS);

  const projectsAndArticles = projectsData?.getAllProjects?.concat(articlesData?.getAllArticles);
  const shuffledProjectsAndArticles = projectsAndArticles?.sort(() => 0.5 - Math.random());

  return (
    <div>
      <RecommendedProducts />
      <h4 className='text-white font-semibold text-xl mt-10 mb-2'>Activity</h4>
      <div className='grid grid-cols-1'>
        {articlesLoading || tagsLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {shuffledProjectsAndArticles.slice(0, 10).map((item: any) => (
              <div className='rounded-lg overflow-hidden mb-10' key={item.id}>
                <div className='grid grid-cols-5'>
                  {item.__typename === 'Article' ? (
                    <div
                      className='border-blue col-span-5 bg-cover bg-center h-72'
                      style={{
                        backgroundImage: `url(${item.articleImageList[0]})`,
                      }}
                    ></div>
                  ) : (
                    <div
                      className='border-blue col-span-5 bg-cover bg-center h-72'
                      style={{
                        backgroundImage: `url(${item.projectCoverImage})`,
                      }}
                    ></div>
                  )}
                  <div className='text-center lg:text-left col-span-5 md:col-span-2 bg-white px-8 py-6'>
                    <h3 className='text-black text-lg font-bold uppercase'>
                      {item.__typename === 'Article' ? item.heading : item.projectName}
                    </h3>
                    <h4 className='mt-4 mb-4 md:mb-8 text-black text-sm'>
                      {item.__typename === 'Article' ? item.subHeading : ''}
                    </h4>
                    {item.__typename === 'Article' ? (
                      <Link
                        to={`/articles/${item.id}`}
                        state={{
                          articleId: item.id,
                          heading: item.heading,
                          subHeading: item.subHeading,
                          bodyContentList: item.bodyContentList,
                          articleImageList: item.articleImageList,
                          createdAt: item.createdAt,
                          createdBy: item.createdBy,
                          articleTemplate: item.articleTemplate,
                          // userId: profileData.userProfile.username,
                        }}
                      >
                        <button className='lg:col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'>
                          Read this article
                        </button>
                      </Link>
                    ) : (
                      <Link
                        to={`/project/${item.id}`}
                        state={{
                          projectId: item.id,
                          projectName: item.projectName,
                          projectCoverImage: item.projectCoverImage,
                          year: item.year,
                          make: item.make,
                          model: item.model,
                          colour: item.colour,
                          description: item.description,
                        }}
                      >
                        <button className='lg:col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'>
                          View this project
                        </button>
                      </Link>
                    )}
                  </div>
                  <div className='col-span-5 md:col-span-3 bg-grey px-8 py-6'>
                    <div className='single-comment mb-6'>
                      <h4 className='text-black text-md font-bold flex items-center'>
                        <svg
                          className='icon text-red'
                          xmlns='http://www.w3.org/2000/svg'
                          width='21.294'
                          height='21.283'
                          viewBox='0 0 21.294 21.283'
                        >
                          <path
                            d='M1067.677,334.854l.609,1.674a8.873,8.873,0,0,1,8.772,2.654,1.315,1.315,0,0,1-.422,2.067l-3.612,1.7a1.2,1.2,0,0,1-1.146-.081,2.662,2.662,0,0,0-3.938,1.433,1.2,1.2,0,0,1-.825.8l-3.859,1.02a1.315,1.315,0,0,1-1.652-1.312,8.873,8.873,0,0,1,5.014-7.672l-.609-1.674a10.644,10.644,0,1,0,1.667-.607Zm2.488,19.139a8.894,8.894,0,0,1-6.6-3.3,1.315,1.315,0,0,1,.693-2.092l3.5-.926a1.308,1.308,0,0,1,1.418.532l1.146,1.7,1.125,1.991A1.411,1.411,0,0,1,1070.164,353.993Zm.914-7.2a1.774,1.774,0,1,1,1.06-2.274A1.774,1.774,0,0,1,1071.078,346.793Zm5.328,4.928a1.411,1.411,0,0,1-2.333-.786l-.417-2.249-.213-2.037a1.307,1.307,0,0,1,.744-1.319l3.279-1.543a1.315,1.315,0,0,1,1.875,1.157A8.9,8.9,0,0,1,1076.406,351.721Z'
                            transform='translate(-1059.824 -334.484)'
                          />
                        </svg>
                        <span className='ml-2'>
                          {item.projectCreator || (item?.subHeading ?? '')}
                        </span>
                      </h4>
                      <div className='mt-2 text-black text-sm'>
                        {item?.description || (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: getHTMLFromDraftState(item?.bodyContentList[0]),
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <CommunityProjects />
    </div>
  );
}

export default Activity;
