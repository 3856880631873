import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { UPLOAD_PHOTO } from '@/utils/mutations/mutations';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { CREATE_ARTICLE } from '../ArticleMutations';
import { ArticleTemplate } from '../AddArticle';
import WYSIWYGEditor from '@/components/utils/WYSIWYGEditor';
import { ImageUploadSection } from './small-components/ImageUploadSection';
import TagSearch, { MHPSearchTagType } from '@/components/utils/TagSearch';
import { disableKeyEnter } from '@/utils/utilities';

const ArticleTemplateTwo = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  const [formError, setFormError] = useState('');
  // TODO - refactor to store array of images while still correlating position on article
  const [articleImages, setArticleImages] = useState<any>(['', '', '']);
  const [articleUploading, setArticleUploading] = useState(false);
  const [articleImageError, setArticleImageError] = useState(false);
  const [addArticleMutation] = useMutation(CREATE_ARTICLE);
  const [uploadPhoto] = useMutation(UPLOAD_PHOTO);
  const [formTags, setFormTags] = useState<any[]>([]);
  const [bodyContentList, setBodyContentList] = useState<string[]>([]);

  // Refactor handle image upload to be reusable
  const handleImageUpload = async (image: any) => {
    let uploadUrl = '';
    try {
      await uploadPhoto({
        variables: { uploadPhotoPhoto: image },
      }).then((data: any) => {
        setArticleImageError(false);
        uploadUrl = data?.data?.uploadPhoto;
      });
      return uploadUrl;
    } catch (err: any) {
      console.log('err: ', err);
    }
  };

  const handleWYSIWYGFormStateUpdate = (
    convertedWYSIWYGContent: string,
    contentPosition: number,
  ) => {
    const newBodyContentList = [
      ...bodyContentList.slice(0, contentPosition),
      convertedWYSIWYGContent,
      ...bodyContentList.slice(contentPosition + 1),
    ];
    setBodyContentList(newBodyContentList);
  };

  // Used to set image position in article - starting from 0 as the arr index.
  const handleImageData = (image: any, positionInArticle: number) => {
    let newArticleImages = [...articleImages];
    newArticleImages[positionInArticle] = image;
    setArticleImages(newArticleImages);
  };

  const handleAddArticle = async (inputs: any) => {
    setArticleUploading(true);
    if (articleImages === null) {
      setArticleImageError(true);
      return;
    }
    // Upload all images - TODO - fix method to await all image uploads - forEach spread into the array?
    Promise.all([
      handleImageUpload(articleImages[0]),
      handleImageUpload(articleImages[1]),
      handleImageUpload(articleImages[2]),
    ])
      .then(async (results) => {
        let articlesImagesUploadUrls = [...results];
        await uploadArticle(inputs, articlesImagesUploadUrls);
      })
      .catch((err) => {
        console.log(err);
        setFormError(err.message);
      });
  };

  const uploadArticle = async (inputs: any, articlesImagesUrls: any) => {
    setFormError('');
    const { heading, subHeading } = inputs;
    try {
      const { data } = await addArticleMutation({
        variables: {
          input: {
            heading,
            subHeading,
            bodyContentList: bodyContentList,
            articleImageList: articlesImagesUrls,
            tags: formTags,
            articleTemplate: ArticleTemplate.TEMPLATE_TWO,
          },
        },
      });

      if (data) {
        navigate('/articles');
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  const handleFormTags = (newTags: any) => {
    setFormTags(newTags);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleAddArticle)} onKeyDown={(e) => disableKeyEnter(e)} className='space-y-6'>
        <div className='shadow sm:rounded-md sm:overflow-hidden'>
          <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
            <div className='flex flex-row'>
              <ImageUploadSection
                articleImages={articleImages}
                handleImageData={handleImageData}
                articleImageError={articleImageError}
                positionInArticle={0}
                smallPreview={true}
              />
            </div>
            <div className='flex w-full flex-col'>
              <Heading />
              <SubHeading />
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700'>Search for Tags</label>
              <TagSearch tagType={MHPSearchTagType.Article} handleFormTags={handleFormTags} />
            </div>
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={0}
            />
            <ImageUploadSection
              articleImages={articleImages}
              handleImageData={handleImageData}
              articleImageError={articleImageError}
              positionInArticle={1}
            />
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description - Optional'}
              contentSectionCount={1}
            />
            <ImageUploadSection
              articleImages={articleImages}
              handleImageData={handleImageData}
              articleImageError={articleImageError}
              positionInArticle={2}
            />
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description - Optional'}
              contentSectionCount={2}
            />
          </div>
          <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
            {articleUploading ? (
              <LoadingSpinner />
            ) : (
              <button
                type='submit'
                className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
              >
                Create Article
              </button>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ArticleTemplateTwo;

const Heading = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className='w-full'>
      <label htmlFor='heading' className='block text-lg font-bold text-gray-700'>
        Heading
      </label>
      <div className='mt-1'>
        <input
          id='heading'
          {...register('heading', { required: 'Article name required' })}
          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
          placeholder='Enter the name of your new Article'
          defaultValue={''}
        />
        <span className='text-red'>{errors.heading && errors.heading.message}</span>
      </div>
    </div>
  );
};

const SubHeading = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <label htmlFor='subheading' className='block text-md font-semibold pt-2 text-gray-700'>
        Sub heading
      </label>
      <div className='mt-1'>
        <input
          id='subheading'
          {...register('subHeading')}
          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
          placeholder='Article subheading'
          defaultValue={''}
        />
      </div>
    </div>
  );
};

// TODO - remove below once all functionality is confirmed working
const BodyContent = ({ bodyContentFieldName }: { bodyContentFieldName: string }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <label htmlFor='content' className='block text-sm font-medium text-gray-700'>
        Content/Description
      </label>
      <div className='mt-1'>
        <textarea
          id='content'
          {...register(`${bodyContentFieldName}`, { required: 'Description required.' })}
          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
          defaultValue={''}
        />
        <span className='text-red'>{errors.bodyContent && errors.bodyContent.message}</span>
      </div>
    </div>
  );
};
