function QueryError() {
  return (
    <div className="text-center pt-5">
      <h2 className="text-xl font-semibold">Something went wrong.</h2>
      <h3 className="font-semibold mb-5">Internal server error. We will resolve it as soon as possible.</h3>
    </div>
  );
}

export default QueryError;
