const PistonIcon = (props) => {
  return (
    <svg
      data-name="Component 59 \u2013 1"
      xmlns="http://www.w3.org/2000/svg"
      width={12.059}
      height={30.678}
      viewBox="0 0 12.059 30.678"
      {...props}
    >
      <path
        data-name="Path 60"
        d="M96.245 597.757H87.47a1.641 1.641 0 00-1.642 1.642v.387a.5.5 0 00.5.5h11.05a.5.5 0 00.5-.5v-.386a1.642 1.642 0 00-1.633-1.643z"
        transform="translate(-85.828 -597.757)"
        fill="#f2133c"
      />
      <rect
        data-name="Rectangle 311"
        width={12.059}
        height={1.681}
        rx={0.84}
        transform="translate(0 3.225)"
        fill="#f2133c"
      />
      <path
        data-name="Path 61"
        d="M94.628 665.518a2.441 2.441 0 01-2.412-2.065.186.186 0 00-.185-.156h-1.293a.188.188 0 00-.188.188v1.8a.494.494 0 00.495.494h.011a2.847 2.847 0 011.607.505 3.486 3.486 0 003.931 0 2.844 2.844 0 011.607-.505h.012a.494.494 0 00.494-.494v-1.8a.188.188 0 00-.188-.188h-1.294a.187.187 0 00-.186.156 2.441 2.441 0 01-2.411 2.065z"
        transform="translate(-88.599 -636.216)"
        fill="#f2133c"
      />
      <path
        data-name="Path 62"
        d="M97.886 611.81a.5.5 0 00-.5-.5H86.333a.5.5 0 00-.5.5v4.461a1.5 1.5 0 001.495 1.495h1.446a1.221 1.221 0 011.215 1.1c.1 1.023.164 2.95.155 3.69a41.543 41.543 0 01-.557 6.227 1.393 1.393 0 01-1.318.955.494.494 0 00-.485.494v1.929a.188.188 0 00.188.188h1.288a.187.187 0 00.185-.157 2.441 2.441 0 014.823 0 .187.187 0 00.186.157h1.293a.188.188 0 00.188-.188v-1.929a.494.494 0 00-.485-.494 1.393 1.393 0 01-1.318-.955 41.543 41.543 0 01-.557-6.227c-.01-.74.052-2.667.155-3.69a1.221 1.221 0 011.216-1.1h1.445a1.5 1.5 0 001.5-1.495v-4.461zm-6.029 4.328a1.208 1.208 0 111.208-1.208 1.208 1.208 0 01-1.208 1.207z"
        transform="translate(-85.828 -605.706)"
        fill="#f2133c"
      />
    </svg>
  )
}

export default PistonIcon
