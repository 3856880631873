import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import client from './services/apollo'
import { store } from './store/store';
import * as serviceWorker from './serviceWorker';

import '@/assets/index.scss';
import Router from '@/router';
// import reportWebVitals from './reportWebVitals';

// const httpLink = createHttpLink({
//   uri: 'http://localhost:7700/graphql',
// });

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem('access_token');
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     }
//   }
// });

// // const client = new ApolloClient({
// //   link: authLink.concat(httpLink),
// //   cache: new InMemoryCache()
// // });

// const GET_NEW_TOKEN = gql`
//   mutation Mutation($input: String!) {
//     authenticateRefreshToken(input: $input) {
//       accessToken
//       refreshToken
//     }
//   }
// `;

// let apolloClient: any;

// const getNewToken = () => {
//   const refreshToken = localStorage.getItem('refresh_token');
//   return apolloClient.useMutation(GET_NEW_TOKEN, { variables: { refreshToken } }).then((response: any) => {
//     // extract your accessToken from your response data and return it
//     const { accessToken } = response.data;
//     return accessToken;
//   });
// };

// const errorLink = onError(
//   ({ graphQLErrors, networkError, operation, forward }) => {
//     if (graphQLErrors) {
//       for (let err of graphQLErrors) {
//         switch (err.extensions.code) {
//           case "UNAUTHENTICATED":
//             return fromPromise(
//               getNewToken().catch((error: any) => {
//                 // Handle token refresh errors e.g clear stored tokens, redirect to login
//                 return;
//               })
//             )
//               .filter((value) => Boolean(value))
//               .flatMap((accessToken) => {
//                 const oldHeaders = operation.getContext().headers;
//                 // modify the operation context with a new token
//                 operation.setContext({
//                   headers: {
//                     ...oldHeaders,
//                     authorization: `Bearer ${accessToken}`,
//                   },
//                 });

//                 // retry the request, returning the new observable
//                 return forward(operation);
//               });
//         }
//       }
//     }
//   }
// );

// apolloClient = new ApolloClient({
//   link: ApolloLink.from([errorLink, authLink, httpLink]),
// });

declare global {
  interface Window {
    client: any;
    store: any;
  }
}

if (process.env.NODE_ENV === 'development') {
  window.client = client || {};
  window.store = store || {};
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
