import decode from "jwt-decode"

export const JWT_ACCESS_KEY = process.env.REACT_APP_ACCESS_TOKEN_NAME ?? '_hjwt__access_https___hi_performance'
export const JWT_REFRESH_KEY = process.env.REACT_APP_REFRESH_TOKEN_NAME ?? '_hjwt__refresh_https___hi_performance'

const getAccessToken = (): string | undefined => {
  const accessKey = localStorage.getItem(JWT_ACCESS_KEY)
  if (typeof window === `undefined`) return undefined
  if (typeof accessKey === 'string') return accessKey
}

const getRefreshToken = (): string | undefined => {
  const refreshKey = localStorage.getItem(JWT_REFRESH_KEY)
  if (typeof window === `undefined`) return undefined
  if (typeof refreshKey === 'string') return refreshKey
}

export const setJwt = (jwt: any) => localStorage.setItem(JWT_ACCESS_KEY, JSON.stringify(jwt))

export const deleteJwt = () => localStorage.removeItem(JWT_ACCESS_KEY)

export const isTokenExpired = (token: any) => decode<any>(token).exp <= Date.now() / 1000

export const setAuthToken = (authToken: any) => {
  const jwt = getAccessToken()
  jwt && setJwt(authToken)
}

// export const getUser = () => (getAccessToken() || {}) || undefined

export { getAccessToken, getRefreshToken }