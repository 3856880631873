import { useState, useContext, useEffect } from "react"
import { useQuery, gql, useMutation } from "@apollo/client"
import { Link, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import LoadingSpinner from '@/utils/loading/LoadingSpinner';

import Chat from "@/pages/user/messages/Chat"
import CurrentChat from "@/pages/user/messages/CurrentChat"
import { RootState } from '@/store/reducers/rootReducer'
import { subscribeUser } from '@/desktopnotification'
import QueryError from '@/utils/QueryError';

// Get the user chats & render them out
const GET_USER_CHATS = gql`
  query Query {
    getUserChats {
      chatSlug
      chatName
      users
      isChatEnquiry
      advertiserResponsed
    }
  }
`;

const Messages = () => {
  const { chatId } = useParams();
  const { pathname } = useLocation();
  const [ messageFilter, setMessageFilter ] = useState('personal');
  const { userData } = useSelector((state: RootState) => state.user);
  const { loading, error, data, refetch } = useQuery(GET_USER_CHATS, {
    fetchPolicy: "no-cache",
  });
  const [mobileChatActive, setMobileChatActive] = useState<boolean>(true);

  useEffect(() => {
    refetch();
  },[data])

  const slug = pathname.split('/messages/').pop();

  useEffect(() => {
    if(chatId === slug) {
      setMobileChatActive(true);
    } else {
      setMobileChatActive(false);
    }
  }, [chatId])

  const handleMessageFilterClick = (data: string) => {
    setMessageFilter(data)
  }

  if (loading) return <LoadingSpinner />;
  if (error) return <QueryError />;

  return (
    <>
      <div className="flex flex-col w-full pt-4 pb-8">
        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center">
            <div className="text-xl font-semibold">Messages</div>
            {/* <div className="flex items-center justify-center ml-2 text-xs h-5 w-5 text-white bg-red rounded-full font-medium">5</div> */}
          </div>
          <div className="ml-auto flex items-center">
          {/* <button className="col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-gray-800 hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl" onClick={subscribeUser}>Enable desktop notifications</button> */}
            <Link className="col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl" to="/messages/add">+ Create chat</Link>
            {/* <button className="inline-flex text-white p-4 bg-red rounded-md ml-5" onClick={subscribeUser}>Notify Me</button> */}
          </div>
        </div>
      </div>
      { data.getUserChats.length ? <>
        {/* <div className="mt-5 mb-5">
          <ul className="flex flex-row items-center">
            <li>
              <a href="#"
                className="flex items-center pb-3 text-xs font-semibold relative text-white">
                <span>All Conversations</span>
                <span className="absolute left-0 bottom-0 h-1 w-6 bg-white rounded-full"></span>
              </a>
            </li>
          </ul>
        </div> */}
        <div className="flex flex-row grid grid-cols-12">
          <div className={'col-span-12 lg:col-span-4 bg-darkgrey2 rounded-md overflow-y-auto lg:mr-8 h-[calc(100vh-200px)] ' + ((mobileChatActive === true) ? 'hidden lg:block lg:order-none' : '')} >
            <div className="h-full relative">
              <div className="flex pt-5 mb-2 mx-3 border-b border-gray-100/30">
                {/* <button className={`text-sm font-semibold px-2 pb-2 border-b uppercase hover:text-white hover:border-red ${(messageFilter === 'all') ? 'text-white border-red' : 'text-gray-400 border-gray-100/50' }`} style={{ marginBottom: `-1px` }} onClick={() => handleMessageFilterClick('all')}>All</button> */}
                <button className={`text-sm font-semibold px-2 pb-2 border-b uppercase hover:text-white hover:border-red ${(messageFilter === 'personal') ? 'text-white border-red' : 'text-gray-400 border-gray-100/50' }`} style={{ marginBottom: `-1px` }} onClick={() => handleMessageFilterClick('personal')}>Personal</button>
                <button className={`text-sm font-semibold px-2 pb-2 border-b uppercase hover:text-white hover:border-red ${(messageFilter === 'enquiry') ? 'text-white border-red' : 'text-gray-400 border-gray-100/50' }`} style={{ marginBottom: `-1px` }} onClick={() => handleMessageFilterClick('enquiry')}>Enquiry</button>
              </div>
              <div className="overflow-hidden">
                <div className="flex flex-col h-full overflow-y-scroll">
                { data.getUserChats.map((data: any, index: any) => {
                  let messageName;
                  let commaCount = (data.chatName.match(/,/g) || []).length;
                  if(commaCount > 2) {
                    messageName = data.chatName;
                  } else {
                    messageName = data.chatName.replace(userData.fullName, '').trim().replace(',', '');
                  }

                  if(messageFilter === 'personal') {
                    if(!data.isChatEnquiry) {
                      return (
                        <Link key={index} className={`flex items-center px-4 py-2 relative hover:bg-gray-700 cursor-pointer ${(data.chatSlug === chatId) ? 'bg-gray-700' : ''}`} to={`/messages/${data.chatSlug}`}>
                          <div className="flex justify-center items-center flex-none h-10 w-10 rounded-full bg-pink-500 text-pink-300 font-bold capitalize">
                            {messageName.charAt(0)}
                          </div>
                          <div className="ml-3 pr-5 w-full overflow-hidden flex-1">
                            <div className="text-sm truncate">{messageName}</div>
                            {/* <div className="text-xs truncate w-40 text-gray-400">{data.messages[latestMessageIndex].content}</div> */}
                          </div>
                          <div className="flex-none ml-2">
                            {/* <span className="flex items-center justify-center h-5 w-5 bg-red text-white text-xs rounded-full">3</span> */}
                          </div>
                        </Link>
                      )
                    }
                  }
                  if (messageFilter === 'enquiry') {
                    if(data.isChatEnquiry && data.advertiserResponsed) {
                      return (
                        <Link key={index} className={`flex items-center px-4 py-2 relative hover:bg-gray-700 cursor-pointer ${(data.chatSlug === chatId) ? 'bg-gray-700' : ''}`} to={`/messages/${data.chatSlug}`}>
                          <div className="flex justify-center items-center flex-none h-10 w-10 rounded-full bg-pink-500 text-pink-300 font-bold capitalize">
                            {messageName.charAt(0)}
                          </div>
                          <div className="ml-3 pr-5 w-full overflow-hidden flex-1">
                            <div className="text-sm truncate">{messageName}</div>
                            {/* <div className="text-xs truncate w-40 text-gray-400">{data.messages[latestMessageIndex].content}</div> */}
                          </div>
                          <div className="flex-none ml-2">
                            {/* <span className="flex items-center justify-center h-5 w-5 bg-red text-white text-xs rounded-full">3</span> */}
                          </div>
                        </Link>
                      )
                    }
                  }
                  })}
                </div>
                {/* <div className="absolute bottom-3 right-2 mr-2">
                  <Link className="flex items-center justify-center shadow-sm h-10 w-10 bg-red text-white rounded-full hover:bg-darkgrey transition ease-in-out duration-300" to="/messages/add">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                    </svg>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
          { chatId && <CurrentChat chatId={chatId} mobileChatActive={mobileChatActive} /> }
        </div>
      </>: <div className="text-center pt-5">
        <h2 className="text-xl font-semibold">No conversation started yet.</h2>
        <h3 className="font-semibold mb-5">Click the button below to start a conversation.</h3>
        <Link className="inline-block whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl" to="/messages/add">+ Create chat</Link>
        </div>}
    </>
  )
}

export default Messages;
