import { RootState } from '@/store/reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { gql } from '@apollo/client/core';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import LoadingSpinner from '@/components/LoadingSpinner';
import { UserActionType, UserActions } from '@/store/actions/userActions';

const EDIT_PROFILE = gql`
  mutation EditUserProfile($input: EditProfile!) {
    editProfile(input: $input) {
      firstName
      lastName
      username
      about
    }
  }
`;

const EDIT_PROFILE_PHOTO = gql`
  mutation EditUserProfile($profilePicture: String!) {
    editProfilePhoto(profilePicture: $profilePicture) {
      firstName
      lastName
      username
      profilePicture
      about
    }
  }
`;

const UPLOAD_PHOTO = gql`
  mutation UploadPhotoMutation($uploadPhotoPhoto: String) {
    uploadPhoto(photo: $uploadPhotoPhoto)
  }
`;

const CHECK_USERNAME_EXIST = gql`
  query CheckUsernameExist($username: String!) {
    checkUsernameExists(username: $username)
  }
`;

const CHECK_EMAIL_EXIST = gql`
  query CheckEmailExist($email: String!) {
    checkEmailExists(email: $email)
  }
`;

const EditProfile = () => {
  const photoInput = useRef<any>()
  const { userData } = useSelector((state: RootState) => state.user);
  const userDispatch = useDispatch<Dispatch<UserActions>>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const [newUsername, setNewUsername] = useState('');
  const [newFirstName, setNewFirstName] = useState<string>('');
  const [newLastName, setNewLastName] = useState<string>('');
  const [validatingUsername, setValidatingUsername] = useState(false);
  const [validNewUsername, setValidNewUsername] = useState(false);
  const [isUsernameUsed, setIsUsernameUsed] = useState(false);
  const [profileUpdateSuccessful, setProfileUpdateSuccessful] = useState(false);
  const [profilePhotoUpdate, setProfilePhotoUpdate] = useState({ success: false, message: ''});
  const [imagePreview, setImagePreview] = useState<any>('');
  const [profileImageURL, setProfileImageUrl] = useState('');
  const [checkUsernameExist, { data: isUsernameAvailable }] = useLazyQuery(CHECK_USERNAME_EXIST, {
    variables: { username: newUsername },
    onCompleted: (data) => {
      setValidatingUsername(false);
      if (!data.checkUsernameExists) {
        setValidNewUsername(true);
      } else {
        setIsUsernameUsed(true);
      }
    },
  });
  const [formError, setFormError] = useState('');
  const [editUserProfile, { loading: updateProfileLoading }] = useMutation(EDIT_PROFILE);
  const [editUserProfilePhoto] = useMutation(EDIT_PROFILE_PHOTO);
  const [uploadPhoto] = useMutation(UPLOAD_PHOTO, {
    onCompleted(data) {
      let newImageObj = { imageUrl: data.uploadPhoto };
      setProfileImageUrl(newImageObj.imageUrl);
    },
  });

  const handleEditProfile = async (inputs: any) => {
    setFormError('');
    const { firstName, lastName, username } = inputs;
    try {
      const { data } = await editUserProfile({
        variables: {
          input: {
            username: newUsername || username,
            firstName: newFirstName || firstName,
            lastName: newLastName || lastName,
          },
        },
      });

      if (data) {
        setProfileUpdateSuccessful(true);
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  const handleUsernameChange = (username: string) => {
    setValidNewUsername(false);
    setIsUsernameUsed(false);
    if (username.length > 1 && errors.username === undefined && !errors.username) {
      setValidatingUsername(true);
      setNewUsername(username);
      setTimeout(() => {
        checkUsernameExist();
      }, 3000);
    }
  };

  async function handleImageUpload(e: any) {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      setImagePreview(reader.result);
      try {
        const data = await uploadPhoto({
          variables: { uploadPhotoPhoto: reader.result },
        });
        if(data) {
          setProfilePhotoUpdate({ success: true, message: 'Profile photo updated successfully.' })
          userDispatch({ type: UserActionType.SET_PROFILE_PHOTO, payload: data.data.uploadPhoto });
          try {
            const updatedProfile = await editUserProfilePhoto({
              variables: {
                profilePicture: data.data.uploadPhoto
              },
            });

            if (updatedProfile.data) {
              setProfileUpdateSuccessful(true);
            }
          } catch (err: any) {
            setFormError(err.message);
          }
        }
      } catch(err: any) {
        setProfilePhotoUpdate({ success: false, message: err.message })
      }
    };
  }

  const handleUpdatePhoto = () => {
    if(photoInput.current) photoInput.current.click()
  }

  return (
    <form className='space-y-6' onSubmit={handleSubmit(handleEditProfile)}>
      {profileUpdateSuccessful && (
        <div className='bg-red rounded-md p-3 flex items-center'>
          <CheckCircleIcon className='mr-2 h-5 w-5 text-white ' />
          <div className='text-white'>
            <div className=''>Your profile has been updated!</div>
          </div>
        </div>
      )}
      {profilePhotoUpdate.message.length > 1 && (
        <div className={`rounded-md p-3 flex items-center ${profilePhotoUpdate.success ? 'bg-green-600' : 'bg-red'}`}>
          <CheckCircleIcon className='mr-2 h-5 w-5 text-white ' />
          <div className='text-white'>
            <div className=''>{profilePhotoUpdate.message}</div>
          </div>
        </div>
      )}
      <div className='bg-white shadow px-4 py-5 rounded-lg sm:p-6'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <h3 className='text-lg font-medium font-bold leading-6 text-gray-900'>Edit Profile</h3>
            <p className='mt-1 text-sm text-gray-500'>You can make changes to your profile here.</p>
          </div>
          <div className='mt-5 space-y-6 md:mt-0 md:col-span-2'>
            <div className='flex items-center'>
              <div className='cursor-pointer mr-4 border-2 border-radius-50 border-red w-16 h-16 flex justify-center items-center rounded-full hover:bg-red transition duration-300 ease-in-out overflow-hidden'>
                { userData.profilePicture !== '' && <img className='object-cover' src={userData.profilePicture} alt='ProfileImage' /> }
                <input
                  className='h-24 w-24 opacity-0'
                  type='file'
                  ref={photoInput}
                  onChange={(event) => {
                    handleImageUpload(event);
                  }}
                />
                <div className='absolute text-2xl'>+</div>
              </div>
              <div className="inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl cursor-pointer" onClick={() => handleUpdatePhoto()}>Update Photo</div>
            </div>
            <div className='grid grid-cols-6 gap-6'>
              <div className='col-span-6 sm:col-span-3'>
                <label htmlFor='first-name' className='block text-sm font-medium text-gray-700'>
                  First name
                </label>
                <input
                  {...register('firstName', { required: 'Firstname is required' })}
                  type='text'
                  name='first-name'
                  id='first-name'
                  autoComplete='given-name'
                  className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-black'
                  defaultValue={userData.firstName}
                  onChange={(event) => setNewFirstName(event?.target?.value)}
                />
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label htmlFor='last-name' className='block text-sm font-medium text-gray-700'>
                  Last name
                </label>
                <input
                  {...register('lastName', { required: 'Lastname is required' })}
                  type='text'
                  name='last-name'
                  id='last-name'
                  autoComplete='family-name'
                  className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-black'
                  defaultValue={userData.lastName}
                  onChange={(event) => setNewLastName(event?.target?.value)}
                />
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label htmlFor='username' className='block text-sm font-medium text-gray-700'>
                  Username
                </label>
                <div className='relative'>
                  <input
                    className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md text-black'
                    type='text'
                    placeholder='Enter Username'
                    id='username'
                    defaultValue={userData.username}
                    {...register('username', {
                      required: 'Username is required.',
                      minLength: {
                        value: 4,
                        message: 'Minimum 4 characters for username.',
                      },
                      pattern: {
                        value: /^[\w.-]*$/,
                        message: 'Invalid username format.',
                      },
                      onChange: (e) => handleUsernameChange(e.target.value),
                    })}
                  />
                  {validatingUsername && (
                    <svg
                      className='animate-spin -ml-1 mr-3 h-5 w-5 text-white absolute right-1 top-2 text-indigo-500'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      ></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      ></path>
                    </svg>
                  )}
                  {validNewUsername && (
                    <CheckCircleIcon className='-ml-1 mr-3 h-5 w-5 text-green-500 absolute right-1 top-2' />
                  )}
                </div>
                {errors?.username && (
                  <div className='text-red text-sm'>{errors.username.message}</div>
                )}
                {!errors?.username && isUsernameUsed && (
                  <div className='text-red text-sm'>Username already exist</div>
                )}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <div className='flex justify-end'>
        {updateProfileLoading ? (
          <LoadingSpinner />
        ) : (
          <button
            type='submit'
            className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
          >
            Update Profile
          </button>
        )}
      </div>
    </form>
  );
};

export default EditProfile;
