import { useState } from 'react';
import { PlusCircleIcon, XIcon } from '@heroicons/react/outline';
import { useMutation, gql } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { UPLOAD_PHOTO } from '@/utils/mutations/mutations';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';

const CREATE_PRODUCT = gql`
  mutation Mutation($input: ProductInput) {
    createProduct(input: $input) {
      title
      price
      bodyContent
    }
  }
`;

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const CreateProductForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [formError, setFormError] = useState('');
  const [productCoverImage, setProductCoverImage] = useState('');
  const [imagePreview, setImagePreview] = useState<any>();
  const [addProductMutation, { data: addProductData, loading: addProductloading }] =
    useMutation(CREATE_PRODUCT);
  const [productCoverImageError, setProductCoverImageError] = useState({ success: false, message: ''});
  const [uploadPhoto, { data: uploadPhotoData, loading: uploadPhotoLoading }] =
    useMutation(UPLOAD_PHOTO);

  const [popOverStateManager, setPopOverStateManager] = useState<any>({
    tags: {
      values: {
        activeSearchValue: '',
        searchResults: [],
        selectedTags: [],
      },
      properties: {
        isActive: false,
      },
    },
    categories: {
      values: {
        activeSearchValue: '',
        searchResults: [],
        selectedCategories: [],
      },
      properties: {
        isActive: false,
      },
    },
    vendor: {
      properties: {
        isActive: false,
      },
    },
  });

  const handleImageUpload = async (e: any) => {
    setProductCoverImageError({ success: false, message: ''});
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      setImagePreview(reader.result);
      try {
        const newPhoto = await uploadPhoto({
          variables: { uploadPhotoPhoto: reader.result },
        })
        if(newPhoto.data) {
          setProductCoverImage(newPhoto?.data?.uploadPhoto);
          setProductCoverImageError({ success: true, message: 'Product Image successfully uploaded.'});
        }
      } catch (err: any) {
        setProductCoverImageError({ success: false, message: err.message});
      }
    }
  };

  const handleAddProduct = async (inputs: any) => {
    if (productCoverImage === '' || productCoverImage === null) {
      setProductCoverImageError({ success: false, message: 'Product Image required.'});
      return;
    }
    setFormError('');
    const { title, price, comparePrice, bodyContent, type, vendor } = inputs;
    try {
      const { data } = await addProductMutation({
        variables: {
          input: {
            title,
            price,
            comparePrice,
            bodyContent,
            type,
            vendor,
            categories: popOverStateManager.categories.values.selectedCategories,
            tags: popOverStateManager.tags.values.selectedTags,
            productImageURL: productCoverImage,
          },
        },
      });

      if (data) {
        navigate('/products');
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  const handleTagSearch = (e: any) => {
    if (e.target.value.length >= 1) {
      setPopOverStateManager({
        ...popOverStateManager,
        tags: {
          ...popOverStateManager.tags,
          values: {
            ...popOverStateManager.tags.values,
            activeSearchValue: e.target.value,
          },
          properties: {
            isActive: true,
          },
        },
      });
    } else {
      setPopOverStateManager({
        ...popOverStateManager,
        tags: {
          ...popOverStateManager.tags,
          values: {
            ...popOverStateManager.tags.values,
            activeSearchValue: e.target.value,
          },
          properties: {
            isActive: false,
          },
        },
      });
    }
  };

  const handleCategorySearch = (e: any) => {
    if (e.target.value.length >= 1) {
      setPopOverStateManager({
        ...popOverStateManager,
        categories: {
          ...popOverStateManager.categories,
          values: {
            ...popOverStateManager.categories.values,
            activeSearchValue: e.target.value,
          },
          properties: {
            isActive: true,
          },
        },
      });
    } else {
      setPopOverStateManager({
        ...popOverStateManager,
        categories: {
          ...popOverStateManager.categories,
          values: {
            ...popOverStateManager.categories.values,
            activeSearchValue: e.target.value,
          },
          properties: {
            isActive: false,
          },
        },
      });
    }
  };

  const addProductTag = () => {
    setPopOverStateManager({
      ...popOverStateManager,
      tags: {
        ...popOverStateManager.tags,
        values: {
          ...popOverStateManager.tags.values,
          selectedTags: [
            ...popOverStateManager.tags.values.selectedTags,
            popOverStateManager.tags.values.activeSearchValue.trim(),
          ],
          activeSearchValue: '',
        },
        properties: {
          isActive: false,
        },
      },
    });
  };

  const addProductCategory = () => {
    setPopOverStateManager({
      ...popOverStateManager,
      categories: {
        ...popOverStateManager.categories,
        values: {
          ...popOverStateManager.categories.values,
          selectedCategories: [
            ...popOverStateManager.categories.values.selectedCategories,
            popOverStateManager.categories.values.activeSearchValue.trim(),
          ],
          activeSearchValue: '',
        },
        properties: {
          isActive: false,
        },
      },
    });
  };

  const handleRemoveTag = (tag: string) => {
    setPopOverStateManager({
      ...popOverStateManager,
      tags: {
        ...popOverStateManager.tags,
        values: {
          ...popOverStateManager.tags.values,
          selectedTags: popOverStateManager.tags.values.selectedTags.filter(
            (selectedTag: string) => selectedTag !== tag,
          ),
        },
        properties: {
          isActive: false,
        },
      },
    });
  };

  const handleRemoveCategory = (category: string) => {
    setPopOverStateManager({
      ...popOverStateManager,
      categories: {
        ...popOverStateManager.categories,
        values: {
          ...popOverStateManager.categories.values,
          selectedcategories: popOverStateManager.categories.values.selectedCategories.filter(
            (selectedCategory: string) => selectedCategory !== category,
          ),
        },
        properties: {
          isActive: false,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleAddProduct)} className='space-y-6'>
      <div className='shadow sm:rounded-md sm:overflow-hidden'>
        <div className='px-4 py-5 bg-white'>
          <div className='grid grid-cols-5 gap-12'>
            <div className='col-span-3 space-y-6 sm:p-6'>
              <div>
                <label htmlFor='title' className='block text-sm font-medium text-gray-700'>
                  Product Title
                </label>
                <div className='mt-1'>
                  <input
                    id='title'
                    {...register('title', { required: 'Product name required' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter product title'
                    defaultValue={''}
                  />
                  <span className='text-red'>{errors.heading && errors.heading.message}</span>
                </div>
              </div>

              <div className='grid grid-cols-2 gap-6'>
                <div className='col-span-1'>
                  <div>
                    <label htmlFor='price' className='block text-sm font-medium text-gray-700'>
                      Price
                    </label>
                    <div className='mt-1'>
                      <input
                        id='price'
                        {...register('price')}
                        className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                        placeholder='Enter price'
                        defaultValue={''}
                      />
                      <span className='text-red'>{errors.heading && errors.heading.message}</span>
                    </div>
                  </div>
                </div>
                <div className='col-span-1'>
                  <div>
                    <label
                      htmlFor='compareprice'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Compare Price
                    </label>
                    <div className='mt-1'>
                      <input
                        id='compareprice'
                        {...register('compareprice')}
                        className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                        placeholder='Pre-discout price(optional)'
                        defaultValue={''}
                      />
                      <span className='text-red'>{errors.heading && errors.heading.message}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor='content' className='block text-sm font-medium text-gray-700'>
                  Product Description
                </label>
                <div className='mt-1'>
                  <textarea
                    id='content'
                    {...register('bodyContent', { required: 'Description required.' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter product description'
                    defaultValue={''}
                  />
                  <span className='text-red'>
                    {errors.bodyContent && errors.bodyContent.message}
                  </span>
                </div>
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700'>Photos</label>
                <div
                  className={`mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md ${
                    productCoverImage === '' ? 'px-6 pt-5 pb-6' : ''
                  }`}
                >
                  <div className='space-y-1 text-center'>
                    {uploadPhotoLoading ? (
                      <LoadingSpinner />
                    ) : productCoverImage === '' ? (
                      <>
                        <svg
                          className='mx-auto h-12 w-12 text-gray-400'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 48 48'
                          aria-hidden='true'
                        >
                          <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <div className='flex text-sm text-gray-600'>
                          <label
                            htmlFor='file-upload'
                            className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                          >
                            <span>Upload a file</span>
                            <input
                              id='file-upload'
                              name='file-upload'
                              type='file'
                              onChange={(event) => {
                                handleImageUpload(event);
                              }}
                              className='sr-only'
                            />
                          </label>
                          <p className='pl-1'>or drag and drop</p>
                        </div>
                        <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                      </>
                    ) : (
                      <div className='h-full w-full max-h-48 relative'>
                        <img
                          className='object-cover h-full w-full'
                          src={imagePreview}
                          alt='ProfileImage'
                        />
                      </div>
                    )}
                  </div>
                </div>
                {productCoverImageError.message.length > 1 ? (
                  <span className='text-red'>{ productCoverImageError.message }</span>
                ) : null}
              </div>
            </div>
            <div className='col-span-2 space-y-6 sm:p-6'>
              <div>
                <label htmlFor='type' className='block text-sm font-medium text-gray-700'>
                  Product Type
                </label>
                <div className='mt-1'>
                  <input
                    id='type'
                    {...register('type', { required: 'Product type is required' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder=''
                    defaultValue={''}
                  />
                </div>
              </div>
              <div>
                <label htmlFor='vendor' className='block text-sm font-medium text-gray-700'>
                  Product Vendor/Brand
                </label>
                <div className='mt-1'>
                  <input
                    id='vendor'
                    {...register('vendor')}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder=''
                    defaultValue={''}
                  />
                </div>
              </div>
              <div className='relative pb-1'>
                <label htmlFor='category' className='block text-sm font-medium text-gray-700'>
                  Category
                </label>
                <div className='mt-1'>
                  <input
                    id='category'
                    onFocus={handleCategorySearch}
                    onChange={handleCategorySearch}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder=''
                    value={popOverStateManager.categories.values.activeSearchValue}
                  />
                </div>
                {popOverStateManager.categories.values.selectedCategories && (
                  <ul>
                    {popOverStateManager.categories.values.selectedCategories.map(
                      (category: any, index: any) => (
                        <div
                          className='bg-indigo-100 inline-flex items-center text-xs rounded mt-2 mr-1 p-0.5 text-black'
                          key={index}
                        >
                          <span className='ml-2 mr-1 leading-relaxed truncate max-w-xs' key={index}>
                            {category}
                          </span>
                          <span
                            onClick={() => handleRemoveCategory(category)}
                            className='ml-0.5 cursor-pointer'
                          >
                            <XIcon className='h-3 w-3 text-red' aria-hidden='true' />
                          </span>
                        </div>
                      ),
                    )}
                  </ul>
                )}
                {popOverStateManager.categories.properties.isActive && (
                  <div className='absolute left-0 top-16 bg-white w-full shadow-sm rounded-md border border-gray-300 z-10'>
                    <div
                      className='flex items-center text-black p-2 hover:bg-gray-100 cursor-pointer'
                      onClick={addProductCategory}
                    >
                      <PlusCircleIcon
                        className='h-5 w-5 flex-shrink-0 text-gray-400'
                        aria-hidden='true'
                      />
                      <span className='ml-2 w-0 flex-1 text-sm'>
                        <span className='font-bold'>Add</span>{' '}
                        {popOverStateManager.categories.values.activeSearchValue}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='relative pb-1'>
                <label htmlFor='tags' className='block text-sm font-medium text-gray-700'>
                  Product Tags
                </label>
                <div className='mt-1'>
                  <input
                    id='tags'
                    onFocus={handleTagSearch}
                    onChange={handleTagSearch}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder=''
                    value={popOverStateManager.tags.values.activeSearchValue}
                  />
                </div>
                {popOverStateManager.tags.values.selectedTags && (
                  <ul>
                    {popOverStateManager.tags.values.selectedTags.map((tag: any, index: any) => (
                      <div
                        className='bg-indigo-100 inline-flex items-center text-xs rounded mt-2 mr-1 p-0.5 text-black'
                        key={index}
                      >
                        <span className='ml-2 mr-1 leading-relaxed truncate max-w-xs' key={index}>
                          {tag}
                        </span>
                        <span
                          onClick={() => handleRemoveTag(tag)}
                          className='ml-0.5 cursor-pointer'
                        >
                          <XIcon className='h-3 w-3 text-red' aria-hidden='true' />
                        </span>
                      </div>
                    ))}
                  </ul>
                )}
                {popOverStateManager.tags.properties.isActive && (
                  <div className='absolute left-0 top-16 bg-white w-full shadow-sm rounded-md border border-gray-300'>
                    <div
                      className='flex items-center text-black p-2 hover:bg-gray-100 cursor-pointer'
                      onClick={addProductTag}
                    >
                      <PlusCircleIcon
                        className='h-5 w-5 flex-shrink-0 text-gray-400'
                        aria-hidden='true'
                      />
                      <span className='ml-2 w-0 flex-1 text-sm'>
                        <span className='font-bold'>Add</span>{' '}
                        {popOverStateManager.tags.values.activeSearchValue}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
          {addProductloading ? (
            <LoadingSpinner />
          ) : (
            <button
              type='submit'
              className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
            >
              Create Product
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default CreateProductForm;
