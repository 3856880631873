import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { RootState } from '@/store/reducers/rootReducer';

import PrivateRoute from '@/router/PrivateRoute';
import Login from '@/pages/auth/Login';
import Register from '@/pages/auth/Register';
import Forgot from '@/pages/auth/Forgot';
import Dashboard from '@/pages/user/Dashboard';
import WelcomeScreen from '@/pages/user/WelcomeScreen';
import Homepage from '@/pages/user/Homepage';

// Project imports
import Projects from '@/pages/user/Projects';
import AddNewProject from '@/pages/user/projects/AddNewProject';
import SingleProject from '@/pages/user/projects/SingleProject';

// Articles imports
import Articles from '@/pages/user/Articles';
import AddArticle from '@/pages/user/articles/AddArticle';
import SingleArticle from '@/pages/user/articles/SingleArticle';

// Product imports
import Products from '@/pages/user/Products';
import AddProduct from '@/pages/user/products/AddProduct';
import SingleProduct from '@/pages/user/products/SingleProduct';

// Messaging system
import Messages from '@/pages/user/Messages';
import AddMessage from '@/pages/user/messages/AddMessage';

// Coming Soon
import ComingSoon from '@/pages/ComingSoon';

// Profile Routes
import Profile from '@/pages/user/Profile';
import EditProfile from '@/pages/user/EditProfile';
import Shop from '@/pages/user/Shop';

// Admin Routes
import ManageTags from '@/pages/admin/ManageTags';

// Feeds/posts
import AddPost from '@/pages/user/posts/AddPost';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='forgot' element={<Forgot />} />
        <Route path='message' element={<Messages />} />
        <Route
          path='welcome'
          element={
            <PrivateRoute>
              <WelcomeScreen />
            </PrivateRoute>
          }
        />
        <Route
          path='/'
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute>
                <Homepage />
              </PrivateRoute>
            }
          />
          <Route
            path='adminarea/tags'
            element={
              <PrivateRoute>
                <ManageTags />
              </PrivateRoute>
            }
          />
          <Route
            path='profile/:username'
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path='profile/:username/edit'
            element={
              <PrivateRoute>
                <EditProfile />
              </PrivateRoute>
            }
          />
          <Route
            path='/shop'
            element={
              <PrivateRoute>
                <Shop />
              </PrivateRoute>
            }
          />
          <Route
            path='articles'
            element={
              <PrivateRoute>
                <Articles />
              </PrivateRoute>
            }
          />
          <Route
            path='articles/add'
            element={
              <PrivateRoute>
                <AddArticle />
              </PrivateRoute>
            }
          />
          <Route
            path='articles/:articleSlug'
            element={
              <PrivateRoute>
                <SingleArticle />
              </PrivateRoute>
            }
          />
          <Route
            path='articles'
            element={
              <PrivateRoute>
                <WelcomeScreen />
              </PrivateRoute>
            }
          />
          <Route path='/post/add' element={<AddPost />} />
          <Route path='projects' element={<Projects />} />
          <Route path='projects/add' element={<AddNewProject />} />
          <Route path='/project/:projectId' element={<SingleProject />} />
          <Route path='groups' element={<ComingSoon />} />
          <Route
            path='messages'
            element={
              <PrivateRoute>
                <Messages />
              </PrivateRoute>
            }
          />
          <Route
            path='/messages/:chatId'
            element={
              <PrivateRoute>
                <Messages />
              </PrivateRoute>
            }
          />
          <Route
            path='/messages/enquiry/:chatId'
            element={
              <PrivateRoute>
                <Messages />
              </PrivateRoute>
            }
          />
          <Route
            path='messages/add'
            element={
              <PrivateRoute>
                <AddMessage />
              </PrivateRoute>
            }
          />
          <Route path='categories' element={<ComingSoon />} />
          <Route path='products' element={<Products />} />
          <Route
            path='products/add'
            element={
              <PrivateRoute>
                <AddProduct />
              </PrivateRoute>
            }
          />
          <Route
            path='products/:productSlug'
            element={
              <PrivateRoute>
                <SingleProduct />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path='*' element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
