import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// For modifying the config options please refer to the documentation: https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp

function WYSIWYGEditor({
  formStateUpdateMethod,
  formLabel,
  contentSectionCount,
}: {
  formStateUpdateMethod: Function;
  formLabel?: string;
  contentSectionCount?: number;
}) {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    formStateUpdateMethod &&
      formStateUpdateMethod(
        JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        contentSectionCount ?? 0,
      );
  }, [editorState]);

  return (
    <div>
      <label className='block text-sm font-medium text-gray-700 mb-1'>{formLabel}</label>
      <Editor
        wrapperClassName='h-full w-full text-black p-2 shadow-sm min-h-[300px] focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md box-border-important'
        editorState={editorState}
        onEditorStateChange={setEditorState}
        toolbar={{
          options: ['inline', 'fontSize', 'textAlign'],
          inline: { inDropdown: true },
          textAlign: { inDropdown: true },
        }}
        stripPastedStyles={true} // Thank God this prop exists <3
      />
    </div>
  );
}

export default WYSIWYGEditor;
