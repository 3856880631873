import { RootState } from '@/store/reducers/baseReducer'

export enum BaseActionType {
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SET_ROOT_STATE = 'SET_ROOT_STATE',
  RESET_STORE = 'RESET_STORE',
}

interface ISetAccessTokenAction {
  readonly type: BaseActionType.SET_ACCESS_TOKEN;
  payload: string;
}
interface ISetRefreshTokenAction {
  readonly type: BaseActionType.SET_REFRESH_TOKEN;
  payload: string;
}
interface ISetRootStateAction {
  readonly type: BaseActionType.SET_ROOT_STATE;
  payload: RootState;
}
interface ISetResetStoreAction {
  readonly type: BaseActionType.RESET_STORE;
}

export type BaseActions = ISetAccessTokenAction | ISetRefreshTokenAction | ISetRootStateAction | ISetResetStoreAction