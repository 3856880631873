import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { UPLOAD_PHOTO } from '@/utils/mutations/mutations';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { CREATE_ARTICLE } from '../ArticleMutations';
import { ArticleTemplate } from '../AddArticle';
import WYSIWYGEditor from '@/components/utils/WYSIWYGEditor';
import TagSearch, { MHPSearchTagType } from '@/components/utils/TagSearch';
import { ImageUploadSection } from './small-components/ImageUploadSection';
import { disableKeyEnter } from '@/utils/utilities';

const ArticleTemplateAdmin = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  const [formError, setFormError] = useState('');
  // TODO - refactor to store array of images while still correlating position on article
  const [articleImages, setArticleImages] = useState<any>(['', '', '']);
  const [articleUploading, setArticleUploading] = useState(false);
  const [articleImageError, setArticleImageError] = useState(false);
  const [addArticleMutation] = useMutation(CREATE_ARTICLE);
  const [uploadPhoto] = useMutation(UPLOAD_PHOTO);
  const [formTags, setFormTags] = useState<any[]>([]);
  const [bodyContentList, setBodyContentList] = useState<string[]>([]);

  // Refactor handle image upload to be reusable
  const handleImageUpload = async (image: any) => {
    let uploadUrl = '';
    try {
      await uploadPhoto({
        variables: { uploadPhotoPhoto: image },
      }).then((data: any) => {
        setArticleImageError(false);
        uploadUrl = data?.data?.uploadPhoto;
      });
      return uploadUrl;
    } catch (err: any) {
      console.log('err: ', err);
    }
  };

  // Used to set image position in article - starting from 0 as the arr index.
  const handleImageData = (image: any, positionInArticle: number) => {
    let newArticleImages = [...articleImages];
    newArticleImages[positionInArticle] = image;
    setArticleImages(newArticleImages);
  };

  const handleAddArticle = async (inputs: any) => {
    setArticleUploading(true);
    if (articleImages === null) {
      setArticleImageError(true);
      return;
    }
    let imageUploadArr: Promise<string | undefined>[] = [];
    let imagesToUpload = articleImages.map((image: any) => {
      if (image && image !== undefined && image !== null && image !== '') {
        imageUploadArr.push(handleImageUpload(image));
      }
    });
    Promise.all([...imageUploadArr])
      .then(async (results) => {
        let articlesImagesUploadUrls = [...results];
        await uploadArticle(inputs, articlesImagesUploadUrls);
      })
      .catch((err) => {
        console.log(err);
        setFormError(err.message);
      });
  };

  const handleWYSIWYGFormStateUpdate = (
    convertedWYSIWYGContent: string,
    contentPosition: number,
  ) => {
    const newBodyContentList = [
      ...bodyContentList.slice(0, contentPosition),
      convertedWYSIWYGContent,
      ...bodyContentList.slice(contentPosition + 1),
    ];
    setBodyContentList(newBodyContentList);
  };

  const uploadArticle = async (inputs: any, articlesImagesUrls: any) => {
    setFormError('');
    const { heading, subHeading } = inputs;
    try {
      const { data } = await addArticleMutation({
        variables: {
          input: {
            heading,
            subHeading,
            bodyContentList: bodyContentList,
            articleImageList: articlesImagesUrls,
            tags: formTags,
            articleTemplate: ArticleTemplate.TEMPLATE_ADMIN,
          },
        },
      });

      if (data) {
        navigate('/articles');
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  const handleFormTags = (newTags: any) => {
    setFormTags(newTags);
  };

  // Generate new templates to be rendered dynamically
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleAddArticle)} onKeyDown={(e) => disableKeyEnter(e)} className='space-y-6 mb-8'>
        <div className='shadow sm:rounded-md sm:overflow-hidden'>
          <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
            <LargeImageUploadSection
              articleImages={articleImages}
              handleImageData={handleImageData}
              articleImageError={articleImageError}
              positionInArticle={0}
              smallPreview={true}
              labelText={'Feature Image'}
            />
            <div className='flex flex-row'>
              <ImageUploadSection
                articleImages={articleImages}
                handleImageData={handleImageData}
                articleImageError={articleImageError}
                positionInArticle={1}
                smallPreview={true}
              />
              <div className='flex flex-col w-full ml-4'>
                <Heading />
                <SubHeading />
                <div>
                  <label className='block text-sm font-medium text-gray-700 mt-4'>
                    Search for Tags
                  </label>
                  <TagSearch tagType={MHPSearchTagType.Article} handleFormTags={handleFormTags} />
                </div>
              </div>
            </div>
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={0}
            />
            <ImageUploadSection
              articleImages={articleImages}
              handleImageData={handleImageData}
              articleImageError={articleImageError}
              positionInArticle={2}
            />
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={1}
            />
            <ImageUploadSection
              articleImages={articleImages}
              handleImageData={handleImageData}
              articleImageError={articleImageError}
              positionInArticle={3}
            />
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={2}
            />
            <ImageUploadSection
              articleImages={articleImages}
              handleImageData={handleImageData}
              articleImageError={articleImageError}
              positionInArticle={4}
            />
            <ImageUploadSection
              articleImages={articleImages}
              handleImageData={handleImageData}
              articleImageError={articleImageError}
              positionInArticle={5}
            />
            <ImageUploadSection
              articleImages={articleImages}
              handleImageData={handleImageData}
              articleImageError={articleImageError}
              positionInArticle={6}
            />
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={3}
            />
            <div className='flex flex-wrap justify-between'>
              <div className='flex w-full grid gap-2 md:grid-cols-2'>
                <div className='col'>
                  <ImageUploadSection
                    articleImages={articleImages}
                    handleImageData={handleImageData}
                    articleImageError={articleImageError}
                    positionInArticle={7}
                    smallPreview={true}
                  />
                </div>
                <div className='col'>
                  <ImageUploadSection
                    articleImages={articleImages}
                    handleImageData={handleImageData}
                    articleImageError={articleImageError}
                    positionInArticle={8}
                    smallPreview={true}
                  />
                </div>
              </div>
              <div className='flex w-full grid gap-2 md:grid-cols-2 mt-4'>
                <div className='col'>
                  <ImageUploadSection
                    articleImages={articleImages}
                    handleImageData={handleImageData}
                    articleImageError={articleImageError}
                    positionInArticle={9}
                    smallPreview={true}
                  />
                </div>
                <div className='col'>
                  <ImageUploadSection
                    articleImages={articleImages}
                    handleImageData={handleImageData}
                    articleImageError={articleImageError}
                    positionInArticle={10}
                    smallPreview={true}
                  />
                </div>
              </div>
              <div className='flex w-full grid gap-2 md:grid-cols-2 mt-4'>
                <div className='col'>
                  <ImageUploadSection
                    articleImages={articleImages}
                    handleImageData={handleImageData}
                    articleImageError={articleImageError}
                    positionInArticle={11}
                    smallPreview={true}
                  />
                </div>
                <div className='col'>
                  <ImageUploadSection
                    articleImages={articleImages}
                    handleImageData={handleImageData}
                    articleImageError={articleImageError}
                    positionInArticle={12}
                    smallPreview={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
            {articleUploading ? (
              <div className='w-full flex flex-col items-center justify-center'>
                <div className='w-full text-center text-black'>
                  Please wait while we upload your <strong>epic</strong> images!
                </div>
                <LoadingSpinner />
              </div>
            ) : (
              <button
                type='submit'
                className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
              >
                Create Article
              </button>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ArticleTemplateAdmin;

const Heading = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className='w-full'>
      <label htmlFor='heading' className='block text-lg font-bold text-gray-700'>
        Heading
      </label>
      <div className='mt-1'>
        <input
          id='heading'
          {...register('heading', { required: 'Article name required' })}
          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
          placeholder='Enter the name of your new Article'
          defaultValue={''}
        />
        <span className='text-red'>{errors.heading && errors.heading.message}</span>
      </div>
    </div>
  );
};

const SubHeading = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <label htmlFor='subheading' className='block text-md font-semibold pt-2 text-gray-700'>
        Sub heading
      </label>
      <div className='mt-1'>
        <input
          id='subheading'
          {...register('subHeading')}
          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
          placeholder='Article subheading'
          defaultValue={''}
        />
      </div>
    </div>
  );
};

const LargeImageUploadSection = ({
  articleImages,
  labelText,
  handleImageData,
  articleImageError,
  smallPreview,
  positionInArticle,
}: {
  articleImages: any;
  labelText: string;
  handleImageData: Function;
  positionInArticle: number;
  articleImageError: any;
  smallPreview?: boolean;
}) => {
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>('');

  const handleImagePreview = async (e: any) => {
    try {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        setImagePreview(reader.result);
        handleImageData(reader.result, positionInArticle);
      };
    } catch (err: any) {
      console.log('err: ', err);
      return null;
    }
  };

  return (
    <div className={'w-full'}>
      <label className='block text-sm font-medium text-gray-700'>{labelText}</label>
      <div
        className={`mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md ${
          articleImages.length === 0 ? 'px-6 pt-5 pb-6' : ''
        } w-full h-48`}
      >
        <div className='text-center rounded overflow-hidden w-full h-full'>
          {imagePreview === '' ? (
            <div
              className={`${
                smallPreview ? 'px-4' : ''
              } h-full w-full flex flex-col py-6 items-center justify-center`}
            >
              <svg
                className='mx-auto h-12 w-12 text-gray-400'
                stroke='currentColor'
                fill='none'
                viewBox='0 0 48 48'
                aria-hidden='true'
              >
                <path
                  d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                  strokeWidth={2}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <div className='flex text-sm text-gray-600 text-center justify-center items-center'>
                <label
                  htmlFor='file-upload'
                  className={`relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 ${
                    smallPreview ? 'w-[8rem]' : ''
                  }`}
                >
                  <span>Upload a file</span>
                  <input
                    id='file-upload'
                    name='file-upload'
                    type='file'
                    onChange={(event) => {
                      handleImagePreview(event);
                    }}
                    className='sr-only'
                  />
                </label>
                {smallPreview ? '' : <p className='pl-1'>or drag and drop</p>}
              </div>
              <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
            </div>
          ) : typeof imagePreview === 'string' ? (
            <div className='h-full w-full max-h-48 relative'>
              <img className='object-cover h-full w-full' src={imagePreview} alt='ProfileImage' />
            </div>
          ) : null}
        </div>
      </div>
      {articleImageError ? <span className='text-red'>Article image required</span> : null}
    </div>
  );
};
