import { GET_ALL_ADVERTISERS } from '@/utils/queries/queries';
import { useQuery } from '@apollo/client';
import { Key, useState } from 'react';
import { Link, To } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

const AdvertisersWidgets = () => {
  const { data } = useQuery(GET_ALL_ADVERTISERS);
  if (!data) {
    return <></>;
  }
  return (
    <div className='pt-10 pl-4'>
      <div>
        <h5 className='font-bold text-tiny'>Advertisers</h5>
        <Swiper
          slidesPerView={4}
          breakpoints={{
            640: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 6,
            },
            1024: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 4,
            },
          }}
        >
          {data?.getAllAdvertisers?.map((advertiser: any, index: number) => {
            return (
              <SwiperSlide key={index}>
                <Link to={`/profile/${advertiser.username}`}>
                  <div className='m-2 bg-transparent border border-transparent overflow-hidden rounded-full h-8 w-8'>
                    <img
                      className='object-contain h-full w-full'
                      src={advertiser?.profilePicture}
                      referrerPolicy='no-referrer'
                      alt='logo'
                    />
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default AdvertisersWidgets;
